import './App.less';
import MyRoute from "./components/MyComponents/MyRoute/MyRoute";
import {
  URL_APPROVAL_DETAIL,
  URL_APPROVAL_LIST,
  URL_CHANCE_DETAIL,
  URL_CHANCE_EDIT,
  URL_CHANCE_LIST,
  URL_CLUE_DETAIL,
  URL_CLUE_EDIT,
  URL_CLUE_LIST,
  URL_CUSTOMER_DETAIL,
  URL_CUSTOMER_DUP_CHECK,
  URL_CUSTOMER_EDIT,
  URL_CUSTOMER_LIST,
  URL_LINKMAN_DETAIL,
  URL_LINKMAN_EDIT,
  URL_LINKMAN_LIST, URL_LOGIN, URL_LOGIN_RESULT,
  URL_MAIN, URL_MERCHANT_DETAIL, URL_MERCHANT_EDIT, URL_MERCHANT_LIST,
  URL_ORDER_DETAIL,
  URL_ORDER_EDIT,
  URL_ORDER_LIST_CREATE_USER,
  URL_ORDER_LIST_RECEIVE_USER
} from "./constant";
import Main from "./components/Main/Main";
import {Redirect, Switch} from "react-router-dom";
import CustomerList from "./components/Customer/CustomerList/CustomerList";
import CustomerForm from "./components/Customer/CustomerForm/CustomerForm";
import CustomerDetail from "./components/Customer/CustomerDetail/CustomerDetail";
import LinkmanForm from "./components/Linkman/LinkmanForm/LinkmanForm";
import LinkmanDetail from "./components/Linkman/LinkmanDetail/LinkmanDetail";
import LinkmanList from "./components/Linkman/LinkmanList/LinkmanList";
import CustomerDupCheck from "./components/Customer/CustomerDupCheck/CustomerDupCheck";
import ApprovalList from "./components/Approval/ApprovalList/ApprovalList";
import ApprovalDetail from "./components/Approval/ApprovalDetail.jsx/ApprovalDetail";
import ClueList from "./components/Clue/ClueList/ClueList";
import ClueForm from "./components/Clue/ClueForm/ClueForm";
import ClueDetail from "./components/Clue/ClueDetail/ClueDetail";
import ChanceList from "./components/Chance/ChanceList/ChanceList";
import ChanceForm from "./components/Chance/ChanceForm/ChanceForm";
import ChanceDetail from "./components/Chance/ChanceDetail/ChanceDetail";
import OrderListCreateUser from "./components/Order/OrderListCreateUser/OrderListCreateUser";
import OrderForm from "./components/Order/OrderForm/OrderForm";
import OrderDetail from "./components/Order/OrderDetail/OrderDetail";
import OrderListReceiveUser from "./components/Order/OrderListReceiveUser/OrderListReceiveUser";
import Login from "./components/Login/Login";
import LoginResult from "./components/Login/LoginResult";
import MerchantList from "./components/Merchant/MerchantList/MerchantList";
import MerchantForm from "./components/Merchant/MerchantForm/MerchantForm";
import MerchantDetail from "./components/Merchant/MerchantDetail/MerchantDetail";
import KeepAlive from "react-activation";


function App() {
  return (
    <div>
      <Switch>
        <MyRoute needLogin={false} path={URL_LOGIN} component={Login}/>
        <MyRoute needLogin={false} path={URL_LOGIN_RESULT} component={LoginResult}/>
        <MyRoute path={URL_MAIN} component={Main}/>
        <MyRoute path={URL_CUSTOMER_LIST}
                 component={(props) => (<KeepAlive name={URL_CUSTOMER_LIST}><CustomerList {...props}/></KeepAlive>)}/>
        <MyRoute path={URL_CUSTOMER_EDIT} component={CustomerForm}/>
        <MyRoute path={URL_CUSTOMER_DETAIL} component={CustomerDetail}/>
        <MyRoute path={URL_LINKMAN_LIST}
                 component={(props) => (<KeepAlive name={URL_LINKMAN_LIST}><LinkmanList {...props}/></KeepAlive>)}/>
        <MyRoute path={URL_LINKMAN_EDIT} component={LinkmanForm}/>
        <MyRoute path={URL_LINKMAN_DETAIL} component={LinkmanDetail}/>
        <MyRoute path={URL_CUSTOMER_DUP_CHECK} component={CustomerDupCheck}/>
        <MyRoute path={URL_APPROVAL_LIST} component={ApprovalList}/>
        <MyRoute path={URL_APPROVAL_DETAIL} component={ApprovalDetail}/>
        <MyRoute path={URL_CLUE_LIST}
                 component={(props) => (<KeepAlive name={URL_CLUE_LIST}><ClueList {...props}/></KeepAlive>)}/>
        <MyRoute path={URL_CLUE_EDIT} component={ClueForm}/>
        <MyRoute path={URL_CLUE_DETAIL} component={ClueDetail}/>
        <MyRoute path={URL_CHANCE_LIST}
                 component={(props) => (<KeepAlive name={URL_CHANCE_LIST}><ChanceList {...props}/></KeepAlive>)}/>
        <MyRoute path={URL_CHANCE_EDIT} component={ChanceForm}/>
        <MyRoute path={URL_CHANCE_DETAIL} component={ChanceDetail}/>
        <MyRoute path={URL_ORDER_LIST_CREATE_USER} component={OrderListCreateUser}/>
        <MyRoute path={URL_ORDER_LIST_RECEIVE_USER} component={OrderListReceiveUser}/>
        <MyRoute path={URL_ORDER_EDIT} component={OrderForm}/>
        <MyRoute path={URL_ORDER_DETAIL} component={OrderDetail}/>
        <MyRoute path={URL_MERCHANT_LIST}
                 component={(props) => (<KeepAlive name={URL_MERCHANT_LIST}><MerchantList {...props}/></KeepAlive>)}/>
        <MyRoute path={URL_MERCHANT_EDIT} component={MerchantForm}/>
        <MyRoute path={URL_MERCHANT_DETAIL} component={MerchantDetail}/>
        <Redirect to={URL_LOGIN}/>
      </Switch>
    </div>
  );
}


export default App;
