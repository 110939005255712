import React, {useEffect} from 'react';
import globalStyles from "../../../App.less";
import {Button, Dialog, Form, Input, TextArea, Toast} from "antd-mobile/2x";
import MyPicker from "../../MyComponents/Form/MyPicker";
import {useHistory} from "react-router-dom";
import {
  HTML_TITLE, PS_RELOAD_MERCHANT_LIST,
  SESSION_LAST_MERCHANT_INFO,
  URL_MERCHANT_CREATE,
  URL_MERCHANT_DETAIL,
  URL_MERCHANT_UPDATE
} from "../../../constant";
import {Query, Url} from "../../../api/query";
import PubSub from "pubsub-js";


const PageType = {
  Create: 0,
  Update: 1
}

export const MerchantTypeText = ['住宿', '餐饮', '烟酒礼品', '娱乐', '其他']
export const MerchantType = {
  '住宿': 1,
  '餐饮': 2,
  '烟酒礼品': 3,
  '娱乐': 4,
  '其他': 100,
}

const MerchantForm = (props) => {
  const history = useHistory()
  const formRef = React.createRef()
  const [pageType, setPageType] = React.useState(PageType.Create)
  const [merchantIdInUpdate, setMerchantIdInUpdate] = React.useState(-1)
  const [hasSubmit, setHasSubmit] = React.useState(false)

  useEffect(() => {
    if (props.location.pathname === URL_MERCHANT_CREATE) {
      setPageType(PageType.Create)
      document.title = HTML_TITLE[URL_MERCHANT_CREATE]
    } else if (props.location.pathname === URL_MERCHANT_UPDATE) {
      setPageType(PageType.Update)
      document.title = HTML_TITLE[URL_MERCHANT_UPDATE]

      const stateMerchant = props.location.state === undefined ? getLastMerchantInfo() : props.location.state.merchant
      const content = JSON.parse(stateMerchant.content)
      content.merchant_type = MerchantTypeText[Number(content.merchant_type) - 1]

      setMerchantIdInUpdate(stateMerchant.id)
      let form;
      (form = formRef.current) === null || form === void 0 ? void 0 : form.setFieldsValue(content);
    }
  }, [])

  const getLastMerchantInfo = () => {
    const lastMerchantInfoStr = sessionStorage.getItem(SESSION_LAST_MERCHANT_INFO);
    if (lastMerchantInfoStr == null) {
      return {}
    }
    return JSON.parse(lastMerchantInfoStr)
  }

  const submitForm = (obj) => {
    let value = JSON.parse(JSON.stringify(obj))
    let data = {}
    if (pageType === PageType.Update) {
      data.id = merchantIdInUpdate
    }
    data.name = value.merchant_name
    data.city = value.merchant_city
    value.merchant_type = MerchantType[value.merchant_type]
    data.type = value.merchant_type
    data.content = value

    if (pageType === PageType.Create) {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认创建商户？</div>,
        onConfirm: async () => {
          setHasSubmit(true)
          Query(Url.merchantCreate, data, (res) => {
            Toast.show({
              icon: 'success',
              content: '创建成功',
              duration: 1000,
            })
            PubSub.publish(PS_RELOAD_MERCHANT_LIST, {})
            setTimeout(() => {
              history.replace({
                  pathname: URL_MERCHANT_DETAIL,
                  state: {merchant: res.data}
                },
              )
            }, 1500)
          }, (err) => {
            setHasSubmit(false)
          }, () => {
            setHasSubmit(false)
          })
        },
      })

    } else if (pageType === PageType.Update) {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认提交商户修改申请？</div>,
        onConfirm: async () => {
          setHasSubmit(true)
          Query(Url.merchantUpdate, data, (res) => {
            Toast.show({
              icon: 'success',
              content: (<div style={{textAlign: 'center'}}>提交成功！<br/>请等待直属上级审批</div>),
              duration: 2000,
            })
            PubSub.publish(PS_RELOAD_MERCHANT_LIST, {})
            setTimeout(() => {
              history.go(-1)
            }, 2500)
          }, (err) => {
            setHasSubmit(false)
          }, () => {
            setHasSubmit(false)
          })
        },
      })

    }
  }

  return (
    <div style={{padding: '0 20px', background: '#EDEEF0'}} className={globalStyles.vh_frame}>
      <div className={globalStyles.body}>

        <Form ref={formRef}
              layout='horizontal'
              mode='card'
              onFinish={submitForm}
              initialValues={{
                phones: [{}],
              }}
              onFinishFailed={() => {
                Toast.show({
                  icon: 'fail',
                  content: '请检查表单是否填写完整',
                })
              }}
              footer={<Button block type='submit' color='primary' size='large'
                              disabled={hasSubmit}
                              style={{marginBottom: '80px', '--border-radius': '30px'}}>提交</Button>}
        >
          <Form.Header>个人信息</Form.Header>

          <Form.Item label='商户名' name='merchant_name' rules={[{required: true}]}>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='商户城市' name='merchant_city' rules={[{required: true}]}>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='商户类型' name='merchant_type' rules={[{required: true}]}>
            <MyPicker columns={[MerchantTypeText]}/>
          </Form.Item>
          <Form.Item label='联系人姓名' name='linkman_name'>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='联系电话' name='linkman_phone'>
            <TextArea placeholder='有多个号码时，号码之间用空格隔开' rows={3}/>
          </Form.Item>
          <Form.Item label='地址' name='merchant_location'>
            <TextArea placeholder='请直接输入地址，不需要写所在城市' rows={3}/>
          </Form.Item>

          <Form.Header>备注框</Form.Header>
          <Form.Item name='merchant_note'>
            <TextArea
              placeholder='备注信息'
              rows={5}
            />
          </Form.Item>

        </Form>
      </div>
    </div>
  );
};

export default MerchantForm;