import React, {useEffect} from 'react';
import {GetQueryString} from "../../utils/UrlParam";
import MyEmpty from "../MyComponents/MyEmpty/MyEmpty";
import {
  setSessionAuthorization,
  setSessionAvatar,
  setSessionLeaders,
  setSessionMobile,
  setSessionUserid,
  setSessionUserName
} from "../../storage/storage";
import {LoginRedirectState, URL_APPROVAL_LIST, URL_CHANCE_LIST, URL_CUSTOMER_LIST, URL_MAIN} from "../../constant";
import {useHistory} from "react-router-dom";
import {Dialog} from "antd-mobile/2x";

const LoginResult = (props) => {
  const history = useHistory()
  useEffect(() => {
    const token = GetQueryString(props.location.search, 'token')
    if (token.charAt(0) !== 'e') {
      setLoginFailed(true)
      if (token==="-2"){
        Dialog.alert({
          content: '您的账号已被管理员关闭',
        })
      }
    } else {
      const userId = GetQueryString(props.location.search, 'userId')
      const userName = GetQueryString(props.location.search, 'userName')
      const mobile = GetQueryString(props.location.search, 'mobile')
      const avatar = GetQueryString(props.location.search, 'avatar')
      const leaders = GetQueryString(props.location.search, 'leaders')
      const state = GetQueryString(props.location.search, 'state')
      setSessionAuthorization("token " + token)
      setSessionUserid(userId)
      setSessionUserName(userName)
      setSessionMobile(mobile)
      setSessionAvatar(avatar)
      setSessionLeaders(leaders)
      if (state != null) {
        if (state === LoginRedirectState.Approval) {
          history.replace({
              pathname: URL_APPROVAL_LIST
            },
          )
        } else if (state === LoginRedirectState.ChanceList) {
          history.replace({
              pathname: URL_CHANCE_LIST
            },
          )
        } else if (state === LoginRedirectState.OrderList) {
          history.replace({
              pathname: URL_MAIN
            },
          )
        } else if (state === LoginRedirectState.CustomerList) {
          history.replace({
              pathname: URL_CUSTOMER_LIST
            },
          )
        } else {
          history.replace({
              pathname: URL_MAIN
            },
          )
        }
      } else {
        history.replace({
            pathname: URL_MAIN
          },
        )
      }
    }
  }, [])

  const [loginFailed, setLoginFailed] = React.useState(false)

  return (
    <div>
      <div style={{textAlign: "center", marginTop: '50px'}}>
        {loginFailed
          ? <div>
            <MyEmpty description="登录失败，请联系管理员"/>
            <div style={{marginTop: '20px', color: '#f5222d'}}>注意：仅可在企业微信中打开，微信中无法登陆</div>
          </div>
          : "登录成功，正在跳转..."}
      </div>
    </div>
  );
};

export default LoginResult;