import React, {useEffect} from 'react';
import globalStyles from "../../../App.less";
import {Button, DatePicker, Dialog, Form, Input, List, Popup, Skeleton, TextArea, Toast} from "antd-mobile/2x";
import {useHistory} from "react-router-dom";
import dayjs from "dayjs";
import {
  HTML_TITLE, PS_RELOAD_CHANCE_LIST,
  SESSION_LAST_CHANCE_INFO,
  SESSION_LAST_CUSTOMER_IN_CHANCE_INFO,
  URL_CHANCE_CREATE,
  URL_CHANCE_DETAIL,
  URL_CHANCE_UPDATE,
} from "../../../constant";
import {Query, Url} from "../../../api/query";
import ChineseHelper from "../../../utils/Pinyin/ChineseHelper";
import PubSub from "pubsub-js";


const PageType = {
  Create: 0,
  Update: 1
}

const ChanceForm = (props) => {
  const history = useHistory()
  const formRef = React.createRef()
  const [pageType, setPageType] = React.useState(PageType.Create)
  const [chanceIdInUpdate, setChanceIdInUpdate] = React.useState(-1)
  const [customer, setCustomer] = React.useState({})
  const [customerPopup, setCustomerPopup] = React.useState(false)
  const [customerList, setCustomerList] = React.useState([])
  const [customerListLoading, setCustomerListLoading] = React.useState(false)
  const [hasSubmit, setHasSubmit] = React.useState(false)

  useEffect(() => {
    if (props.location.pathname === URL_CHANCE_CREATE) {
      setPageType(PageType.Create)
      document.title = HTML_TITLE[URL_CHANCE_CREATE]

    } else if (props.location.pathname === URL_CHANCE_UPDATE) {
      setPageType(PageType.Update)
      document.title = HTML_TITLE[URL_CHANCE_UPDATE]

      const stateCustomer = props.location.state === undefined ? getLastCustomerInChanceInfo() : props.location.state.customer
      setCustomerInfo(stateCustomer)
      const stateChance = props.location.state === undefined ? getLastChanceInfo() : props.location.state.chance
      const content = convertDate(JSON.parse(stateChance.content))
      setChanceIdInUpdate(stateChance.id)
      let form;
      (form = formRef.current) === null || form === void 0 ? void 0 : form.setFieldsValue(content);
    }
  }, [])

  const convertDate = (content) => {
    if (content.chance_day !== null && content.chance_day !== undefined) {
      content.chance_day = new Date(content.chance_day)
    }
    return content
  }

  const getLastChanceInfo = () => {
    const lastChanceInfoStr = sessionStorage.getItem(SESSION_LAST_CHANCE_INFO);
    if (lastChanceInfoStr == null) {
      return {}
    }
    return JSON.parse(lastChanceInfoStr)
  }

  const getLastCustomerInChanceInfo = () => {
    const lastCustomerInChanceInfoStr = sessionStorage.getItem(SESSION_LAST_CUSTOMER_IN_CHANCE_INFO);
    if (lastCustomerInChanceInfoStr == null) {
      return undefined
    }
    return JSON.parse(lastCustomerInChanceInfoStr)
  }

  const setForm = (content) => {
    let form;
    (form = formRef.current) === null || form === void 0 ? void 0 : form.setFieldsValue(content);
  }

  const setCustomerInfo = (customer) => {
    setForm({customer_name: customer.name})
    setCustomer(customer)
  }

  const submitForm = (obj) => {
    let value = JSON.parse(JSON.stringify(obj))
    if (value.chance_day !== undefined) {
      value.chance_day = dayjs(value.chance_day).format('YYYY-MM')
    }

    let data = {}
    if (pageType === PageType.Update) {
      data.id = chanceIdInUpdate
    }
    delete value.customer_name
    data.customer_id = customer.id
    data.name = value.chance_name
    data.content = value

    if (pageType === PageType.Create) {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认创建商机？</div>,
        onConfirm: async () => {
          setHasSubmit(true)
          Query(Url.chanceCreate, data, (res) => {
            Toast.show({
              icon: 'success',
              content: '创建成功',
              duration: 1000,
            })
            PubSub.publish(PS_RELOAD_CHANCE_LIST, {})
            setTimeout(() => {
              history.replace({
                  pathname: URL_CHANCE_DETAIL,
                  state: {chance: res.data}
                },
              )
            }, 1500)
          }, (err) => {
            setHasSubmit(false)
          }, () => {
            setHasSubmit(false)
          })
        },
      })

    } else if (pageType === PageType.Update) {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认修改商机？</div>,
        onConfirm: async () => {
          setHasSubmit(true)
          Query(Url.chanceUpdate, data, (res) => {
            Toast.show({
              icon: 'success',
              content: (<div style={{textAlign: 'center'}}>修改成功</div>),
              duration: 2000,
            })
            PubSub.publish(PS_RELOAD_CHANCE_LIST, {})
            setTimeout(() => {
              history.go(-1)
              history.replace({
                pathname: URL_CHANCE_DETAIL,
                state: {
                  chance: res.data
                }
              })
            }, 2500)
          }, (err) => {
            setHasSubmit(false)
          }, () => {
            setHasSubmit(false)
          })
        },
      })

    }
  }

  const chooseCustomer = () => {
    setCustomerPopup(true)

    setCustomerListLoading(true)
    Query(Url.customerQuerySelf, null,
      (res) => {
        let list = res.data.list
        for (let i = 0; i < list.length; i++) {
          list[i]["enName"] = ChineseHelper.ConvertPinyin(list[i]["name"])
        }
        list.sort((o1, o2) => o1.enName.localeCompare(o2.enName, 'en'))

        setCustomerList(list)
        setCustomerListLoading(false)
      },
      (res) => {
        setCustomerListLoading(false)
      },
      () => {
        setCustomerListLoading(false)
      }
    )
  }

  return (
    <div style={{padding: '0 20px', background: '#EDEEF0'}} className={globalStyles.vh_frame}>
      <div className={globalStyles.body}>

        <Form ref={formRef}
              layout='horizontal'
              mode='card'
              onFinish={submitForm}
              initialValues={{
                phones: [{}],
              }}
              onFinishFailed={() => {
                Toast.show({
                  icon: 'fail',
                  content: '请检查表单是否填写完整',
                })
              }}
              footer={<Button block type='submit' color='primary' size='large'
                              disabled={hasSubmit}
                              style={{marginBottom: '80px', '--border-radius': '30px'}}>提交</Button>}
        >
          <Form.Header>基本信息</Form.Header>
          <Form.Item label='客户名称' name='customer_name' rules={[{required: true}]} onClick={chooseCustomer}>
            <Input placeholder='请输入' readOnly/>
          </Form.Item>

          <Form.Item label='商机名称' name='chance_name' rules={[{required: true}]}>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='产品' name='chance_product'>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='金额' name='chance_budget'>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='预计签约/招标' name='chance_day' trigger='onConfirm' onClick={(e, datePickerRef) => {
            let _a;
            (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.open();
          }}>
            <DatePicker min={new Date(1900, 0, 1)} precision='month'>
              {value => value ? dayjs(value).format('YYYY-MM') : <div style={{color: '#cccccc'}}>请选择</div>}
            </DatePicker>
          </Form.Item>

          <Form.Header>需求描述</Form.Header>
          <Form.Item name='chance_description'>
            <TextArea
              placeholder='请输入'
              rows={5}
            />
          </Form.Item>

          <Form.Header>竞品分析</Form.Header>
          <Form.Item name='competitive_analysis'>
            <TextArea
              placeholder='请输入'
              rows={5}
            />
          </Form.Item>

          <Form.Header>备注</Form.Header>
          <Form.Item name='competitive_note'>
            <TextArea
              placeholder='请输入'
              rows={5}
            />
          </Form.Item>


        </Form>

        <Popup
          visible={customerPopup}
          onMaskClick={() => {
            setCustomerPopup(false)
          }}
          position='right'
          bodyStyle={{width: 'var(--80vw)'}}
        >
          <div className={globalStyles.frame}>
            <div style={{
              fontSize: 'var(--adm-font-size-8)',
              padding: '20px',
              color: 'var(--adm-color-text)',
              textAlign: "center"
            }}
                 className={globalStyles.top_b}>选择客户
            </div>
            <List className={globalStyles.body}
                  style={{display: customerListLoading ? 'none' : 'block', overflowY: 'scroll'}}>
              {
                customerList.map((item, index) => {
                  return (
                    <List.Item key={index} onClick={() => {
                      setCustomerInfo(item)
                      setCustomerPopup(false)
                    }}>
                      {item.name}
                    </List.Item>
                  )
                })
              }
            </List>
          </div>
          <div style={{margin: '20px 50px 20px 50px', display: customerListLoading ? 'block' : 'none'}}>
            <Skeleton.Title animated/>
            <Skeleton.Paragraph lineCount={5} animated/>
          </div>

        </Popup>


      </div>

    </div>
  );
};

export default ChanceForm;