export const MapApi = "https://uri.amap.com/search?view=map&keyword="
// export const MapApi = "https://map.baidu.com/mobile/webapp/search/search/qt=s&wd="

export const MapNotice = () => {
  // return <div>
  //   若需在高德地图App使用，请在跳转至地图页后，请点击右上角<strong> ··· </strong>按钮，再点击在系统浏览器中打开
  // </div>
  return <div style={{textAlign:"center"}}>
    即将跳转到高德地图
  </div>

}

