import React, {useEffect} from 'react';
import {
  HTML_TITLE,
  OrderStatus,
  OrderUserRole,
  SESSION_LAST_ORDER_INFO,
  SESSION_LAST_ORDER_USER_ROLE,
  URL_CUSTOMER_DETAIL,
  URL_ORDER_DETAIL,
} from "../../../constant";
import globalStyles from "../../../App.less";
import {Button, Card, Dialog, Divider, Grid, List, Space, SpinLoading, Tag, Toast} from "antd-mobile/2x";
import "./OrderDetail.css"
import {NempStr, SubString} from "../../../utils/StringUtil";
import {useHistory} from "react-router-dom";
import {Query, Url} from "../../../api/query";
import {RightOutline} from "antd-mobile-icons";
import {getSessionUserId, getSessionUserName} from "../../../storage/storage";
import {MapApi, MapNotice} from "../../../api/map";

const OrderDetail = (props) => {
    useEffect(() => {
      document.title = HTML_TITLE[URL_ORDER_DETAIL]
      sessionStorage.setItem(SESSION_LAST_ORDER_INFO, JSON.stringify(order))
      sessionStorage.setItem(SESSION_LAST_ORDER_USER_ROLE, userRole)

      tryGetCustomer()
    }, [])


    const history = useHistory()

    const getLastOrderInfo = () => {
      const lastOrderInfoStr = sessionStorage.getItem(SESSION_LAST_ORDER_INFO);
      if (lastOrderInfoStr == null) {
        return {
          id: -1,
          user_id: -1,
          name: "参数错误",
          type: 0,
          level: "普通客户",
          content: "{}",
          created_at: "-",
          updated_at: "-",
        };
      }
      return JSON.parse(lastOrderInfoStr)
    }

    const getLastUserRole = () => {
      const lastOrderUserRole = sessionStorage.getItem(SESSION_LAST_ORDER_USER_ROLE);
      if (lastOrderUserRole == null) {
        return 0;
      }
      return Number(lastOrderUserRole);
    }

    const order = props.location.state === undefined ? getLastOrderInfo() : props.location.state.order
    const userRole = props.location.state === undefined ? getLastUserRole() : props.location.state.role
    const content = JSON.parse(order.content)

    const [customerTry, setCustomerTry] = React.useState({});
    const [customerAuth, setCustomerAuth] = React.useState(false);

    const tryGetCustomer = () => {
      Query(Url.customerTryQueryById, {id: order.customer_id},
        (res) => {
          setCustomerTry(res.data)
          if (res.data.id !== 0) {
            setCustomerAuth(true)
          }
        })
    }

    const detailTab = () => {
      const closeOrder = () => {
        Dialog.confirm({
          content: <div style={{
            fontSize: 'var(--adm-font-size-8)',
            textAlign: "center",
            margin: '20px 0',
          }}>确认关闭订单？
            <div style={{marginTop: '20px', fontSize: 'var(--adm-font-size-5)'}}>
              关闭后将无法再次开启<br/>若订单已确认，确认人将收到通知
            </div>
          </div>,
          onConfirm: () => {
            Query(Url.orderClose, {id: order.id},
              (res) => {
                Toast.show({
                  icon: 'success',
                  content: '关闭成功',
                  duration: 1000,
                })
                order.status = OrderStatus.Close
                history.replace({
                  pathname: URL_ORDER_DETAIL,
                  state: {
                    order: order,
                    role: userRole
                  }
                })
              })
          }
        })
      }
      const finishOrder = () => {
        Dialog.confirm({
          content: <div style={{
            fontSize: 'var(--adm-font-size-8)',
            textAlign: "center",
            margin: '20px 0',
          }}>是否确认订单？
            <div style={{marginTop: '20px', fontSize: 'var(--adm-font-size-5)'}}>
              确认后，创建人将收到通知
            </div>
          </div>,
          onConfirm: () => {
            Query(Url.orderFinish, {id: order.id},
              (res) => {
                Toast.show({
                  icon: 'success',
                  content: '确认成功',
                  duration: 1000,
                })
                order.status = OrderStatus.Finish
                order.receive_user_id = getSessionUserId()
                order.receive_user_name = getSessionUserName()
                history.replace({
                  pathname: URL_ORDER_DETAIL,
                  state: {
                    order: order,
                    role: userRole
                  }
                })
              })
          }
        })
      }

      return (<div>
          <List header='基本信息'
                mode={"card"}
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                }}>
            <List.Item prefix='订货日期'>{NempStr(content.order_date)}</List.Item>
            <List.Item prefix='结算单位'>{NempStr(content.charge_unit)}</List.Item>
            <List.Item prefix='关联订单'>{NempStr(content.relative_order)}</List.Item>
            <List.Item prefix='参照订单'>{NempStr(content.refer_order)}</List.Item>
            <List.Item prefix='销售预计号'>{NempStr(content.sales_forecast_number)}</List.Item>
          </List>

          <List header='业务信息'
                mode={"card"}
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                }}>
            <List.Item prefix='业务类型'>{NempStr(content.business_type)}</List.Item>
            <List.Item prefix='客户类型'>{NempStr(content.customer_type)}</List.Item>
            <List.Item prefix='业务归口'>{NempStr(content.business_belong)}</List.Item>
            <List.Item prefix='系统类型'>{NempStr(content.system_type)}</List.Item>
          </List>

          <List header='联系人信息'
                mode={"card"}
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                }}>
            <List.Item prefix='联系人'>{NempStr(content.receiver_name)}</List.Item>
            <List.Item prefix='联系人电话'>
              <a href={"tel:" + content.receiver_phone} style={{textDecoration: 'none'}}>
                {NempStr(content.receiver_phone)}
              </a>
            </List.Item>
            <List.Item prefix='收货地址'>
              <div style={{color: 'var(--adm-color-primary)'}} onClick={() => {
                Dialog.confirm({
                  content: (<div style={{margin: '0 10px'}}>
                    <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>提示</div>
                    {MapNotice()}
                  </div>),
                  onConfirm: () => {
                    window.open(`${MapApi}${content.receiver_location}`)
                  },
                })
              }
              }>
                {NempStr(content.receiver_location)}
              </div>
              {/*<a href={`${MapApi}${content.receiver_location}`}*/}
              {/*   style={{textDecoration: 'none'}}>*/}
              {/*  {NempStr(content.receiver_location)}*/}
              {/*</a>*/}
            </List.Item>
          </List>

          <div>
            {
              content.products !== undefined && content.products !== null ?
                content.products.map((item, index) => {
                  return (
                    <div key={index + 1}>
                      <List mode='card' header={'产品' + (index + 1)}
                            style={{
                              '--prefix-width': '200px',
                              '--font-size': '30px',
                            }}>
                        <List.Item prefix='产品名称'>{NempStr(item.product_name)}</List.Item>
                        <List.Item prefix='规格型号'>{NempStr(item.product_type)}</List.Item>
                        <List.Item prefix='单位'>{NempStr(item.product_unit)}</List.Item>
                        <List.Item prefix='数量'>{NempStr(item.product_number)}</List.Item>
                        <List.Item prefix='不含税单价（元）'>{NempStr(item.product_price)}</List.Item>
                        <List.Item prefix='交货日期'>{NempStr(item.deliver_date)}</List.Item>
                        <List.Item prefix='备注'>{NempStr(item.note)}</List.Item>
                      </List>
                    </div>
                  )
                }) :
                null
            }
          </div>

          <List header='其他信息'
                mode={"card"}
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                }}>
            <List.Item prefix='交付特殊要求'>{NempStr(content.deliver_special_requirement)}</List.Item>
            <List.Item prefix='产品特殊要求'>{NempStr(content.product_special_requirement)}</List.Item>
            <List.Item prefix='不含税采购价格(适用于其他销售)'>{NempStr(content.order_price)}</List.Item>
          </List>

          <List header='备注'
                mode={"card"}
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                }}>
            <List.Item>{NempStr(content.order_note)}</List.Item>
          </List>

          <List header='确认信息'
                mode={"card"}
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                }}>
            <List.Item prefix='创建时间'>{NempStr(content.order_birth_date)}</List.Item>
            <List.Item prefix='审批人'>{NempStr(content.confirm_user_name)}</List.Item>
          </List>

          <div style={{padding: '50px 0'}}/>

          <div style={{
            textAlign: 'center',
            display: userRole === OrderUserRole.Create && order.status !== OrderStatus.Close ? 'block' : 'none'
          }}>
            <Button color='danger' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                    onClick={closeOrder}>
              <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>关闭订单</div>
            </Button>
          </div>

          <div style={{
            textAlign: 'center',
            display: userRole === OrderUserRole.Receive && order.status === OrderStatus.Unfinished ? 'block' : 'none'
          }}>
            <Button color='primary' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                    onClick={finishOrder}>
              <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>确认订单</div>
            </Button>
          </div>
        </div>

      )
    }


    return (
      <div>
        <div className={globalStyles.background_grey}/>

        <div className={globalStyles.vh_frame}>
          <div className={globalStyles.top}
               style={{
                 margin: '30px 40px 20px 40px',
                 borderRadius: '30px',
                 // padding: '30px 30px 30px 30px',
                 // background: 'linear-gradient(to bottom, #5BBE91, #67D59C)',
               }}
          >
            <Card style={{padding: '0 30px', boxShadow: '0 5px 10px 0 rgb(0 0 0 / 10%)'}}>
              <div style={{fontSize: '40px', fontWeight: 'bold'}}>
                {order.name}


                <span style={{float: 'right'}}>
                  {
                    order.status === OrderStatus.Unfinished ?
                      <Tag color="danger" fill='outline'
                           style={{
                             '--border-radius': '10px',
                             fontSize: '24px',
                             transform: 'translateY(-4px)'
                           }}>待确认</Tag> :
                      order.status === OrderStatus.Finish ?
                        <Tag color="success" fill='outline' style={{
                          '--border-radius': '10px',
                          fontSize: '24px',
                          transform: 'translateY(-4px)'
                        }}>已确认</Tag> :
                        <Tag color="default" fill='outline' style={{
                          '--border-radius': '10px',
                          fontSize: '24px',
                          transform: 'translateY(-4px)'
                        }}>已关闭</Tag>
                  }
                    </span>

              </div>

              <Divider
                style={{margin: '25px 0px 25px 0px'}}
              />
              <div style={{color: 'var(--adm-color-text-secondary)'}}>
                <Grid columns={2}>
                  <Grid.Item>
                    创建人：{order.create_user_name}
                  </Grid.Item>
                  <Grid.Item>
                    <span>
                    确认人：{order.receive_user_id === 0 ? '暂无' : order.receive_user_name}
                    </span>
                  </Grid.Item>
                </Grid>
              </div>
              <div style={{marginTop: '10px', color: 'var(--adm-color-text-secondary)'}}>
                审批通过时间：{SubString(order.created_at, 0, 19)}
              </div>
              <div style={{marginTop: '10px', color: 'var(--adm-color-text-secondary)'}}>
                状态更新时间：{SubString(order.updated_at, 0, 19)}
              </div>
            </Card>

            <div style={{margin: '20px'}}/>
            <Card style={{padding: '0 30px', boxShadow: '0 5px 10px 0 rgb(0 0 0 / 10%)'}}
                  onClick={() => {
                    if (customerAuth) {
                      history.push({
                        pathname: URL_CUSTOMER_DETAIL,
                        state: {customer: customerTry}
                      })
                    } else {
                      Dialog.alert({
                        content: <div style={{
                          fontSize: 'var(--adm-font-size-8)',
                          textAlign: "center",
                          margin: '20px 0',
                        }}>无权限编辑该客户
                          <div style={{marginTop: '20px', fontSize: 'var(--adm-font-size-5)'}}>
                            若要编辑，请联系该客户的创建人将您添加为协作同事（可在<strong>首页-客户查重</strong>查找该客户的创建人）
                            <br/>
                            若该创建人是您的下属，可在<strong>首页-客户-查看下属的客户</strong>查看该客户信息
                          </div>
                        </div>,
                      })
                    }
                  }}>
              {customerTry.id === undefined
                ? <>所属客户：<Space><SpinLoading color='primary' style={{'--size': '20px'}}/></Space></>
                : <>
                  所属客户：<span style={{fontWeight: 'bold'}}>{customerTry.name}</span>
                  <div style={{marginTop:'10px'}}/>
                  客户编号：<span style={{fontWeight: 'bold'}}>{customerTry.biz_id}</span>
                </>}


              <span style={{float: 'right', display: customerAuth ? 'block' : 'none'}}><RightOutline/></span>
            </Card>
          </div>
          <div className={globalStyles.body} style={{padding: '0 20px', '--adm-color-primary': '#5BBE91'}}>
            {detailTab()}
          </div>
        </div>
      </div>
    );
  }
;

export default OrderDetail;
