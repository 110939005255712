import React, {useEffect, useState} from 'react';
import {Button, Card, Dialog, Divider, Grid, List, Skeleton, SwipeAction} from "antd-mobile/2x";
import {Query, Url} from "../../api/query";
import globalStyles from "../../App.less";
import MyEmpty from "../MyComponents/MyEmpty/MyEmpty";
import {HTML_TITLE, NotificationStatus, PS_CLEAR_UNREAD_NOTIFICATION, URL_NOTIFICATION} from "../../constant";
import PubSub from "pubsub-js";
import {SubString} from "../../utils/StringUtil";

const Notification = () => {
  useEffect(() => {
    document.title = HTML_TITLE[URL_NOTIFICATION]
    getNotificationList()
  }, [])

  const [notificationList, setNotificationList] = useState([])
  const [notificationListLoading, setNotificationListLoading] = useState(false)
  const [unreadCount, setUnreadCount] = useState(0)

  const getNotificationList = () => {
    setNotificationListLoading(true)
    Query(Url.notificationQuery, {}, (res) => {
      let list = res.data.list
      let unreadCountVar = 0
      list.sort((o1, o2) => o1.is_read === o2.is_read ? o2.id - o1.id : o1.is_read - o2.is_read)
      for (let i = 0; i < list.length; i++) {
        if (list[i].is_read === NotificationStatus.Unread) {
          unreadCountVar++
        } else {
          break
        }
      }
      setUnreadCountWrapper(unreadCountVar)
      setNotificationList(list)
      setNotificationListLoading(false)
    })
  }

  const setUnreadCountWrapper = (unreadCountVar) => {
    if (unreadCountVar === 0) {
      PubSub.publish(PS_CLEAR_UNREAD_NOTIFICATION, {})
    }
    setUnreadCount(unreadCountVar)
  }

  const readNotification = (id) => {
    Query(Url.notificationRead, {ids: [id]}, (res) => {
    })
    let list = notificationList
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        list[i].is_read = Notification.Read;
        setUnreadCountWrapper(unreadCount - 1)
        list.sort((o1, o2) => o1.is_read === o2.is_read ? o2.id - o1.id : o1.is_read - o2.is_read)
        setNotificationList(list)
        break
      }
    }
  }

  const readAllNotification = () => {
    Dialog.confirm({
      content: <div style={{
        fontSize: 'var(--adm-font-size-8)',
        textAlign: "center",
        margin: '20px 0'
      }}>确认将全部消息标记位已读？</div>,
      onConfirm: async () => {

        let list = notificationList
        const ids = list.filter(item => item.is_read === NotificationStatus.Unread).map(item => item.id)
        if (ids.length !== 0) {
          Query(Url.notificationRead, {ids}, (res) => {
          })
        }
        for (let i = 0; i < list.length; i++) {
          list[i].is_read = Notification.Read;
        }
        setUnreadCountWrapper(0)
        list.sort((o1, o2) => o1.is_read === o2.is_read ? o2.id - o1.id : o1.is_read - o2.is_read)
        setNotificationList(list)

      },
    })

  }

  return (
    <div>
      <div className={globalStyles.frame} style={{width: 'var(--100vw)'}}>
        <div className={globalStyles.top}>
          <Grid columns={3} style={{margin: '20px 40px 20px 40px'}}>
            <Grid.Item span={2}
                       style={{
                         alignItems: 'center',
                         display: 'flex',
                         fontSize: 'var(--adm-font-size-7)',
                         color: 'var(--adm-color-text-secondary)'
                       }}>
              从右向左滑将消息设为已读消息
            </Grid.Item>
            <Grid.Item span={1}>
              <div style={{textAlign: 'right'}}>
                <Button color='primary' shape='rounded' onClick={readAllNotification}>全部已读</Button>
              </div>
            </Grid.Item>
          </Grid>
          <Divider style={{margin: '10px 0 0 0'}}/>
        </div>

        <div className={globalStyles.body} style={{backgroundColor: '#EDEEF0'}}>
          <div style={{padding: '10px'}}/>

          <Card style={{
            margin: '15px 30px',
            display: notificationList.length !== 0 ? 'none' : 'block'
          }}>
            <div style={{
              margin: '30px 0 50px 0',
              display: (!notificationListLoading && notificationList.length === 0) ? 'block' : 'none'
            }}>
              <MyEmpty/>
            </div>

            <div style={{
              margin: '10px 30px',
              display: notificationListLoading ? 'block' : 'none'
            }}>
              <Skeleton.Title animated/>
              <Skeleton.Paragraph lineCount={5} animated/>
            </div>

          </Card>

          <List mode='card' style={{margin: '15px 30px'}}>
            {
              notificationList.map((item, index) => {
                return (
                  <SwipeAction
                    key={index+1}
                    rightActions={[{
                    key: 'read', text: '已读',
                    color: item.is_read === NotificationStatus.Unread ? 'primary' : 'light',
                    onClick: () => {
                      if (item.is_read === NotificationStatus.Unread) {
                        readNotification(item.id)
                      }
                    }
                  }]}>
                    <List.Item title={
                      <Grid columns={3}>
                        <Grid.Item>
                          通知 {item.id}
                        </Grid.Item>
                        <Grid.Item span={2}>
                          <div style={{textAlign: 'right'}}>
                            {SubString(item.created_at, 0, 19)}
                          </div>
                        </Grid.Item>
                      </Grid>}>
                      <div style={{
                        fontSize: 'var(--adm-font-size-7)',
                        color: item.is_read === NotificationStatus.Unread ? '#000000' : '#AAAAAA'
                      }}>{item.content}</div>
                    </List.Item>
                  </SwipeAction>
                )
              })
            }
          </List>

          <div style={{padding: '20px'}}/>
        </div>
      </div>
    </div>
  );
};

export default Notification;