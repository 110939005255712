import React from 'react';
import {Badge} from "antd-mobile/2x";

const MyItem = (props) => {
  return (
    <div onClick={props.onClick}>
      <Badge content={props.badge}>
        {props.icon}
        <div style={{textAlign: 'center', margin: '10px 0px', color: 'var(--adm-color-text)', fontSize: '28px'}}>
          {props.title}
        </div>
      </Badge>
    </div>
  );
};

export default MyItem;