import React, {useEffect, useState} from 'react';
import styles from './CustomerDupCheck.less';
import globalStyles from "../../../App.less";
import {Dialog, ErrorBlock, FloatingBubble, List, SearchBar} from "antd-mobile/2x";
import {HTML_TITLE, URL_CUSTOMER_DUP_CHECK} from "../../../constant";
import {Query, Url} from "../../../api/query";
import {QuestionOutlined} from "@ant-design/icons";
import {GlobalZoom} from "../../../index";

const CustomerDupCheck = () => {
  useEffect(() => {
    document.title = HTML_TITLE[URL_CUSTOMER_DUP_CHECK]
  }, [])

  const [resultList, setResultList] = useState([])
  const [searched, setSearched] = useState(false)

  const queryResult = (value) => {
    if (value === '') {
      Dialog.alert({
        content: '请输入查询关键字',
      })
      return
    }
    setSearched(true)
    Query(Url.customerDupCheck, {name: value}, res => {
      setResultList(res.data.list)
      if (res.data.list.length === 0) {
        Dialog.alert({
          content: '未查询到相关客户',
        })
      }
    })
  }

  return (
    <div>
      <div className={styles.background_pic}/>
      <div className={globalStyles.background_grey}/>
      <div className={globalStyles.vh_frame}>
        <div className={globalStyles.top} style={{padding: '20px 24px 0 24px'}}>
          <div style={{textAlign: "center"}}>
            <div style={{color: 'var(--adm-color-white)', fontSize: '42px', marginTop: '20px'}}>
              客户查重
            </div>
            <div style={{color: '#DDDDDD', fontSize: 'var(--adm-font-size-5)', marginTop: '20px'}}>
              提前查询，避免重复创建客户
            </div>
            <div style={{marginTop: '40px', padding: '0 30px', marginBottom: '55px'}}>
              <SearchBar placeholder='请输入关键字，再按键盘上的搜索(回车)'
                         onSearch={queryResult}
                         onClear={() => {
                           setResultList([])
                           setSearched(false)
                         }}
                         onCancel={() => {
                           setResultList([])
                           setSearched(false)
                         }}
                         style={{'--height': '90px'}}/>
            </div>
          </div>
        </div>

        <div className={globalStyles.body} style={{padding: '0 20px'}}>
          <List header=' ' mode='card'>
            {
              resultList.map((item, index) => {
                return (
                  <List.Item key={index} extra={"负责人：" + item.user_name}>
                    {item.name}
                  </List.Item>
                )
              })
            }
            <List.Item style={{display: resultList.length === 0 ? 'block' : 'none'}}>
              <ErrorBlock status='empty' style={{margin: '20px 0 40px 0'}}
                          description={
                            <span>
                              {searched === false ? '查找客户' : '未查询到相关客户'}
                            </span>
                          }/>
            </List.Item>
          </List>
          <div style={{padding: '80px'}}/>
        </div>

        <FloatingBubble
          style={{
            '--initial-position-bottom': '38px',
            '--initial-position-right': '38px',
            '--edge-distance': '38px',
          }}
          onClick={() => {
            Dialog.alert({
              content: (<div style={{margin: '0 10px'}}>
                <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>关于客户查重</div>
                <div>1. 本功能旨在避免创建重复客户：因权限原因，不能查看非下属同事且未被设为协作同事的客户</div>
                <div>2. 可通过本功能查看是否存在某客户</div>
                <div>3.
                  查询结果左侧为客户名称，右侧为负责该客户的同事，若有需求，可联系负责同事将你设置为该客户的协作同事
                </div>
              </div>),
            })
          }}
        >
          <QuestionOutlined fontSize={32* GlobalZoom}/>
        </FloatingBubble>
      </div>
    </div>
  );
};

export default CustomerDupCheck;