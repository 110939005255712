import React from 'react';
import {
  getSessionAvatar,
  getSessionLeaders,
  getSessionMobile,
  getSessionUserId,
  getSessionUserName
} from "../../storage/storage";
import {Avatar, Button, Dialog, List} from "antd-mobile/2x";
import "./PersonalCenter.css"
import globalStyles from "../../App.less";

const PersonalCenter = () => {
  const userName = getSessionUserName()
  const userId = getSessionUserId()
  const avatar = getSessionAvatar()
  const mobile = getSessionMobile()
  const leaders = getSessionLeaders()
  return <div style={{backgroundColor: '#EDEEF0', width: '100%'}} className={globalStyles.body}>
    <div style={{margin: '50px'}}/>

    <div style={{margin: '25px'}}>
      <List mode='card'>
        <List.Item>

          <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar src={avatar} style={{'--border-radius': '16px', '--size': '128px', display: 'inline-block'}}/>
            <div
              style={{fontSize: '36px', display: 'inline-block', marginLeft: '30px', fontWeight: 'bold'}}>
              {userName}
            </div>
          </div>

        </List.Item>
        <List.Item extra={userId}>用户编号</List.Item>
        <List.Item extra={mobile}>手机号</List.Item>
        {/*<List.Item extra={getSessionAuthorization()}>TOKEN</List.Item>*/}
        <List.Item extra={
          leaders == null ? '' : leaders.map((item, index) => index === (leaders.length - 1) ? (item) : (item + '，')
          )}>直属上级</List.Item>
      </List>
    </div>

    <div style={{margin: '25px'}}>
      <List mode='card' header='系统设置'>
        <List.Item onClick={() => {
          Dialog.alert({
            content: <div style={{
              fontSize: 'var(--adm-font-size-8)',
              textAlign: "center",
              margin: '20px 0'
            }}>
              点击页面右上角<strong> ... </strong>中的<strong> 调整字体 </strong>
            </div>,
          })
        }}>字体大小</List.Item>
      </List>
    </div>

    <div style={{margin: '25px'}}>
      <List mode='card' header='系统说明'>
        <List.Item onClick={() => {
          Dialog.alert({
            content: <div style={{
              fontSize: 'var(--adm-font-size-8)',
              textAlign: "center",
              margin: '20px 0'
            }}>
              文档还没写完，敬请期待...
            </div>,
          })
        }}>使用说明</List.Item>

        <List.Item onClick={() => {
          Dialog.alert({
            content: <div style={{
              fontSize: 'var(--adm-font-size-8)',
              textAlign: "center",
              margin: '20px 0'
            }}>
              反馈问题请编辑邮件至1264855729@qq.com，<br/>或联系系统管理员
            </div>,
          })
        }}>反馈问题</List.Item>
      </List>
    </div>

    <div style={{padding: '10px'}}/>
    <div style={{margin: '50px'}}>
      <Button style={{width: '100%', '--border-radius': '20px'}} color='primary' size='large' onClick={() => {
        window.opener = null;
        window.open('', '_self');
        window.close();
      }
      }>退出系统</Button>
    </div>
    <div style={{padding: '25px'}}/>
  </div>
    ;
};

export default PersonalCenter;