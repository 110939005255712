import React, {useEffect} from 'react';
import {Button, Dialog, Form, Input, Selector, TextArea, Toast} from "antd-mobile/2x";
import globalStyles from "../../../App.less";
import {
  CustomerType,
  HTML_TITLE, PS_RELOAD_CUSTOMER_LIST,
  SESSION_LAST_CUSTOMER_INFO,
  URL_CUSTOMER_CREATE,
  URL_CUSTOMER_DETAIL,
  URL_CUSTOMER_UPDATE
} from "../../../constant";
import MyPicker from "../../MyComponents/Form/MyPicker";
import {Query, Url} from "../../../api/query";
import {useHistory} from "react-router-dom";
import PubSub from "pubsub-js";


const PageType = {
  Create: 0,
  Update: 1
}

const CustomerForm = (props) => {
  const history = useHistory()
  const formRef = React.createRef()
  const [pageType, setPageType] = React.useState(PageType.Create)
  const [customerIdInUpdate, setCustomerIdInUpdate] = React.useState(-1)
  const [customerTypeInUpdate, setCustomerTypeInUpdate] = React.useState(CustomerType.Company)
  const [hasSubmit, setHasSubmit] = React.useState(false)

  const getLastCustomerInfo = () => {
    const lastCustomerInfoStr = sessionStorage.getItem(SESSION_LAST_CUSTOMER_INFO);
    if (lastCustomerInfoStr == null) {
      return {
        id: -1,
        user_id: -1,
        name: "参数错误",
        type: 0,
        level: "普通客户",
        content: "{\"biz_id\":\"\",\"customer_level\":\"\",\"customer_location\":\"\",\"customer_name\":\"\",\"customer_product\":\"\",\"customer_size\":\"\",\"customer_source\":\"\"}",
        created_at: "-",
        updated_at: "-",
      };
    }
    return JSON.parse(lastCustomerInfoStr)
  }

  useEffect(() => {
    if (props.location.pathname === URL_CUSTOMER_CREATE) {
      setPageType(PageType.Create)
      document.title = HTML_TITLE[URL_CUSTOMER_CREATE]
    } else if (props.location.pathname === URL_CUSTOMER_UPDATE) {
      setPageType(PageType.Update)
      document.title = HTML_TITLE[URL_CUSTOMER_UPDATE];

      const customer = props.location.state === undefined ? getLastCustomerInfo() : props.location.state.customer
      const content = JSON.parse(customer.content)
      setCustomerIdInUpdate(customer.id)
      setCustomerTypeInUpdate(customer.type)
      let form;
      (form = formRef.current) === null || form === void 0 ? void 0 : form.setFieldsValue(content);
    }
  }, [])

  const submitForm = (obj) => {
    let value = JSON.parse(JSON.stringify(obj))
    let data = {}
    if (pageType === PageType.Update) {
      data.id = customerIdInUpdate
    }
    data.name = value.customer_name
    data.type = value.customer_type === undefined ? [customerTypeInUpdate] : value.customer_type[0]
    data.biz_id = value.biz_id === undefined ? "" : value.biz_id
    data.level = value.customer_level
    data.content = value
    if (data.customer_type === CustomerType.Person) {
      delete data.customer_location
      delete data.customer_size
    }
    if (pageType === PageType.Create) {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认创建客户？</div>,
        onConfirm: async () => {
          setHasSubmit(true)
          Query(Url.customerCreate, data,
            (res) => {
              Toast.show({
                icon: 'success',
                content: '创建成功',
                duration: 1000,
              })
              PubSub.publish(PS_RELOAD_CUSTOMER_LIST, {})
              setTimeout(() => {
                history.replace({
                    pathname: URL_CUSTOMER_DETAIL,
                    state: {customer: res.data}
                  },
                )
              }, 1500)
            }, (err) => {
              setHasSubmit(false)
            }, () => {
              setHasSubmit(false)
            })
        },
      })

    } else if (pageType === PageType.Update) {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认提交客户修改申请？</div>,
        onConfirm: async () => {
          setHasSubmit(true)
          Query(Url.customerUpdate, data,
            (res) => {
              Toast.show({
                icon: 'success',
                content: (<div style={{textAlign: 'center'}}>提交成功！<br/>请等待直属上级审批</div>),
                duration: 2000,
              })
              PubSub.publish(PS_RELOAD_CUSTOMER_LIST, {})
              setTimeout(() => {
                history.go(-1)
              }, 2500)
            }, (err) => {
              setHasSubmit(false)
            }, () => {
              setHasSubmit(false)
            })
        },
      })

    }
  };

  return (
    <div style={{padding: '0 20px', background: '#EDEEF0'}} className={globalStyles.vh_frame}>
      <div className={globalStyles.body}>

        <Form ref={formRef}
              layout='horizontal'
              mode='card'
              initialValues={{
                customer_type: [customerTypeInUpdate],
              }}
              onFinish={submitForm}
              onFinishFailed={() => {
                Toast.show({
                  icon: 'fail',
                  content: '请检查表单是否填写完整',
                })
              }}
              footer={<Button block type='submit' color='primary' size='large'
                              disabled={hasSubmit}
                              style={{marginBottom: '80px', '--border-radius': '30px'}}>提交</Button>}
        >
          <Form.Header>首要信息</Form.Header>
          <Form.Item label='客户名称' name='customer_name' rules={[{required: true}]}>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='客户类型' name='customer_type'>
            <Selector
              columns={2}
              options={[
                {label: '企业', value: CustomerType.Company},
                {label: '个人', value: CustomerType.Person},
              ]}
              // defaultValue={[customerTypeInUpdate]}
            />
          </Form.Item>
          <Form.Item label='客户编号' name='biz_id' rules={[{required: true}]}>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='客户归属' name='customer_source'>
            <MyPicker
              columns={[['铁路局', '主机厂', '地铁公司', '集成商', '中间商', '竞争商', '合作商', '竞争合作商', '地方铁路', '其他']]}/>
          </Form.Item>
          <Form.Subscribe to={['customer_type']}>
            {
              ({customer_type}) => {
                return (
                  <>
                    {customer_type === undefined || customer_type[0] !== CustomerType.Person ?
                      <>
                        <Form.Item label='企业地址' name='customer_location'>
                          <Input placeholder='请输入详细地址'/>
                        </Form.Item></> : <div/>
                    }
                  </>
                )
              }
            }
          </Form.Subscribe>


          <Form.Header>其他信息</Form.Header>
          <Form.Item label='客户等级' name='customer_level' rules={[{required: true}]}>
            <MyPicker columns={[['普通客户', '重要客户', '特别重要客户']]}/>
          </Form.Item>
          <Form.Subscribe to={['customer_type']}>
            {
              ({customer_type}) => {
                return (
                  <>
                    {customer_type === undefined || customer_type[0] !== CustomerType.Person ?
                      <Form.Item label='企业规模' name='customer_size'>
                        <MyPicker columns={[['小微型企业', '中型企业', '大型企业', '特大型企业']]}/>
                      </Form.Item> : <div/>
                    }
                  </>
                )
              }
            }
          </Form.Subscribe>
          <Form.Item label='主营产品' name='customer_product'>
            <Input placeholder='请输入'/>
          </Form.Item>

          <Form.Header>备注框</Form.Header>
          <Form.Item name='customer_note'>
            <TextArea
              placeholder='备注信息'
              rows={5}
            />
          </Form.Item>
        </Form>
      </div>

    </div>
  );
};

export default CustomerForm;