import React, {useEffect} from 'react';
import {
  HTML_TITLE, PS_RELOAD_CHANCE_LIST, PS_RELOAD_CUSTOMER_LIST,
  SESSION_LAST_CHANCE_INFO,
  URL_CHANCE_DETAIL,
  URL_CHANCE_UPDATE,
  URL_CUSTOMER_DETAIL
} from "../../../constant";
import styles from "./ChanceDetail.less"
import globalStyles from "../../../App.less";
import {
  ActionSheet,
  Button,
  CapsuleTabs,
  Card,
  Dialog,
  Divider,
  FloatingBubble,
  Form,
  Grid,
  List,
  Popup,
  Skeleton,
  Space,
  SpinLoading,
  SwipeAction,
  Tag,
  TextArea,
  Toast
} from "antd-mobile/2x";
import "./ChanceDetail.css"
import {NempStr, SubString} from "../../../utils/StringUtil";
import {useHistory} from "react-router-dom";
import {Query, Url} from "../../../api/query";
import {getSessionUserId} from "../../../storage/storage";
import {QuestionOutlined} from "@ant-design/icons";
import ChineseHelper from "../../../utils/Pinyin/ChineseHelper";
import {RightOutline} from "antd-mobile-icons";
import MyEmpty from "../../MyComponents/MyEmpty/MyEmpty";
import {GlobalZoom} from "../../../index";
import PubSub from "pubsub-js";


const tabKeys = ['0', '1', '2']
let tabKey = tabKeys[0]

const ChanceDetail = (props) => {
    useEffect(() => {
      document.title = HTML_TITLE[URL_CHANCE_DETAIL]
      sessionStorage.setItem(SESSION_LAST_CHANCE_INFO, JSON.stringify(chance))
      getChanceRU()
      tryGetCustomer()
      if (tabKey !== tabKeys[0]) {
        tabChange(tabKey)
      }
    }, [])


    const history = useHistory()

    const getLastChanceInfo = () => {
      const lastChanceInfoStr = sessionStorage.getItem(SESSION_LAST_CHANCE_INFO);
      if (lastChanceInfoStr == null) {
        return {
          id: -1,
          user_id: -1,
          name: "参数错误",
          type: 0,
          level: "普通客户",
          content: {},
          created_at: "-",
          updated_at: "-",
        };
      }
      return JSON.parse(lastChanceInfoStr)
    }
    const chance = props.location.state === undefined ? getLastChanceInfo() : props.location.state.chance
    const content = JSON.parse(chance.content)

    const [detailActionSheetVisible, setDetailActionSheetVisible] = React.useState(false);
    const [messageList, setMessageList] = React.useState([]);
    const [messageListLoading, setMessageListLoading] = React.useState(false);
    const [canUpdate, setCanUpdate] = React.useState(false);
    const [canDelete, setCanDelete] = React.useState(false);
    const [manager, setManager] = React.useState({});
    const [relevants, setRelevants] = React.useState([]);
    const [userPopupVisible, setUserPopupVisible] = React.useState(false);
    const [userListLoading, setUserListLoading] = React.useState(false);
    const [userList, setUserList] = React.useState([]);
    const [customerTry, setCustomerTry] = React.useState({});
    const [customerAuth, setCustomerAuth] = React.useState(false);

    const getChanceRU = () => {
      Query(Url.chanceRUQueryByChanceId, {chanceId: chance.id},
        (res) => {
          setManager(res.data.manager)
          setRelevants(res.data.relevants)
          if (res.data.manager.id === getSessionUserId()) {
            setCanUpdate(true)
            setCanDelete(true)
            return
          }
          for (let i = 0; i < res.data.relevants.length; i++) {
            if (res.data.relevants[i].id === getSessionUserId()) {
              setCanUpdate(true)
              break
            }
          }
        })
    }

    const tryGetCustomer = () => {
      Query(Url.customerTryQueryById, {id: chance.customer_id},
        (res) => {
          setCustomerTry(res.data)
          if (res.data.id !== 0) {
            setCustomerAuth(true)
          }
        })
    }

    const detailTab = () => {
      const submitDeleteChance = () => {
        Dialog.confirm({
          content: <div style={{
            fontSize: 'var(--adm-font-size-8)',
            textAlign: "center",
            margin: '20px 0'
          }}>确认删除该商机？</div>,
          onConfirm: async () => {
            const data = {id: chance.id}
            Query(Url.chanceDelete, data, (res) => {
              Toast.show({
                icon: 'success',
                content: '删除成功',
                duration: 1000,
              })
              PubSub.publish(PS_RELOAD_CHANCE_LIST, {})
              setTimeout(() => {
                history.go(-1)
              }, 1500)
            })
          },
        })

      }
      return (<div>
          <List header='基本信息'
                mode={"card"}
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                }}>
            <List.Item prefix='产品'>{NempStr(content.chance_product)}</List.Item>
            <List.Item prefix='金额'>{NempStr(content.chance_budget)}</List.Item>
            <List.Item prefix='预计签约/招标'>{NempStr(content.chance_day)}</List.Item>
          </List>
          <List header='需求描述'
                mode={"card"}
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                }}>
            <List.Item>{NempStr(content.chance_description)}</List.Item>
          </List>
          <List header='竞品分析'
                mode={"card"}
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                }}>
            <List.Item>{NempStr(content.competitive_analysis)}</List.Item>
          </List>
          <List header='备注'
                mode={"card"}
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                }}>
            <List.Item>{NempStr(content.competitive_note)}</List.Item>
          </List>
          <div style={{padding: '50px 0'}}/>

          <div style={{textAlign: 'center', display: canUpdate ? 'block' : 'none'}}>
            <Button color='primary' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                    onClick={() => {
                      setDetailActionSheetVisible(true)
                    }}>
              <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>变更商机</div>
            </Button>
          </div>
          <ActionSheet
            visible={detailActionSheetVisible}
            actions={[
              {
                text: '修改', key: 'update',
                onClick: () => {
                  history.push({pathname: URL_CHANCE_UPDATE, state: {chance, customer: customerTry}})
                }
              },
              {
                text: '完成', key: 'delete', description: '已完成并移除该商机', disabled: !canDelete,
                onClick: () => {
                  submitDeleteChance()
                }
              }]}
            onClose={() => setDetailActionSheetVisible(false)}
          />
        </div>

      )
    }

    const messageTab = () => {

      const messageFormRef = React.createRef()
      const submitCreateMessage = (value) => {
        if (value.content === undefined || value.content === '') {
          Toast.show({
            icon: 'fail',
            content: '内容不能为空',
            duration: 1000,
          })
          return
        }

        value['chance_id'] = chance.id
        Dialog.confirm({
          content: <div style={{
            fontSize: 'var(--adm-font-size-8)',
            textAlign: "center",
            margin: '20px 0'
          }}>确认发布信息？</div>,
          onConfirm: async () => {
            Query(Url.chanceMessageCreate, value, (res) => {
              console.log(res)
              setMessageList([...messageList, res.data])
              let form;
              (form = messageFormRef.current) === null || form === void 0 ? void 0 : form.setFieldsValue({content: ''})
              Toast.show({
                icon: 'success',
                content: '发布成功',
                duration: 500,
              })
            })
          },
        })
      }

      return (
        <div>
          <List mode="card"
                style={{
                  '--prefix-width': '200px',
                  '--font-size': '30px',
                  display: messageListLoading ? 'none' : 'block'
                }}>
            {messageList.map((item, index) => {
              return (
                <List.Item style={{marginRight: '20px'}} key={index}
                           title={
                             <Grid columns={3}>
                               <Grid.Item>
                                 {item.user_name}
                               </Grid.Item>
                               <Grid.Item span={2}>
                                 <div style={{textAlign: 'right'}}>
                                   {SubString(item.created_at, 0, 19)}
                                 </div>
                               </Grid.Item>
                             </Grid>}>
                  <div style={{fontSize: 'var(--adm-font-size-7)', marginTop: '10px'}}>
                    {item.content}
                  </div>
                </List.Item>
              )
            })}
            <List.Item style={{display: messageList.length === 0 ? 'block' : 'none'}}>
              <div style={{margin: '30px 0 50px 0'}}>
                <MyEmpty description='还没有跟进信息'/>
              </div>
            </List.Item>
          </List>
          <List mode="card" style={{display: messageListLoading ? 'block' : 'none'}}>
            <List.Item>
              <div style={{margin: '20px 50px 20px 50px'}}>
                <Skeleton.Title animated/>
                <Skeleton.Paragraph lineCount={5} animated/>
              </div>
            </List.Item>
          </List>

          <div style={{padding: '25px 0'}}/>
          <div style={{
            display: canUpdate ? 'block' : 'none',
            background: '#E3E3E3',
            borderRadius: '30px',
            margin: '0 20px'
          }}>
            <Form
              ref={messageFormRef}
              mode='card'
              name='form'
              onFinish={submitCreateMessage}
              style={{padding: '15px 5px 0px 5px'}}
              footer={<Button block type='submit' color='primary' size='large'
                              style={{'--border-radius': '30px'}}>发布</Button>}
            >
              <Form.Item name='content'>
                <TextArea
                  placeholder='在此输入跟进信息，发布后将通知该商机的所有协作同事'
                  rows={4}
                />
              </Form.Item>
            </Form>
          </div>
          <div style={{padding: '30px 0'}}/>
        </div>)
    }

    const relevantTab = () => {
      const submitDeleteRelevant = (userId) => {
        Dialog.confirm({
          content: <div style={{
            fontSize: 'var(--adm-font-size-8)',
            textAlign: "center",
            margin: '20px 0'
          }}>确认移除该协作同事？</div>,
          onConfirm: async () => {
            Query(Url.chanceRUDelete, {chance_id: chance.id, user_id: userId}, (res) => {
              Toast.show({
                icon: 'success',
                content: '移除成功',
                duration: 1000,
              })
              setRelevants(relevants.filter((item) => item.id !== userId))
            })
          },
        })
      }

      const submitCreateRelevantUser = (userId, userName, username) => {
        Dialog.confirm({
          content: <div style={{
            fontSize: 'var(--adm-font-size-8)',
            textAlign: "center",
            margin: '20px 0'
          }}>确认将 <b>{userName}</b><br/> 设为该商机的协作同事？</div>,
          onConfirm: async () => {
            Query(Url.chanceRUCreate, {chance_id: chance.id, user_id: userId}, (res) => {
              Toast.show({
                icon: 'success',
                content: '设置成功',
                duration: 1000,
              })
              setRelevants([...relevants, {id: userId, name: userName, username: username}])
            })
          },
        })
        setUserPopupVisible(false)
      }

      const chooseUser = () => {
        setUserPopupVisible(true)

        setUserListLoading(true)
        Query(Url.userQuery, null,
          (res) => {
            let list = res.data.list
            list = list.filter((item) => {
              let flag = true
              if (item.id === manager.id) {
                return false
              }
              for (let i = 0; i < relevants.length; i++) {
                if (item.id === relevants[i].id) {
                  flag = false
                  break
                }
              }
              return flag
            })

            for (let i = 0; i < list.length; i++) {
              list[i]["enName"] = ChineseHelper.ConvertPinyin(list[i]["name"])
            }
            list.sort((o1, o2) => o1.enName.localeCompare(o2.enName, 'en'))

            setUserList(list)
            setUserListLoading(false)
          },
          (res) => {
            setUserListLoading(false)
          },
          () => {
            setUserListLoading(false)
          }
        )
      }

      return (<div>
        <List mode={"card"}
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          <List.Item style={{paddingLeft: '50px'}}>
            {manager.name}
            <Tag color='primary' fill='outline' style={{marginLeft: '20px', '--border-radius': '6px'}}>
              商机创建人
            </Tag>
          </List.Item>

          {relevants.map((relevant, index) => {
            return (
              <SwipeAction key={index} rightActions={
                canDelete ? [{
                  key: 'delete',
                  text: '删除',
                  color: 'danger',
                  onClick: () => {
                    submitDeleteRelevant(relevant.id)
                  }
                }] : []}>
                <List.Item style={{paddingRight: '30px'}}>
                  <div style={{fontSize: 'var(--adm-font-size-7)', marginLeft: '30px'}}>{relevant.name}</div>
                </List.Item>
              </SwipeAction>
            )
          })
          }
        </List>

        <div style={{padding: '50px 0'}}/>
        <div style={{textAlign: "center", display: canUpdate ? 'block' : 'none'}}>
          <Button color='primary' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                  onClick={() => {
                    chooseUser()
                  }}>
            <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>新增协作同事</div>
          </Button>
        </div>

        <Popup
          visible={userPopupVisible}
          onMaskClick={() => {
            setUserPopupVisible(false)
          }}
          position='right'
          bodyStyle={{width: 'var(--80vw)'}}
        >
          <div className={globalStyles.frame}>
            <div style={{
              fontSize: 'var(--adm-font-size-8)',
              padding: '20px',
              color: 'var(--adm-color-text)',
              textAlign: "center"
            }}
                 className={globalStyles.top_b}>选择用户
            </div>
            <List className={globalStyles.body}
                  style={{display: userListLoading ? 'none' : 'block', overflowY: 'scroll'}}>
              {
                userList.map((item, index) => {
                  return (
                    <List.Item key={index} onClick={() => {
                      submitCreateRelevantUser(item.id, item.name, item.username)
                    }}>
                      {item.name}
                    </List.Item>
                  )
                })
              }
            </List>
          </div>
          <div style={{margin: '20px 50px 20px 50px', display: userListLoading ? 'block' : 'none'}}>
            <Skeleton.Title animated/>
            <Skeleton.Paragraph lineCount={5} animated/>
          </div>

        </Popup>

        <FloatingBubble
          style={{
            '--initial-position-bottom': '38px',
            '--initial-position-right': '38px',
            '--edge-distance': '38px',
            display: canUpdate ? 'block' : 'none'
          }}
          onClick={() => {
            Dialog.alert({
              content: (<div style={{margin: '0 10px'}}>
                <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>关于协作同事</div>
                <div>1. 协作同事可与商机创建人一起管理该商机</div>
                <div>2. 协作同事拥有除“完成商机、新增和删除其他协作同事”外的所有权限</div>
                <div>3. 向左滑动协作同事，可移除该协作同事</div>
              </div>),
            })
          }}
        >
          <QuestionOutlined fontSize={32 * GlobalZoom}/>
        </FloatingBubble>
      </div>)
    }

    const tabChange = (number) => {
      tabKey = number
      if (number === tabKeys[2]) {
        setMessageListLoading(true)
        Query(Url.chanceMessageQueryByChanceId, {chance_id: chance.id}, (res) => {
          console.log(res)
          setMessageList(res.data.list === null ? [] : res.data.list)
          setMessageListLoading(false)
        })
      }
    }

    return (
      <div>
        <div className={styles.background_pic}/>
        <div className={globalStyles.background_grey}/>

        <div className={globalStyles.vh_frame}>
          <div style={{margin: '40px 50px 30px 50px'}} className={globalStyles.top}>
            <Card style={{boxShadow:'0 5px 10px 0 rgb(0 0 0 / 10%)'}}>
              <div style={{fontSize: '40px', fontWeight: 'bold'}}>
                {chance.name}
              </div>
              <Divider/>
              <div style={{marginTop: '20px', color: 'var(--adm-color-text-secondary)'}}>
                <Grid columns={2}>
                  <Grid.Item>
                    创建时间：{SubString(chance.created_at, 0, 10)}
                  </Grid.Item>
                  <Grid.Item>
                    更新时间：{SubString(chance.updated_at, 0, 10)}
                  </Grid.Item>
                </Grid>
              </div>
            </Card>

            <div style={{margin: '20px'}}/>
            <Card style={{boxShadow:'0 5px 10px 0 rgb(0 0 0 / 10%)'}} onClick={() => {
              if (customerAuth) {
                history.push({
                  pathname: URL_CUSTOMER_DETAIL,
                  state: {customer: customerTry}
                })
              } else {
                Dialog.alert({
                  content: <div style={{
                    fontSize: 'var(--adm-font-size-8)',
                    textAlign: "center",
                    margin: '20px 0',
                  }}>您无法查看该用户
                    <div style={{marginTop: '20px', fontSize: 'var(--adm-font-size-5)'}}>
                      请联系该客户的创建人将您添加为协作同事（可在客户查重查找该客户的创建人）
                    </div>
                  </div>,
                })
              }
            }}>
              {customerTry.id === undefined
                ? <Space><SpinLoading color='primary' style={{'--size': '20px'}}/></Space>
                : <span style={{fontWeight: 'bold'}}>{customerTry.name}</span>}

              <span style={{float: 'right', display: customerAuth ? 'block' : 'none'}}><RightOutline/></span>
            </Card>
          </div>
          <div className={globalStyles.body} style={{padding: '0 20px'}}>
            <CapsuleTabs defaultActiveKey={tabKey} className={globalStyles.frame} onChange={tabChange}
                         style={{'--adm-color-box': '#FFFFFF'}}>
              <CapsuleTabs.Tab title='商机详情' key={tabKeys[0]}>
                {detailTab()}
              </CapsuleTabs.Tab>
              <CapsuleTabs.Tab title='协作同事' key={tabKeys[1]}>
                {relevantTab()}
              </CapsuleTabs.Tab>
              <CapsuleTabs.Tab title='商机跟进' key={tabKeys[2]}>
                {messageTab()}
              </CapsuleTabs.Tab>
            </CapsuleTabs>
          </div>
        </div>
      </div>
    );
  }
;

export default ChanceDetail;
