import {
  SESSION_AUTHORIZATION,
  SESSION_AVATAR,
  SESSION_LEADERS,
  SESSION_MOBILE,
  SESSION_USER_NAME,
  SESSION_USERID,
} from "../constant";
import {IS_MOCK} from "../api/query";

let sessionAuthorization = undefined
export const setSessionAuthorization = (authorization) => {
  sessionAuthorization = authorization
  sessionStorage.setItem(SESSION_AUTHORIZATION, authorization)
}
export const getSessionAuthorization = () => {
  if (IS_MOCK) {
    return 'token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJKV1RfSURFTlRJVFlfRklFTEQiOnsiaWQiOjIsInVzZXJuYW1lIjoiTGlZaVh1YW4iLCJuYW1lIjoi5p2O6YC46L2pIiwidHlwZSI6IlVTRVJfVFlQRSJ9LCJleHAiOjE2ODQxMDc0NTUsIm9yaWdfaWF0IjoxNjgwNTA3NDU1fQ.b18qC_Naa2AQYzioJPfHLfBhkG6i7E4dg5hCK1vLqTA' //2
    // return 'token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJKV1RfSURFTlRJVFlfRklFTEQiOnsiaWQiOjEsInVzZXJuYW1lIjoiTGlZaVh1YW4iLCJuYW1lIjoi5p2O6YC46L2pIiwidHlwZSI6IlVTRVJfVFlQRSJ9LCJleHAiOjE2ODQxMDg4OTUsIm9yaWdfaWF0IjoxNjgwNTA4ODk1fQ.XtxuwckAI7uKpH2BoVxpU8YopvzJXcqP_y5Q70A4L3A' //1
  }
  if (sessionAuthorization === undefined) {
    sessionAuthorization = sessionStorage.getItem(SESSION_AUTHORIZATION)
  }
  if (sessionAuthorization === undefined || sessionAuthorization === null) {

    return undefined
  }
  return sessionAuthorization
}


let sessionUserid = undefined;
export const setSessionUserid = (userid) => {
  sessionUserid = Number(userid);
  sessionStorage.setItem(SESSION_USERID, userid);
}
export const getSessionUserId = () => {
  if (IS_MOCK) {
    return 2
  }
  if (sessionUserid === undefined) {
    sessionUserid = Number(sessionStorage.getItem(SESSION_USERID));
  }
  return sessionUserid;
}


let sessionUserName = undefined;
export const setSessionUserName = (userName) => {
  sessionUserName = userName;
  sessionStorage.setItem(SESSION_USER_NAME, userName);
}
export const getSessionUserName = () => {
  if (IS_MOCK) {
    return "MOCK_USER"
  }
  if (sessionUserName === undefined) {
    sessionUserName = sessionStorage.getItem(SESSION_USER_NAME);
  }
  return sessionUserName;
}

let sessionMobile = undefined;
export const setSessionMobile = (mobile) => {
  sessionMobile = mobile;
  sessionStorage.setItem(SESSION_MOBILE, mobile);
}
export const getSessionMobile = () => {
  if (sessionMobile === undefined) {
    sessionMobile = sessionStorage.getItem(SESSION_MOBILE);
  }
  return sessionMobile;
}

let sessionAvatar = undefined;
export const setSessionAvatar = (avatar) => {
  sessionAvatar = avatar;
  sessionStorage.setItem(SESSION_AVATAR, avatar);
}
export const getSessionAvatar = () => {
  if (sessionAvatar === undefined) {
    sessionAvatar = sessionStorage.getItem(SESSION_AVATAR);
  }
  return sessionAvatar;
}

let sessionLeaders = undefined;
export const setSessionLeaders = (leaders) => {
  sessionLeaders = leaders;
  sessionStorage.setItem(SESSION_LEADERS, leaders);
}

export const getSessionLeaders = () => {
  if (sessionLeaders === undefined) {
    sessionLeaders = sessionStorage.getItem(SESSION_LEADERS);
  }
  return JSON.parse(sessionLeaders)
}