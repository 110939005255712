import React, {useEffect} from 'react';
import {
  HTML_TITLE,
  PS_RELOAD_LINKMAN_LIST,
  SESSION_LAST_CUSTOMER_IN_LINKMAN_INFO,
  SESSION_LAST_LINKMAN_INFO,
  URL_CUSTOMER_DETAIL,
  URL_LINKMAN_DETAIL,
  URL_LINKMAN_UPDATE
} from "../../../constant";
import styles from "./LinkmanDetail.less";
import globalStyles from "../../../App.less";
import {ActionSheet, Button, CapsuleTabs, Card, Dialog, Divider, Grid, List, Toast} from "antd-mobile/2x";
import {RightOutline} from "antd-mobile-icons";
import {Query, Url} from "../../../api/query";
import {NempStr, SubString} from "../../../utils/StringUtil";
import {useHistory} from "react-router-dom";
import {getSessionUserId} from "../../../storage/storage";
import {MapApi, MapNotice} from "../../../api/map";
import PubSub from "pubsub-js";


const tabKeys = ['0']
let tabKey = tabKeys[0]

const LinkmanDetail = (props) => {
  const history = useHistory();
  useEffect(() => {
    document.title = HTML_TITLE[URL_LINKMAN_DETAIL]
    getCustomerRU()
    sessionStorage.setItem(SESSION_LAST_LINKMAN_INFO, JSON.stringify(linkman))
    sessionStorage.setItem(SESSION_LAST_CUSTOMER_IN_LINKMAN_INFO, JSON.stringify(customer))
  }, [])

  const getLastLinkmanInfo = () => {
    const lastLinkmanInfoStr = sessionStorage.getItem(SESSION_LAST_LINKMAN_INFO);
    if (lastLinkmanInfoStr == null) {
      return {
        id: -1,
        user_id: -1,
        customerId: -1,
        name: "参数错误",
        content: "{}",
        created_at: "-",
        updated_at: "-",
      };
    }
    return JSON.parse(lastLinkmanInfoStr)
  }
  const getLastCustomerInLinkmanInfo = () => {
    const lastCustomerInLinkmanStr = sessionStorage.getItem(SESSION_LAST_CUSTOMER_IN_LINKMAN_INFO);
    if (lastCustomerInLinkmanStr == null) {
      return {
        id: -1,
        user_id: -1,
        name: "参数错误",
        type: 0,
        level: "普通客户",
        content: "",
        created_at: "-",
        updated_at: "-",
      };
    }
    return JSON.parse(lastCustomerInLinkmanStr)
  }
  const linkman = props.location.state === undefined ? getLastLinkmanInfo() : props.location.state.linkman
  const customer = props.location.state === undefined ? getLastCustomerInLinkmanInfo() : props.location.state.customer
  const content = JSON.parse(linkman.content)

  const [canUpdate, setCanUpdate] = React.useState(false);
  const [canDelete, setCanDelete] = React.useState(false);
  const [detailActionSheetVisible, setDetailActionSheetVisible] = React.useState(false);

  const getCustomerRU = () => {
    Query(Url.customerRUQueryByCustomerId, {customerId: customer.id},
      (res) => {
        if (res.data.manager.id === getSessionUserId()) {
          setCanUpdate(true)
          setCanDelete(true)
          return
        }
        for (let i = 0; i < res.data.relevants.length; i++) {
          if (res.data.relevants[i].id === getSessionUserId()) {
            setCanUpdate(true)
            setCanDelete(true)
            break
          }
        }
      })
  }

  const detailTab = () => {

    const submitDeleteLinkman = () => {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认提交删除申请？</div>,
        onConfirm: async () => {
          const data = {id: linkman.id}
          Query(Url.linkmanDelete, data, (res) => {
            Toast.show({
              icon: 'success',
              content: '提交成功',
              duration: 1000,
            })
          })
          PubSub.publish(PS_RELOAD_LINKMAN_LIST, {})
          setTimeout(() => {
            history.go(-1)
          }, 1500)
        },
      })

    }

    return (<div>
        <List mode='card' header="个人信息"
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          <List.Item prefix='联系人姓名'>{NempStr(content.linkman_name)}</List.Item>
          <List.Item prefix='性别'>{NempStr(content.linkman_sex)}</List.Item>
          <List.Item prefix='出生日期'>{NempStr(content.linkman_birthday)}</List.Item>
          <List.Item prefix='爱好'>{NempStr(content.linkman_hobby)}</List.Item>
          <List.Item prefix='家庭住址'>{NempStr(content.linkman_home_location)}</List.Item>
          {/*<List.Item/>*/}
        </List>

        <List mode='card' header={'电话号码'}
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          {
            content.phones !== undefined && content.phones !== null ?
              content.phones.map((item, index) => {
                return (
                  <List.Item prefix={<div>号码{index + 1}</div>} key={index + 1}>
                    <a href={"tel:" + item.phone} style={{textDecoration: 'none'}}>
                      {NempStr(item.phone)}
                    </a>
                  </List.Item>
                )
              }) :
              null
          }
        </List>

        <List mode='card' header="职务信息"
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          <List.Item prefix='部门'>{NempStr(content.linkman_depart)}</List.Item>
          <List.Item prefix='职务'>{NempStr(content.linkman_post)}</List.Item>
          <List.Item prefix='办公地址'>
            <div style={{color: 'var(--adm-color-primary)'}} onClick={() => {
              Dialog.confirm({
                content: (<div style={{margin: '0 10px'}}>
                  <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>提示</div>
                  {MapNotice()}
                </div>),
                onConfirm: () => {
                  window.open(`${MapApi}${content.linkman_work_location}`)
                },
              })
            }
            }>
              {NempStr(content.linkman_work_location)}
            </div>
            {/*<a href={`${MapApi}${content.linkman_work_location}`} style={{textDecoration:'none'}} >*/}
            {/*  {NempStr(content.linkman_work_location)}*/}
            {/*</a>*/}
          </List.Item>
          <List.Item prefix='办公室'>{NempStr(content.linkman_work_room)}</List.Item>
        </List>

        <List mode='card' header="备注框" style={{
          '--prefix-width': '200px',
          '--font-size': '30px',
        }}>
          <List.Item>{NempStr(content.linkman_note)}</List.Item>
        </List>

        <div>
          {
            content.relative !== undefined && content.relative !== null ?
              content.relative.map((item, index) => {
                return (
                  <div key={index + 1}>
                    <List mode='card' header={'相关人' + (index + 1)}
                          style={{
                            '--prefix-width': '200px',
                            '--font-size': '30px',
                          }}>
                      <List.Item prefix='姓名'>{NempStr(item.name)}</List.Item>
                      <List.Item prefix='关系'>{NempStr(item.relation)}</List.Item>
                      <List.Item prefix='出生年份'>{NempStr(item.birthday)}</List.Item>
                      <List.Item prefix='爱好'>{NempStr(item.hobby)}</List.Item>
                      <List.Item prefix='备注'>{NempStr(item.note)}</List.Item>
                    </List>
                  </div>
                )
              }) :
              null
          }
        </div>

        <div style={{padding: '50px 0'}}/>
        <div style={{textAlign: 'center', display: canUpdate ? 'block' : 'none'}}>
          <Button color='primary' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                  onClick={() => {
                    setDetailActionSheetVisible(true)
                  }}>
            <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>申请变更</div>
          </Button>
        </div>
        <ActionSheet
          visible={detailActionSheetVisible}
          actions={[
            {
              text: '修改', key: 'update', description: '提交后交由直属上级审批', disabled: !canUpdate,
              onClick: () => {
                history.push({pathname: URL_LINKMAN_UPDATE, state: {linkman, customer}})
              }
            },
            {
              text: '删除', key: 'delete', description: '提交后交由直属上级审批', disabled: !canDelete,
              onClick: () => {
                submitDeleteLinkman()
              }
            }]}
          onClose={() => setDetailActionSheetVisible(false)}
        />
      </div>

    )

  }

  return (
    <div>
      <div className={styles.background_pic}/>
      <div className={globalStyles.background_grey}/>

      <div className={globalStyles.vh_frame}>
        <div style={{margin: '40px 50px 20px 50px'}} className={globalStyles.top}>
          <Card style={{boxShadow:'0 5px 10px 0 rgb(0 0 0 / 10%)'}}>
            <div style={{fontSize: '40px', fontWeight: 'bold'}}>
              {linkman.name}
            </div>
            <Divider/>
            <div style={{marginTop: '20px', color: 'var(--adm-color-text-secondary)'}}>
              <Grid columns={2}>
                <Grid.Item>
                  创建时间：{SubString(linkman.created_at, 0, 10)}
                </Grid.Item>
                <Grid.Item>
                  更新时间：{SubString(linkman.updated_at, 0, 10)}
                </Grid.Item>
              </Grid>
            </div>
          </Card>
          <div style={{marginTop: '20px'}}/>
          <Card style={{boxShadow:'0 5px 10px 0 rgb(0 0 0 / 10%)'}} onClick={() => {
            history.push({
              pathname: URL_CUSTOMER_DETAIL,
              state: {customer: customer}
            })
          }}>
            所属客户：<span style={{fontWeight: 'bold'}}>{customer.name}</span>
            <span style={{float: 'right'}}><RightOutline/></span>
          </Card>
        </div>
        <div className={globalStyles.body} style={{padding: '0 20px'}}>
          <CapsuleTabs defaultActiveKey={tabKey} className={globalStyles.frame}
                       style={{'--adm-color-primary': '#59BB8F', '--adm-color-box': '#FFFFFF'}}>
            <CapsuleTabs.Tab title='联系人详情' key={tabKeys[0]}>
              {detailTab()}
            </CapsuleTabs.Tab>
          </CapsuleTabs>
        </div>
      </div>
    </div>
  );
};

export default LinkmanDetail;