import React, {useEffect} from 'react';
import {
  HTML_TITLE,
  PS_RELOAD_MERCHANT_LIST,
  SESSION_LAST_MERCHANT_INFO,
  URL_MERCHANT_DETAIL,
  URL_MERCHANT_UPDATE
} from "../../../constant";
import styles from "./MerchantDetail.less";
import globalStyles from "../../../App.less";
import {ActionSheet, Button, CapsuleTabs, Card, Dialog, Divider, Grid, List, Toast} from "antd-mobile/2x";
import {Query, Url} from "../../../api/query";
import {NempStr, SubString} from "../../../utils/StringUtil";
import {useHistory} from "react-router-dom";
import {MerchantTypeText} from "../MerchantForm/MerchantForm";
import {MapApi, MapNotice} from "../../../api/map";
import PubSub from "pubsub-js";

const MerchantDetail = (props) => {
  const history = useHistory();
  useEffect(() => {
    document.title = HTML_TITLE[URL_MERCHANT_DETAIL]
    sessionStorage.setItem(SESSION_LAST_MERCHANT_INFO, JSON.stringify(merchant))

  }, [])

  const getLastMerchantInfo = () => {
    const lastMerchantInfoStr = sessionStorage.getItem(SESSION_LAST_MERCHANT_INFO);
    if (lastMerchantInfoStr == null) {
      return {
        id: -1,
        name: "参数错误",
        content: "{}",
        created_at: "-",
        updated_at: "-",
      };
    }
    return JSON.parse(lastMerchantInfoStr)
  }

  const merchant = props.location.state === undefined ? getLastMerchantInfo() : props.location.state.merchant
  const content = JSON.parse(merchant.content)

  const [detailActionSheetVisible, setDetailActionSheetVisible] = React.useState(false);

  const detailTab = () => {
    const submitDeleteMerchant = () => {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认提交删除申请？</div>,
        onConfirm: async () => {
          const data = {id: merchant.id}
          Query(Url.merchantDelete, data, (res) => {
            Toast.show({
              icon: 'success',
              content: '提交成功',
              duration: 1000,
            })
            PubSub.publish(PS_RELOAD_MERCHANT_LIST, {})
            setTimeout(() => {
              history.go(-1)
            }, 1500)
          })
        },
      })

    }

    return (<div>
        <List mode='card' header="详细信息"
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          <List.Item prefix='联系人姓名'>{NempStr(content.linkman_name)}</List.Item>
          <List.Item prefix='联系电话'>
            {

              content.linkman_phone !== undefined && content.linkman_phone !== null && content.linkman_phone !== ""
                ? content.linkman_phone.split(" ").filter((item) => item !== "").map((item, index) => {
                  return (<div><a key={index + 1} href={"tel:" + item} style={{textDecoration: 'none'}}>
                    {NempStr(item)}
                  </a></div>)
                })
                : NempStr('')
            }
            {/*<a href={"tel:" + content.linkman_phone} style={{textDecoration:'none'}} >*/}
            {/*  {NempStr(content.linkman_phone)}*/}
            {/*</a>*/}
          </List.Item>
          <List.Item prefix='地址'>
            <div style={{color: 'var(--adm-color-primary)'}} onClick={() => {
              Dialog.confirm({
                content: (<div style={{margin: '0 10px'}}>
                  <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>提示</div>
                  {MapNotice()}
                </div>),
                onConfirm: () => {
                  window.open(`${MapApi}${merchant.city}${content.merchant_location}${merchant.name}`)
                },
              })
            }
            }>
              {NempStr(content.merchant_location)}
            </div>
            {/*<a href={`${MapApi}${content.merchant_location}`} style={{textDecoration:'none'}} >*/}
            {/*  {NempStr(content.merchant_location)}*/}
            {/*</a>*/}
          </List.Item>
        </List>


        <List mode='card' header="备注框" style={{
          '--prefix-width': '200px',
          '--font-size': '30px',
        }}>
          <List.Item>{NempStr(content.merchant_note)}</List.Item>
        </List>

        <div style={{padding: '50px 0'}}/>
        <div style={{textAlign: 'center'}}>
          <Button color='primary' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                  onClick={() => {
                    setDetailActionSheetVisible(true)
                  }}>
            <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>申请变更</div>
          </Button>
        </div>
        <ActionSheet
          visible={detailActionSheetVisible}
          actions={[
            {
              text: '修改', key: 'update', description: '提交后交由直属上级审批',
              onClick: () => {
                history.push({pathname: URL_MERCHANT_UPDATE, state: {merchant}})
              }
            },
            {
              text: '删除', key: 'delete', description: '提交后交由直属上级审批',
              onClick: () => {
                submitDeleteMerchant()
              }
            }]}
          onClose={() => setDetailActionSheetVisible(false)}
        />
      </div>

    )

  }

  return (
    <div>
      <div className={styles.background_pic}/>
      <div className={globalStyles.background_grey}/>

      <div className={globalStyles.vh_frame}>
        <div style={{margin: '40px 50px 20px 50px'}} className={globalStyles.top}>
          <Card style={{boxShadow:'0 5px 10px 0 rgb(0 0 0 / 10%)'}}>
            <div style={{fontSize: '40px', fontWeight: 'bold'}}>
              {merchant.name}
            </div>
            <Divider/>
            <div style={{marginTop: '20px', color: 'var(--adm-color-text-secondary)'}}>
              <Grid columns={2}>
                <Grid.Item>
                  城市：{merchant.city}
                </Grid.Item>
                <Grid.Item>
                  类型：{MerchantTypeText[Number(merchant.type) - 1]}
                </Grid.Item>
              </Grid>
            </div>
            <div style={{marginTop: '20px', color: 'var(--adm-color-text-secondary)'}}>
              <Grid columns={2}>
                <Grid.Item>
                  创建时间：{SubString(merchant.created_at, 0, 10)}
                </Grid.Item>
                <Grid.Item>
                  更新时间：{SubString(merchant.updated_at, 0, 10)}
                </Grid.Item>
              </Grid>
            </div>
          </Card>
          <div style={{marginTop: '20px'}}/>
        </div>
        <div className={globalStyles.body} style={{padding: '0 20px'}}>
          <CapsuleTabs defaultActiveKey={'0'} className={globalStyles.frame}
                       style={{'--adm-color-primary': '#59BB8F', '--adm-color-box': '#FFFFFF'}}>
            <CapsuleTabs.Tab title='商户详情' key={'0'}>
              {detailTab()}
            </CapsuleTabs.Tab>
          </CapsuleTabs>
        </div>
      </div>
    </div>
  );
};

export default MerchantDetail;