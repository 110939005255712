import React, {useEffect, useState} from 'react';
import {CapsuleTabs, FloatingBubble, IndexBar, List, SearchBar, Skeleton, Tag} from "antd-mobile/2x";
import {
  CustomerType,
  HTML_TITLE, PS_RELOAD_CUSTOMER_LIST, PS_CLEAR_UNREAD_NOTIFICATION,
  URL_CUSTOMER_CREATE,
  URL_CUSTOMER_DETAIL,
  URL_CUSTOMER_LIST,
} from "../../../constant";
import globalStyles from "../../../App.less";
import "./CustomerList.css"
import {Query, Url} from "../../../api/query";
import ChineseHelper from "../../../utils/Pinyin/ChineseHelper";
import {AddOutline} from "antd-mobile-icons";
import {useHistory} from "react-router-dom";
import MyEmpty from "../../MyComponents/MyEmpty/MyEmpty";
import {GlobalZoom} from "../../../index";
import PubSub from "pubsub-js";

const QueryType = {
  MyType: "MyType",
  UnderlingType: "UnderlingType",
}

const tabKeys = ['0', '1']
let tabKey = tabKeys[0]
const groupText = {
  'A': '铁路局',
  'B': '信号、牵引、制动系统集成商',
  'C': '机车车辆厂',
  'D': '其他客户',
  'E': '地方铁路公司',
  'F': '个人客户',
  'G': '国际贸易部',
  'L': '城铁公司',
  'X': '国内',
  'Y': '国外',
}

const CustomerList = (props) => {

  const [myList, setMyList] = useState([])
  const [myListLoading, setMyListLoading] = useState(false)
  const [myGroups, setMyGroups] = useState([])

  const [underlingList, setUnderlingList] = useState([])
  const [underlingListLoading, setUnderlingListLoading] = useState(false)
  const [underlingGroups, setUnderlingGroups] = useState([])

  useEffect(() => {
    document.title = HTML_TITLE[URL_CUSTOMER_LIST]

    // customerQueryUnderling()
    customerQuerySelf()

    const ps_reload_customer_list = PubSub.subscribe(PS_RELOAD_CUSTOMER_LIST, () => {
      customerQuerySelf()
      customerQueryUnderling()
    })
    return () => {
      PubSub.unsubscribe(ps_reload_customer_list)
    }
  }, [])

  const history = useHistory();


  const customerQuerySelf = () => {
    setMyListLoading(true)
    Query(Url.customerQuerySelf, null,
      (res) => {
        let list = res.data.list
        for (let i = 0; i < list.length; i++) {
          list[i]["enName"] = ChineseHelper.ConvertPinyin(list[i]["name"])
        }

        setMyList(list)
        setGroups(list, QueryType.MyType)
        setMyListLoading(false)
      },
      (res) => {
        setMyListLoading(false)
      },
      () => {
        setMyListLoading(false)
      }
    )
  }
  const customerQueryUnderling = () => {
    setUnderlingListLoading(true)

    Query(Url.customerQueryUnderling, null,
      (res) => {
        let list = res.data.list
        for (let i = 0; i < list.length; i++) {
          list[i]["enName"] = ChineseHelper.ConvertPinyin(list[i]["name"])
        }
        setUnderlingList(list)
        setGroups(list, QueryType.UnderlingType)
        setUnderlingListLoading(false)
      },
      (res) => {
        setMyListLoading(false)
      },
      () => {
        setMyListLoading(false)
      }
    )

  }
  const searchMyList = (value) => {
    const rmSpaceValue = value.replace(/\s*/g, "")
    const keyword = value.split('').filter((s) => s !== '' && s !== ' ');
    setGroups(myList.filter((item) => {
      let flag = true
      if (item.biz_id.lastIndexOf(rmSpaceValue) !== -1) {
        flag = true
      } else {
        keyword.forEach((k) => {
          if (!item.name.includes(k)) {
            flag = false
          }
        })
      }
      return flag
    }), QueryType.MyType)
  }
  const searchUnderlingList = (value) => {
    const rmSpaceValue = value.replace(/\s*/g, "")
    const keyword = value.split('').filter((s) => s !== '' && s !== ' ');
    setGroups(underlingList.filter((item) => {
      let flag = true
      if (item.biz_id.lastIndexOf(rmSpaceValue) !== -1) {
        flag = true
      } else {
        keyword.forEach((k) => {
          if (!item.name.includes(k)) {
            flag = false
          }
        })
      }
      return flag
    }), QueryType.UnderlingType)
  }
  const setGroups = (list, type) => {
    // list.sort((o1, o2) => o1.enName.charCodeAt(0) - o2.enName.charCodeAt(0))
    list.sort((o1, o2) => o1.biz_id.localeCompare(o2.biz_id, 'en'))

    let lastInitial = '$'
    let groups = []
    for (let i = 0; i < list.length; i++) {
      const initial = list[i].biz_id.charAt(0)
      if (initial !== lastInitial) {
        lastInitial = initial
        groups.push({
          title: initial + (groupText[initial] === undefined ? '' : (' - ' + groupText[initial])),
          items: []
        })
      }
      groups[groups.length - 1].items.push(list[i])
    }
    if (groups.length !== 0) {
      groups.push({
        title: '',
        items: []
      })
    }
    if (type === QueryType.MyType) {
      setMyGroups(groups)
    } else if (type === QueryType.UnderlingType) {
      setUnderlingGroups(groups)
    }
  }

  const ListDiv = (type) => {
    let isLoading = false
    let groups = []
    if (type === QueryType.MyType) {
      isLoading = myListLoading
      groups = myGroups
    } else if (type === QueryType.UnderlingType) {
      isLoading = underlingListLoading
      groups = underlingGroups
    }
    return (<div className={globalStyles.body}>
      <div style={{margin: '20px 50px 20px 50px', display: isLoading ? 'block' : 'none'}}>
        <Skeleton.Title animated/>
        <Skeleton.Paragraph lineCount={5} animated/>
      </div>
      <div style={{margin: '80px 50px 20px 50px', display: !isLoading && groups.length <= 1 ? 'block' : 'none'}}>
        <MyEmpty/>
      </div>
      <IndexBar style={{display: (isLoading || groups.length <= 0) ? 'none' : 'block'}}>
        {groups.map(group => {
          const {title, items} = group
          return (
            <IndexBar.Panel index={title} title={title} key={title}>
              <List>
                {
                  items.map(listItem => (
                    <List.Item key={listItem.id}
                               extra={
                                 listItem.visit_count === 0
                                   ? <Tag color='warning' fill='outline'
                                          style={{'--border-radius': '10px'}}>未拜访</Tag>
                                   : <div>拜访{listItem.visit_count}次</div>
                               }
                               description={
                                 <div>
                                   <span style={{marginRight: '20px'}}>
                                     {listItem.biz_id}
                                   </span>
                                   <span style={{marginRight: '20px'}}>
                                   {listItem.type === CustomerType.Company
                                     ? <Tag color='#AAAAAA' fill='outline'
                                            style={{'--border-radius': '10px'}}>企业客户</Tag>
                                     : <Tag color='default' fill='outline'
                                            style={{'--border-radius': '10px'}}>个人客户</Tag>
                                   }
                                  </span>
                                   <span>
                                   {
                                     listItem.level === "重要客户" ?
                                       <Tag color='warning' fill='outline'
                                            style={{'--border-radius': '10px'}}>重要客户</Tag> :
                                       listItem.level === "特别重要客户" ?
                                         <Tag color='danger' fill='outline'
                                              style={{'--border-radius': '10px'}}>特别重要客户</Tag> :
                                         null
                                   }
                                  </span>
                                 </div>
                               }
                               onClick={() => {
                                 history.push({
                                   pathname: URL_CUSTOMER_DETAIL,
                                   state: {customer: listItem}
                                 })
                               }}>
                      {listItem.name}
                    </List.Item>
                  ))}
              </List>
            </IndexBar.Panel>
          )
        })}
      </IndexBar>
    </div>)
  }

  return (
    <div className={globalStyles.vh_frame}>
      <div className={globalStyles.body}>
        <FloatingBubble
          style={{
            '--initial-position-bottom': '24px',
            '--initial-position-right': '24px',
            '--edge-distance': '24px',
            '--size': '128px',
            '--z-index': '1000',
          }}
          onClick={() => {
            history.push(URL_CUSTOMER_CREATE)
          }}
        >
          <div>
            <AddOutline fontSize={60 * GlobalZoom}/>
          </div>
        </FloatingBubble>

        <CapsuleTabs defaultActiveKey={tabKey} className="customer-tab-frame"
                     onChange={(key) => {
                       if (key === tabKeys[0]) {
                         customerQuerySelf()
                       } else if (key === tabKeys[1]) {
                         customerQueryUnderling()
                       }
                     }}>
          <CapsuleTabs.Tab title='查看我的客户' key={tabKeys[0]}>
            <div className={globalStyles.frame}>
              <div className={globalStyles.top} style={{padding: '20px 24px 0 24px'}}>
                <SearchBar placeholder='查找编号或企业名称（例:"A02","北京电务处"）' showCancelButton
                           onSearch={searchMyList}
                           onClear={() => {
                             setGroups(myList, QueryType.MyType)
                           }}
                           onCancel={() => {
                             setGroups(myList, QueryType.MyType)
                           }}
                           style={{'--height': '80px'}}
                />
                <div style={{margin: '20px'}}/>
              </div>
              {ListDiv(QueryType.MyType)}
            </div>
          </CapsuleTabs.Tab>

          <CapsuleTabs.Tab title='查看其他客户' key={tabKeys[1]}>
            <div className={globalStyles.frame}>
              <div className={globalStyles.top} style={{padding: '20px 24px 0 24px'}}>
                <SearchBar placeholder='查找编号或企业名称（例:"A02","北京电务处"）' showCancelButton
                           onSearch={searchUnderlingList}
                           onClear={() => {
                             setGroups(underlingList, QueryType.UnderlingType)
                           }}
                           onCancel={() => {
                             setGroups(underlingList, QueryType.UnderlingType)
                           }}
                           style={{'--height': '80px'}}
                />
                <div style={{margin: '20px'}}/>
              </div>
              {ListDiv(QueryType.UnderlingType)}
            </div>
          </CapsuleTabs.Tab>


        </CapsuleTabs>
      </div>

    </div>
  );
};


export default CustomerList;