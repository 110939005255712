import React, {useEffect, useState} from 'react';
import {Divider, JumboTabs, List, SearchBar, Skeleton, Tag} from "antd-mobile/2x";
import {HTML_TITLE, OrderStatus, OrderUserRole, URL_ORDER_DETAIL, URL_ORDER_LIST_CREATE_USER,} from "../../../constant";
import globalStyles from "../../../App.less";
import "./OrderListReceiveUser.css"
import {Query, Url} from "../../../api/query";
import {useHistory} from "react-router-dom";
import MyEmpty from "../../MyComponents/MyEmpty/MyEmpty";
import {SubString} from "../../../utils/StringUtil";
import {getSessionUserId} from "../../../storage/storage";

const tabKeys = ['0', '1', '2', '3', '4', '5']
let tabKey = tabKeys[1]

const OrderListReceiveUser = () => {
  useEffect(() => {
    document.title = HTML_TITLE[URL_ORDER_LIST_CREATE_USER]
    orderQuerySelf()
  }, [])

  const history = useHistory();

  const [originList, setOriginList] = useState([])
  const [originListLoading, setOriginListLoading] = useState(false)
  const [displayList, setDisplayList] = useState([])
  const [searchText, setSearchText] = useState("")

  const orderQuerySelf = () => {
    setOriginListLoading(true)
    Query(Url.orderQuerySelf, null,
      (res) => {
        console.log(res.data)
        const list = res.data.list
        setOriginList(list)
        reFilter(list)
        setOriginListLoading(false)
      },
      (res) => {
        setOriginListLoading(false)
      },
      () => {
        setOriginListLoading(false)
      }
    )
  }

  const reFilter = (sourceList, searchWord) => {
    let [...list] = sourceList === undefined ? originList : sourceList


    const myId = getSessionUserId()
    if (tabKey === tabKeys[1]) {
      list = list.filter((item) => item.status === OrderStatus.Unfinished)
    } else if (tabKey === tabKeys[2]) {
      list = list.filter((item) => item.status === OrderStatus.Finish && item.receive_user_id === myId)
    } else if (tabKey === tabKeys[3]) {
      list = list.filter((item) => item.status === OrderStatus.Close && item.receive_user_id === myId)
    } else if (tabKey === tabKeys[4]) {
      list = list.filter((item) => item.status === OrderStatus.Finish && item.receive_user_id !== myId)
    } else if (tabKey === tabKeys[5]) {
      list = list.filter((item) => item.status === OrderStatus.Close && item.receive_user_id !== myId)
    }

    const search = searchWord === undefined ? searchText : searchWord
    if (search !== "") {
      const keyword = search.split('').filter((s) => s !== '' && s !== ' ');
      list = list.filter((item) => {
        let flag = true
        keyword.forEach((k) => {
          if (!item.name.includes(k) && !item.customer_name.includes(k)) {
            flag = false
          }
        })
        return flag
      })
    }

    list.sort((o1, o2) => o2.id - o1.id)

    setDisplayList(list)
  }

  const ListDiv = () => {
    return (
      <div>
        <div style={{margin: '20px 50px 20px 50px', display: originListLoading ? 'block' : 'none'}}>
          <Skeleton.Title animated/>
          <Skeleton.Paragraph lineCount={5} animated/>
        </div>
        <div style={{
          margin: '80px 50px 20px 50px',
          display: !originListLoading && displayList.length <= 0 ? 'block' : 'none',
          height: '100%'
        }}>
          <MyEmpty/>
        </div>
        <List style={{display: !originListLoading && displayList.length > 0 ? 'block' : 'none'}}>
          {
            displayList.map((item, index) => {
              return (
                <List.Item key={index + 1}  title={<div>订单{item.id} &nbsp; {item.customer_name}</div>}
                           extra={
                             item.status === OrderStatus.Unfinished
                               ? <Tag color="danger" fill='outline' style={{'--border-radius': '10px'}}>待确认</Tag>
                               : (item.status === OrderStatus.Finish
                                   ? (
                                     <div>
                                     <span style={{
                                       marginRight: '10px',
                                       fontSize: '26px'
                                     }}>{item.receive_user_id === 0 ? null : item.receive_user_name}</span>
                                       <Tag color="success" fill='outline'
                                            style={{'--border-radius': '10px'}}>已确认</Tag>
                                     </div>)
                                   : (
                                     <Tag color="default" fill='outline' style={{'--border-radius': '10px'}}>已关闭</Tag>)
                               )
                           }
                           description={
                             <div>
                               <span>
                                 {item.create_user_name}
                               </span>
                               <span style={{marginLeft: '30px'}}>
                                 创建时间：{SubString(item.created_at, 0, 10)}
                               </span>
                             </div>
                           }
                           onClick={() => {
                             history.push({
                                 pathname: URL_ORDER_DETAIL,
                                 state: {
                                   order: item,
                                   role: OrderUserRole.Receive
                                 }
                               }
                             )
                           }}>
                  {item.name}
                </List.Item>
              )
            })
          }
          <Divider style={{margin: '0px', borderWidth: '1px'}}/>
        </List>
      </div>
    )
  }

  const tabInfo = [
    {
      key: tabKeys[0],
      title: '所有',
      description: <span style={{margin: '0 5px'}}>全部订单</span>
    },
    {
      key: tabKeys[1],
      title: '待确认',
      description: <span style={{margin: '0 5px'}}>尽快确认</span>
    }
    ,
    {
      key: tabKeys[2],
      title: '已确认',
      description: <span style={{margin: '0 5px'}}>由我确认</span>
    }
    ,
    {
      key: tabKeys[3],
      title: '已关闭',
      description:
        <span style={{margin: '0 5px'}}>由我确认</span>
    }
    ,
    {
      key: tabKeys[4],
      title: '已确认',
      description: <span style={{margin: '0 5px'}}>他人确认</span>
    }
    ,
    {
      key: tabKeys[5],
      title: '已关闭',
      description: <span style={{margin: '0 5px'}}>他人确认</span>
    }
  ]

  return (
    <div className={globalStyles.vh_frame}>
      <div className={globalStyles.body}>
        {/* 接收人不用这个按钮 */}
        {/*<FloatingBubble*/}
        {/*  style={{*/}
        {/*    '--initial-position-bottom': '24px',*/}
        {/*    '--initial-position-right': '24px',*/}
        {/*    '--edge-distance': '24px',*/}
        {/*    '--size': '128px',*/}
        {/*    '--z-index': '1000',*/}
        {/*  }}*/}
        {/*  onClick={() => {*/}
        {/*    history.push({*/}
        {/*      pathname: URL_ORDER_CREATE,*/}
        {/*      state: {*/}
        {/*        role: OrderUserRole.Receive*/}
        {/*      }*/}
        {/*    })*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div>*/}
        {/*    <AddOutline fontSize={60* GlobalZoom}/>*/}
        {/*  </div>*/}
        {/*</FloatingBubble>*/}


        <div className={globalStyles.frame}>
          <div className={globalStyles.top} style={{padding: '20px 24px 0 24px'}}>
            <div style={{margin: '0px 0 10px 0'}}>
              <SearchBar placeholder='查找订单信息' showCancelButton
                         onSearch={(searchText) => {
                           setSearchText(searchText)
                           reFilter(undefined, searchText)
                         }}
                         onClear={() => {
                           setSearchText("")
                           reFilter(undefined, "")
                         }}
                         onCancel={() => {
                           setSearchText("")
                           reFilter(undefined, "")
                         }}
                         style={{'--height': '80px'}}
              />
            </div>
          </div>

          <div className={globalStyles.body}>
            <JumboTabs
              className="customer-tab-frame"
              activeKey={tabKey}
              defaultActiveKey={tabKey} onChange={(value) => {
              tabKey = value
              reFilter()
            }}>
              {
                tabInfo.map((item, index) => {
                  return (
                    <JumboTabs.Tab title={item.title} description={item.description} key={item.key}>

                      {ListDiv()}
                    </JumboTabs.Tab>
                  )
                })
              }

            </JumboTabs>
          </div>
        </div>
      </div>

    </div>
  );
};


export default OrderListReceiveUser;