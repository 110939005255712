import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {HashRouter} from "react-router-dom";
import {AliveScope} from "react-activation";

// axios.defaults.withCredentials = true;

export let GlobalZoom = 1
export let IsPC = false
// export let GlobalZoomRatio = '100%'

const root = ReactDOM.createRoot(document.getElementById('root'));

if (/Mobile|Android|webOS|iPhone|iPod|BlackBerry|Phone/i.test(navigator.userAgent)) {//手机
//   GlobalZoom = 1
//   GlobalZoomRatio = '100%'
} else if (/iPad/i.test(navigator.userAgent)) {//iPad
//   GlobalZoom = 0.8
//   GlobalZoomRatio = '80%'
} else {//电脑
  IsPC = true
//   GlobalZoom = 0.5
//   GlobalZoomRatio = '50%'
}

root.render(
  <AliveScope>
    <HashRouter>
      <div>
        <App/>
      </div>
    </HashRouter>
  </AliveScope>
)
;
