
const dayList=['日','一','二','三','四','五','六']

export const getDateTime=()=>{
  const date = new Date();
  let str=""
  str+=date.getFullYear()+"年"
  str+=(date.getMonth()+1)+"月"
  str+=date.getDate()+"日"
  str+=" 星期"+dayList[date.getDay()]
  return str
}
