import React, {useEffect} from 'react';
import {
  CustomerType,
  HTML_TITLE,
  PS_RELOAD_CUSTOMER_LIST,
  SESSION_LAST_CUSTOMER_INFO,
  URL_CUSTOMER_DETAIL,
  URL_CUSTOMER_UPDATE,
  URL_LINKMAN_CREATE,
  URL_LINKMAN_DETAIL
} from "../../../constant";
import styles from "./CustomerDetail.less"
import globalStyles from "../../../App.less";
import {
  ActionSheet,
  Button,
  CapsuleTabs,
  Card,
  DatePicker,
  Dialog,
  Divider,
  FloatingBubble,
  Form,
  Grid,
  List,
  Popup,
  Selector,
  Skeleton,
  SwipeAction,
  Tag,
  TextArea,
  Toast
} from "antd-mobile/2x";
import "./CustomerDetail.css"
import {NempStr, SubString} from "../../../utils/StringUtil";
import {useHistory} from "react-router-dom";
import {Query, Url} from "../../../api/query";
import {getSessionUserId} from "../../../storage/storage";
import {QuestionOutlined} from "@ant-design/icons";
import ChineseHelper from "../../../utils/Pinyin/ChineseHelper";
import MyEmpty from "../../MyComponents/MyEmpty/MyEmpty";
import {MapApi, MapNotice} from "../../../api/map";
import {GlobalZoom} from "../../../index";
import dayjs from "dayjs";
import PubSub from "pubsub-js";

const tabKeys = ['0', '1', '2', '3']
let tabKey = tabKeys[0]

const CustomerDetail = (props) => {
    useEffect(() => {
      document.title = HTML_TITLE[URL_CUSTOMER_DETAIL]
      sessionStorage.setItem(SESSION_LAST_CUSTOMER_INFO, JSON.stringify(customer))
      getLinkman()
      getCustomerRU()
      getCustomerVisit()
      if (tabKey !== tabKeys[0]) {
        tabChange(tabKey)
      }
    }, [])

    const history = useHistory()

    const getLastCustomerInfo = () => {
      const lastCustomerInfoStr = sessionStorage.getItem(SESSION_LAST_CUSTOMER_INFO);
      if (lastCustomerInfoStr == null) {
        return {
          id: -1,
          user_id: -1,
          name: "参数错误",
          type: 0,
          level: "普通客户",
          content: "",
          created_at: "-",
          updated_at: "-",
        };
      }
      return JSON.parse(lastCustomerInfoStr)
    }

    const customer = props.location.state === undefined ? getLastCustomerInfo() : props.location.state.customer
    const content = JSON.parse(customer.content)

    const [detailActionSheetVisible, setDetailActionSheetVisible] = React.useState(false);
    const [linkmanList, setLinkmanList] = React.useState([]);
    const [linkmanListLoading, setLinkmanListLoading] = React.useState(false);
    const [canUpdate, setCanUpdate] = React.useState(false);
    const [canDelete, setCanDelete] = React.useState(false);
    const [manager, setManager] = React.useState({});
    const [relevants, setRelevants] = React.useState([]);
    const [userPopupVisible, setUserPopupVisible] = React.useState(false);
    const [userListLoading, setUserListLoading] = React.useState(false);
    const [userList, setUserList] = React.useState([]);
    const [customerVisits, setCustomerVisits] = React.useState([]);
    const [visitPopupVisible, setVisitPopupVisible] = React.useState(false);
    const [hasSubmitVisit, setHasSubmitVisit] = React.useState(false);
    const [customerVisitCount, setCustomerVisitCount] = React.useState(customer.visit_count);


    const getLinkman = () => {
      setLinkmanListLoading(true)
      Query(Url.linkmanQueryByCustomerId, {customer_id: customer.id}, (res) => {
        setLinkmanList(res.data.list)
        setLinkmanListLoading(false)
      })
    }
    const getCustomerRU = () => {
      Query(Url.customerRUQueryByCustomerId, {customerId: customer.id}, (res) => {
        setManager(res.data.manager)
        setRelevants(res.data.relevants)
        if (res.data.manager.id === getSessionUserId()) {
          setCanUpdate(true)
          setCanDelete(true)
          return
        }
        for (let i = 0; i < res.data.relevants.length; i++) {
          if (res.data.relevants[i].id === getSessionUserId()) {
            setCanUpdate(true)
            break
          }
        }
      })
    }

    const getCustomerVisit = () => {
      Query(Url.customerVisitQueryByCustomerId, {customerId: customer.id}, (res) => {
        //根据visit_at从大到校排序
        console.log(res.data.list)
        if (res.data.list !== null && res.data.list !== undefined) {
          res.data.list.sort((a, b) => {
            return -(new Date(a.visit_at) - new Date(b.visit_at))
          })
        }
        console.log(res.data.list)
        setCustomerVisits(res.data.list)
      })
    }

    const detailTab = () => {
      const submitDeleteCustomer = () => {
        Dialog.confirm({
          content: <div style={{
            fontSize: 'var(--adm-font-size-8)', textAlign: "center", margin: '20px 0'
          }}>确认删除该客户？</div>, onConfirm: async () => {
            const data = {id: customer.id}
            Query(Url.customerDelete, data, (res) => {
              Toast.show({
                icon: 'success', content: '提交成功', duration: 1000,
              })
              PubSub.publish(PS_RELOAD_CUSTOMER_LIST, {})
              setTimeout(() => {
                history.go(-1)
              }, 1500)
            })
          },
        })

      }
      return (<div>
          <List
            mode={"card"}
            style={{
              '--prefix-width': '200px', '--font-size': '30px',
            }}>
            <List.Item prefix='客户编号'>{NempStr(content.biz_id)}</List.Item>
            <List.Item prefix='客户归属'>{NempStr(content.customer_source)}</List.Item>
            <div style={{display: customer.type === CustomerType.Company ? 'block' : 'none'}}>
              <List.Item prefix='企业详细地址'>
                <div style={{color: 'var(--adm-color-primary)'}} onClick={() => {
                  Dialog.confirm({
                    content: (<div style={{margin: '0 10px'}}>
                      <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>提示</div>
                      {MapNotice()}
                    </div>), onConfirm: () => {
                      window.open(`${MapApi}${content.customer_location}${customer.name}`)
                    },
                  })
                }}>
                  {NempStr(content.customer_location)}
                </div>
                {/*<a href={`${MapApi}${content.customer_location}`} style={{textDecoration: 'none'}}>*/}
                {/*  {NempStr(content.customer_location)}*/}
                {/*</a>*/}
              </List.Item>
              <List.Item prefix='企业规模'>{NempStr(content.customer_size)}</List.Item>
            </div>
            <List.Item prefix='主营产品'>{NempStr(content.customer_product)}</List.Item>
            <List.Item prefix='备注'>{NempStr(content.customer_note)}</List.Item>
          </List>
          <div style={{padding: '50px 0'}}/>
          <div style={{textAlign: 'center', display: canUpdate ? 'block' : 'none'}}>
            <Button color='primary' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                    onClick={() => {
                      setDetailActionSheetVisible(true)
                    }}>
              <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>申请变更</div>
            </Button>
          </div>
          <ActionSheet
            visible={detailActionSheetVisible}
            actions={[{
              text: '修改', key: 'update', description: '提交后交由直属上级审批', onClick: () => {
                history.push({pathname: URL_CUSTOMER_UPDATE, state: {customer: customer}})
              }
            }, {
              text: '删除', key: 'delete', description: '提交后交由直属上级审批', disabled: !canDelete, onClick: () => {
                submitDeleteCustomer()
              }
            }]}
            onClose={() => setDetailActionSheetVisible(false)}
          />
        </div>

      )
    }

    const linkmanTab = () => {
      return (<div>
        <List mode="card"
              style={{
                '--prefix-width': '200px', '--font-size': '30px', display: linkmanListLoading ? 'none' : 'block'
              }}>
          {linkmanList.map((item, index) => {
            return (<List.Item style={{marginRight: '20px'}}
                               key={index}
                               onClick={() => {
                                 history.push({pathname: URL_LINKMAN_DETAIL, state: {linkman: item, customer: customer}})
                               }}>
              <div style={{fontSize: 'var(--adm-font-size-7)', marginLeft: '30px'}}>{item.name}</div>
            </List.Item>)
          })}
          <List.Item style={{display: linkmanList.length === 0 ? 'block' : 'none'}}>
            <div style={{margin: '30px 0 50px 0'}}>
              <MyEmpty description='还没有添加联系人'/></div>
          </List.Item>
        </List>
        <List mode="card" style={{display: linkmanListLoading ? 'block' : 'none'}}>
          <List.Item>
            <div style={{margin: '20px 50px 20px 50px'}}>
              <Skeleton.Title animated/>
              <Skeleton.Paragraph lineCount={5} animated/>
            </div>
          </List.Item>
        </List>

        <div style={{padding: '50px 0'}}/>
        <div style={{textAlign: "center", display: canUpdate ? 'block' : 'none'}}>
          <Button color='primary' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                  onClick={() => {
                    history.push({
                      pathname: URL_LINKMAN_CREATE, state: {customer: customer}
                    })
                  }}>
            <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>新增联系人</div>
          </Button>
        </div>
      </div>)
    }

    const relevantTab = () => {
      const submitDeleteRelevant = (userId) => {
        Dialog.confirm({
          content: <div style={{
            fontSize: 'var(--adm-font-size-8)', textAlign: "center", margin: '20px 0'
          }}>确认移除该协作同事？</div>, onConfirm: async () => {
            Query(Url.customerRUDelete, {customer_id: customer.id, user_id: userId}, (res) => {
              Toast.show({
                icon: 'success', content: '移除成功', duration: 1000,
              })
              setRelevants(relevants.filter((item) => item.id !== userId))
            })
          },
        })
      }

      const submitCreateRelevantUser = (userId, userName, username) => {
        Dialog.confirm({
          content: <div style={{
            fontSize: 'var(--adm-font-size-8)', textAlign: "center", margin: '20px 0'
          }}>确认将 <b>{userName}</b><br/> 设为该客户的协作同事？</div>, onConfirm: async () => {
            Query(Url.customerRUCreate, {customer_id: customer.id, user_id: userId}, (res) => {
              Toast.show({
                icon: 'success', content: '设置成功', duration: 1000,
              })
              setRelevants([...relevants, {id: userId, name: userName, username: username}])
            })
          },
        })
        setUserPopupVisible(false)
      }

      const chooseUser = () => {
        setUserPopupVisible(true)

        setUserListLoading(true)
        Query(Url.userQuery, null, (res) => {
          let list = res.data.list
          list = list.filter((item) => {
            let flag = true
            if (item.id === manager.id) {
              return false
            }
            for (let i = 0; i < relevants.length; i++) {
              if (item.id === relevants[i].id) {
                flag = false
                break
              }
            }
            return flag
          })

          for (let i = 0; i < list.length; i++) {
            list[i]["enName"] = ChineseHelper.ConvertPinyin(list[i]["name"])
          }
          list.sort((o1, o2) => o1.enName.localeCompare(o2.enName, 'en'))

          setUserList(list)
          setUserListLoading(false)
        }, (res) => {
          setUserListLoading(false)
        }, () => {
          setUserListLoading(false)
        })
      }

      return (<div>
        <List mode={"card"}
              style={{
                '--prefix-width': '200px', '--font-size': '30px',
              }}>
          <List.Item style={{paddingLeft: '50px'}}>
            {manager.name}
            <Tag color='primary' fill='outline' style={{marginLeft: '20px', '--border-radius': '6px'}}>
              客户创建人
            </Tag>
          </List.Item>

          {relevants.map((relevant, index) => {
            return (<SwipeAction key={index} rightActions={canDelete ? [{
              key: 'delete', text: '删除', color: 'danger', onClick: () => {
                submitDeleteRelevant(relevant.id)
              }
            }] : []}>
              <List.Item style={{paddingRight: '30px'}}>
                <div style={{fontSize: 'var(--adm-font-size-7)', marginLeft: '30px'}}>{relevant.name}</div>
              </List.Item>
            </SwipeAction>)
          })}
        </List>

        <div style={{padding: '50px 0'}}/>
        <div style={{textAlign: "center", display: canUpdate ? 'block' : 'none'}}>
          <Button color='primary' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                  onClick={() => {
                    chooseUser()
                  }}>
            <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>新增协作同事</div>
          </Button>
        </div>

        <Popup
          visible={userPopupVisible}
          onMaskClick={() => {
            setUserPopupVisible(false)
          }}
          position='right'
          bodyStyle={{width: 'var(--80vw)'}}
        >
          <div className={globalStyles.vh_frame}>
            <div style={{
              fontSize: 'var(--adm-font-size-8)', padding: '20px', color: 'var(--adm-color-text)', textAlign: "center"
            }}
                 className={globalStyles.top_b}>选择用户
            </div>
            <List className={globalStyles.body}
                  style={{display: userListLoading ? 'none' : 'block', overflowY: 'scroll'}}>
              {userList.map((item, index) => {
                return (<List.Item key={index} onClick={() => {
                  submitCreateRelevantUser(item.id, item.name, item.username)
                }}>
                  {item.name}
                </List.Item>)
              })}
            </List>
          </div>
          <div style={{margin: '20px 50px 20px 50px', display: userListLoading ? 'block' : 'none'}}>
            <Skeleton.Title animated/>
            <Skeleton.Paragraph lineCount={5} animated/>
          </div>

        </Popup>

        <FloatingBubble
          style={{
            '--initial-position-bottom': '38px',
            '--initial-position-right': '38px',
            '--edge-distance': '38px',
            display: canUpdate ? 'block' : 'none'
          }}
          onClick={() => {
            Dialog.alert({
              content: (<div style={{margin: '0 10px'}}>
                <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>关于协作同事</div>
                <div>1. 协作同事可与客户创建人一起管理该客户及所属联系人</div>
                <div>2. 协作同事拥有除“删除客户、新增和删除其他协作同事”外的所有权限</div>
                <div>3. 向左滑动协作同事，可移除该协作同事</div>
              </div>),
            })
          }}
        >
          <QuestionOutlined fontSize={32 * GlobalZoom}/>
        </FloatingBubble>
      </div>)
    }

    const visitTab = () => {
      const submitDeleteVisit = (id) => {
        Dialog.confirm({
          content: <div style={{
            fontSize: 'var(--adm-font-size-8)', textAlign: "center", margin: '20px 0'
          }}>确认删除该拜访记录？</div>, onConfirm: async () => {
            Query(Url.customerVisitDelete, {id}, (res) => {
              Toast.show({
                icon: 'success', content: '删除成功', duration: 1000,
              })
              setCustomerVisits(customerVisits.filter((item) => item.id !== id))
              setCustomerVisitCount(customerVisitCount - 1)
              PubSub.publish(PS_RELOAD_CUSTOMER_LIST, {
                customer_id: customer.id, visit_count: customerVisitCount - 1,
              })
            })
          },
        })
      }

      const submitCreateVisit = (obj) => {
        setHasSubmitVisit(true)
        const value = JSON.parse(JSON.stringify(obj))
        value.visit_at = dayjs(value.visit_at).format('YYYY-MM-DD')
        value.customer_id = customer.id
        console.log(value)

        Query(Url.customerVisitCreate, value, (res) => {
          Toast.show({
            icon: 'success', content: '添加成功', duration: 1000,
          })
          if (customerVisits !== null) {
            setCustomerVisits([res.data, ...customerVisits])
          } else {
            setCustomerVisits([res.data])
          }
          setHasSubmitVisit(false)
          setVisitPopupVisible(false)
          setCustomerVisitCount(customerVisitCount + 1)
          PubSub.publish(PS_RELOAD_CUSTOMER_LIST, {
            customer_id: customer.id, visit_count: customerVisitCount + 1,
          })
        }, () => {
          setHasSubmitVisit(false)
          setVisitPopupVisible(false)
        }, () => {
          setHasSubmitVisit(false)
          setVisitPopupVisible(false)
        })
      }


      return (<div>
        <div style={{textAlign: "center", display: canUpdate ? 'block' : 'none'}}>
          <div style={{margin: '10px 25px 30px 25px'}}>
            <Button block color='primary' fill='outline' style={{'--border-radius': '20px'}}
                    onClick={() => {
                      setVisitPopupVisible(true)
                    }}>
              <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>新增拜访记录</div>
            </Button>
          </div>

          <Popup
            visible={visitPopupVisible}
            onMaskClick={() => {
              setVisitPopupVisible(false)
            }}
            bodyStyle={{height: '1100px', maxHeight: 'var(--80vh)', backgroundColor: '#F0F0F0'}}
          >
            <div className={globalStyles.frame}>
              <div className={globalStyles.body}>
                <Form mode="card"
                      style={{margin: '50px 20px'}}
                      onFinish={submitCreateVisit}
                      onFinishFailed={() => {
                        Toast.show({
                          icon: 'fail', content: '请检查表单是否填写完整',
                        })
                      }}
                      footer={<div>
                        <Grid columns={2} gap={100} style={{textAlign: 'center'}}>
                          <Grid.Item>
                            <Button block color='default' size='large'
                                    style={{marginBottom: '0px', '--border-radius': '30px'}}
                                    onClick={() => {
                                      setVisitPopupVisible(false)
                                    }}>取消</Button>
                          </Grid.Item>
                          <Grid.Item>
                            <Button block type='submit' color='primary' size='large'
                                    disabled={hasSubmitVisit}
                                    style={{marginBottom: '0px', '--border-radius': '30px'}}>提交</Button>
                          </Grid.Item>
                        </Grid>
                      </div>}>
                  <Form.Item label='拜访日期' name='visit_at' trigger='onConfirm' rules={[{required: true}]}
                             onClick={(e, datePickerRef) => {
                               let _a;
                               (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.open();
                             }}>
                    <DatePicker min={new Date(1900, 0, 1)}
                    >
                      {value => value ? dayjs(value).format('YYYY-MM-DD') : <div style={{color: '#cccccc'}}>请选择</div>}
                    </DatePicker>
                  </Form.Item>
                  <Form.Item label='拜访对象' name='visit_linkman'>
                    <Selector
                      style={{display: linkmanList === undefined || linkmanList === null || linkmanList.length === 0 ? 'none' : 'block'}}
                      multiple={true}
                      options={linkmanList.map((item) => {
                        return {label: item.name, value: item.name}
                      })}
                    />
                  </Form.Item>
                  <div style={{
                    display: (linkmanList === undefined || linkmanList === null || linkmanList.length === 0) ? 'block' : 'none',
                    color: 'var(--adm-color-text-secondary)',
                    margin: '0px 0px 30px 30px '
                  }}>
                    还没有添加客户联系人
                  </div>
                  <Form.Item label='备注' name='note'>
                    <TextArea placeholder='请输入' rows={3}/>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Popup>

        </div>

        <List mode='card'
              style={{
                '--prefix-width': '200px', '--font-size': '30px',
              }}>

          {customerVisits === null || customerVisits === undefined || customerVisits.length === 0 ? undefined : customerVisits.map((visit, index) => {
            return (<SwipeAction key={index} rightActions={[{
              key: 'delete', text: '删除', color: 'danger', onClick: () => {
                submitDeleteVisit(visit.id)
              }
            }]}>
              <List.Item style={{paddingRight: '30px'}}
                         title={<div
                           style={{marginLeft: '20px'}}>第{customerVisits.length - index}次拜访：{visit.visit_at}</div>}>
                <div style={{
                  fontSize: 'var(--adm-font-size-7)', marginLeft: '30px', marginTop: '15px'
                }}>
                  <div>
                    拜访对象：{visit.visit_linkman === undefined || visit.visit_linkman === null || visit.visit_linkman === "" || visit.visit_linkman === "[]"
                    ? '无'
                    : JSON.parse(visit.visit_linkman).join('、')}
                  </div>
                  <div
                    style={{display: visit.note === undefined || visit.note === null || visit.note === "" ? 'none' : 'block'}}>
                    备注：{visit.note}
                  </div>
                </div>
              </List.Item>
            </SwipeAction>)
          })
          }
        </List>

        <div style={{padding: '50px 0'}}/>


        <FloatingBubble
          style={{
            '--initial-position-bottom': '38px',
            '--initial-position-right': '38px',
            '--edge-distance': '38px',
            display: canUpdate ? 'block' : 'none'
          }}
          onClick={() => {
            Dialog.alert({
              content: (<div style={{margin: '0 10px'}}>
                <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>关于拜访记录</div>
                <div>1. 拜访记录用于统计该对客户的拜访次数、日期与备注</div>
                <div>2. 向左滑动记录，可删除该记录</div>
              </div>),
            })
          }}
        >
          <QuestionOutlined fontSize={32 * GlobalZoom}/>
        </FloatingBubble>
      </div>)
    }

    const tabChange = (number) => {
      // tabKey = number
      // if (number === tabKeys[1]) {
      //
      // }
    }

    return (<div>
      <div className={styles.background_pic}/>
      <div className={globalStyles.background_grey}/>

      <div className={globalStyles.vh_frame}>
        <div style={{margin: '40px 50px 30px 50px'}} className={globalStyles.top}>
          <Card style={{boxShadow: '0 5px 10px 0 rgb(0 0 0 / 10%)'}}>
            <div style={{fontSize: '40px', fontWeight: 'bold'}}>
              {customer.name}

              <span style={{float: 'right'}}>
                  {customerVisitCount === 0 ? <Tag color="warning" fill='outline'
                                                   style={{
                                                     '--border-radius': '10px',
                                                     fontSize: '24px',
                                                     transform: 'translateY(-4px)'
                                                   }}>未拜访</Tag> : <Tag color="default" fill='outline'
                                                                          style={{
                                                                            '--border-radius': '10px',
                                                                            fontSize: '24px',
                                                                            transform: 'translateY(-4px)'
                                                                          }}>拜访{customerVisitCount}次</Tag>}
                    </span>
            </div>
            <Divider/>
            <div style={{color: 'var(--adm-color-text-secondary)'}}>


              <Grid columns={2}>
                <Grid.Item>
                  客户类型：<span>{customer.type === CustomerType.Company ?
                  <Tag color='primary' fill='outline' style={{'--border-radius': '10px'}}>企业客户</Tag> :
                  <Tag color='success' fill='outline' style={{'--border-radius': '10px'}}>个人客户</Tag>}
                    </span>
                </Grid.Item>
                <Grid.Item>
                  客户等级：
                  <span>
                      {customer.level === "重要客户" ? <Tag color='warning' fill='outline'
                                                            style={{'--border-radius': '10px'}}>重要客户</Tag> : customer.level === "特别重要客户" ?
                        <Tag color='danger' fill='outline' style={{'--border-radius': '10px'}}>特别重要客户</Tag> :
                        <Tag color='default' fill='outline' style={{'--border-radius': '10px'}}>普通客户</Tag>}
                    </span>
                </Grid.Item>
              </Grid>
            </div>
            <div style={{marginTop: '20px', color: 'var(--adm-color-text-secondary)'}}>
              <Grid columns={2}>
                <Grid.Item>
                  创建时间：{SubString(customer.created_at, 0, 10)}
                </Grid.Item>
                <Grid.Item>
                  更新时间：{SubString(customer.updated_at, 0, 10)}
                </Grid.Item>
              </Grid>
            </div>
          </Card>
        </div>
        <div className={globalStyles.body} style={{padding: '0 20px'}}>
          <CapsuleTabs defaultActiveKey={tabKey} className={globalStyles.frame} onChange={tabChange}
                       style={{'--adm-color-box': '#FFFFFF'}}>
            <CapsuleTabs.Tab title='客户详情' key={tabKeys[0]}>
              {detailTab()}
            </CapsuleTabs.Tab>
            <CapsuleTabs.Tab title='联系人' key={tabKeys[1]}>
              {linkmanTab()}
            </CapsuleTabs.Tab>
            <CapsuleTabs.Tab title='协作同事' key={tabKeys[2]}>
              {relevantTab()}
            </CapsuleTabs.Tab>
            <CapsuleTabs.Tab title='拜访记录' key={tabKeys[3]}>
              {visitTab()}
            </CapsuleTabs.Tab>
          </CapsuleTabs>
        </div>
      </div>
    </div>);
  }
;

export default CustomerDetail;
