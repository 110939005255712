import React, {useEffect} from 'react';
import { Url} from "../../api/query";


const Login = () => {
  useEffect(() => {
    window.location.replace(Url.userLogin)
  }, [])

  return (
    <div>
    </div>
  );
};

export default Login;