import React, {useEffect} from 'react';
import {
  HTML_TITLE, PS_RELOAD_CHANCE_LIST, PS_RELOAD_CLUE_LIST,
  SESSION_LAST_CLUE_INFO,
  URL_CLUE_CONVERT_TO_LINKMAN,
  URL_CLUE_DETAIL,
  URL_CLUE_UPDATE,
  URL_CUSTOMER_CREATE,
} from "../../../constant";
import styles from "./ClueDetail.less";
import globalStyles from "../../../App.less";
import {
  ActionSheet,
  Button,
  CapsuleTabs,
  Card,
  Dialog,
  Divider,
  FloatingBubble,
  Grid,
  List,
  Popup,
  Skeleton,
  SwipeAction,
  Tag,
  Toast
} from "antd-mobile/2x";
import {Query, Url} from "../../../api/query";
import {NempStr, SubString} from "../../../utils/StringUtil";
import {useHistory} from "react-router-dom";
import {getSessionUserId} from "../../../storage/storage";
import ChineseHelper from "../../../utils/Pinyin/ChineseHelper";
import {QuestionOutlined} from "@ant-design/icons";
import {MapApi, MapNotice} from "../../../api/map";
import {GlobalZoom} from "../../../index";
import PubSub from "pubsub-js";


const tabKeys = ['0', '1', '2', '3']
let tabKey = tabKeys[0]

const ClueDetail = (props) => {
  const history = useHistory();
  useEffect(() => {
    document.title = HTML_TITLE[URL_CLUE_DETAIL]
    sessionStorage.setItem(SESSION_LAST_CLUE_INFO, JSON.stringify(clue))
    getCustomerRU()
  }, [])

  const getLastClueInfo = () => {
    const lastLinkmanInfoStr = sessionStorage.getItem(SESSION_LAST_CLUE_INFO);
    if (lastLinkmanInfoStr == null) {
      return {
        id: -1,
        user_id: -1,
        name: "参数错误",
        content: "-",
        created_at: "-",
        updated_at: "-",
      };
    }
    return JSON.parse(lastLinkmanInfoStr)
  }

  const getCustomerRU = () => {
    Query(Url.clueRUQueryByClueId, {clue_id: clue.id},
      (res) => {
        setManager(res.data.manager)
        setRelevants(res.data.relevants)
        if (res.data.manager.id === getSessionUserId()) {
          setCanUpdate(true)
          setCanDelete(true)
          return
        }
        for (let i = 0; i < res.data.relevants.length; i++) {
          if (res.data.relevants[i].id === getSessionUserId()) {
            setCanUpdate(true)
            break
          }
        }
      })
  }

  const clue = props.location.state === undefined ? getLastClueInfo() : props.location.state.clue
  const content = JSON.parse(clue.content)

  const [canUpdate, setCanUpdate] = React.useState(false);
  const [canDelete, setCanDelete] = React.useState(false);
  const [manager, setManager] = React.useState({});
  const [relevants, setRelevants] = React.useState([]);
  const [detailActionSheetVisible, setDetailActionSheetVisible] = React.useState(false);
  const [userPopupVisible, setUserPopupVisible] = React.useState(false);
  const [userListLoading, setUserListLoading] = React.useState(false);
  const [userList, setUserList] = React.useState([]);


  const detailTab = () => {

    const submitDeleteClue = () => {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认删除该线索？</div>,
        onConfirm: async () => {
          const data = {id: clue.id}
          Query(Url.clueDelete, data, (res) => {
            Toast.show({
              icon: 'success',
              content: '删除成功',
              duration: 1000,
            })
          })
          PubSub.publish(PS_RELOAD_CLUE_LIST, {})
          setTimeout(() => {
            history.go(-1)
          }, 1500)
        },
      })

    }

    return (<div>
      <List mode='card' header="个人信息"
            style={{
              '--prefix-width': '200px',
              '--font-size': '30px',
            }}>
        <List.Item prefix='联系人姓名'>{NempStr(content.linkman_name)}</List.Item>
        <List.Item prefix='性别'>{NempStr(content.linkman_sex)}</List.Item>
        <List.Item prefix='出生日期'>{NempStr(content.linkman_birthday)}</List.Item>
        <List.Item prefix='爱好'>{NempStr(content.linkman_hobby)}</List.Item>
        <List.Item prefix='家庭住址'>
          <div style={{color: 'var(--adm-color-primary)'}} onClick={() => {
            Dialog.confirm({
              content: (<div style={{margin: '0 10px'}}>
                <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>提示</div>
                {MapNotice()}
              </div>),
              onConfirm: () => {
                window.open(`${MapApi}${content.linkman_home_location}`)
              },
            })
          }
          }>
            {NempStr(content.linkman_home_location)}
          </div>
          {/*<a href={`${MapApi}${content.linkman_home_location}`} style={{textDecoration:'none'}} >*/}
          {/*  {NempStr(content.linkman_home_location)}*/}
          {/*</a>*/}
        </List.Item>
        <List.Item prefix='线索来源'>{NempStr(content.linkman_source)}</List.Item>

      </List>

      <List mode='card' header="职务信息"
            style={{
              '--prefix-width': '200px',
              '--font-size': '30px',
            }}>
        <List.Item prefix='部门'>{NempStr(content.linkman_depart)}</List.Item>
        <List.Item prefix='职务'>{NempStr(content.linkman_post)}</List.Item>
        <List.Item prefix='办公地址'>{NempStr(content.linkman_work_location)}</List.Item>
      </List>

      <List mode='card' header="备注框" style={{
        '--prefix-width': '200px',
        '--font-size': '30px',
      }}>
        <List.Item>{NempStr(content.linkman_note)}</List.Item>
      </List>

      <div>
        {
          content.relative !== undefined && content.relative !== null ?
            content.relative.map((item, index) => {
              return (
                <div key={index + 1}>
                  <List mode='card' header={'相关人' + (index + 1)}
                        style={{
                          '--prefix-width': '200px',
                          '--font-size': '30px',
                        }}>
                    <List.Item prefix='姓名'>{NempStr(item.name)}</List.Item>
                    <List.Item prefix='关系'>{NempStr(item.relation)}</List.Item>
                    <List.Item prefix='出生年份'>{NempStr(item.birthday)}</List.Item>
                    <List.Item prefix='爱好'>{NempStr(item.hobby)}</List.Item>
                    <List.Item prefix='备注'>{NempStr(item.note)}</List.Item>
                  </List>
                </div>
              )
            }) :
            null
        }
      </div>

      <div style={{padding: '50px 0'}}/>
      <div style={{textAlign: 'center', display: canUpdate ? 'block' : 'none'}}>
        <Button color='primary' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                onClick={() => {
                  setDetailActionSheetVisible(true)
                }}>
          <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>变更线索</div>
        </Button>
      </div>
      <ActionSheet
        visible={detailActionSheetVisible}
        actions={[
          {
            text: '修改', key: 'update',
            onClick: () => {
              history.push({pathname: URL_CLUE_UPDATE, state: {clue}})
            }
          },
          {
            text: '删除', key: 'delete', disabled: !canDelete,
            onClick: () => {
              submitDeleteClue()
            }
          },
          {
            text: '转为客户联系人', key: 'convert', danger: true,
            onClick: () => {
              Dialog.show({
                content: <div style={{
                  fontSize: 'var(--adm-font-size-8)',
                  textAlign: "center",
                  margin: '20px 0'
                }}>将线索转换为客户联系人前，<br/>请先确定已经创建对应客户</div>,
                closeOnAction: true,
                actions: [
                  {

                    key: 'convert_to_linkman',
                    text: '确认转换线索',
                    bold: true,
                    onClick: () => {
                      history.push({pathname: URL_CLUE_CONVERT_TO_LINKMAN, state: {clue}})
                    }
                  },
                  [
                    {
                      key: 'cancel',
                      text: '取消',
                    },
                    {
                      key: 'create_customer',
                      text: '先创建客户',
                      onClick: () => {
                        history.push({pathname: URL_CUSTOMER_CREATE})
                      }
                    },
                  ],
                ],
              })

            }
          },]}
        onClose={() => setDetailActionSheetVisible(false)}
      />
    </div>)
  }

  const relevantTab = () => {
    const submitDeleteRelevant = (userId) => {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认移除该协作同事？</div>,
        onConfirm: async () => {
          Query(Url.clueRUDelete, {clueId: clue.id, userId: userId}, (res) => {
            Toast.show({
              icon: 'success',
              content: '移除成功',
              duration: 1000,
            })
            setRelevants(relevants.filter((item) => item.id !== userId))
          })
        },
      })
    }

    const submitCreateRelevantUser = (userId, userName, username) => {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认将 <b>{userName}</b><br/> 设为该线索的协作同事？</div>,
        onConfirm: async () => {
          Query(Url.clueRUCreate, {clue_id: clue.id, user_id: userId}, (res) => {
            Toast.show({
              icon: 'success',
              content: '设置成功',
              duration: 1000,
            })
            setRelevants([...relevants, {id: userId, name: userName, username: username}])
          })
        },
      })
      setUserPopupVisible(false)
    }

    const chooseUser = () => {
      setUserPopupVisible(true)

      setUserListLoading(true)
      Query(Url.userQuery, null,
        (res) => {
          let list = res.data.list
          list = list.filter((item) => {
            let flag = true
            if (item.id === manager.id) {
              return false
            }
            for (let i = 0; i < relevants.length; i++) {
              if (item.id === relevants[i].id) {
                flag = false
                break
              }
            }
            return flag
          })

          for (let i = 0; i < list.length; i++) {
            list[i]["enName"] = ChineseHelper.ConvertPinyin(list[i]["name"])
          }
          list.sort((o1, o2) => o1.enName.localeCompare(o2.enName, 'en'))

          setUserList(list)
          setUserListLoading(false)
        },
        (res) => {
          setUserListLoading(false)
        },
        () => {
          setUserListLoading(false)
        }
      )
    }

    return (<div>
      <List mode={"card"}
            style={{
              '--prefix-width': '200px',
              '--font-size': '30px',
            }}>
        <List.Item style={{paddingLeft: '50px'}}>
          {manager.name}
          <Tag color='primary' fill='outline' style={{marginLeft: '20px', '--border-radius': '6px'}}>
            客户创建人
          </Tag>
        </List.Item>

        {relevants.map((relevant, index) => {
          return (
            <SwipeAction key={index} rightActions={
              canDelete ? [{
                key: 'delete',
                text: '删除',
                color: 'danger',
                onClick: () => {
                  submitDeleteRelevant(relevant.id)
                }
              }] : []}>
              <List.Item style={{paddingRight: '30px'}}>
                <div style={{fontSize: 'var(--adm-font-size-7)', marginLeft: '30px'}}>{relevant.name}</div>
              </List.Item>
            </SwipeAction>
          )
        })
        }
      </List>

      <div style={{padding: '50px 0'}}/>
      <div style={{textAlign: "center", display: canUpdate ? 'block' : 'none'}}>
        <Button color='primary' fill='outline' style={{margin: '0 20px 150px 20px', '--border-radius': '20px'}}
                onClick={() => {
                  chooseUser()
                }}>
          <div style={{fontSize: 'var(--adm-font-size-7)', margin: '5px 80px'}}>新增协作同事</div>
        </Button>
      </div>

      <Popup
        visible={userPopupVisible}
        onMaskClick={() => {
          setUserPopupVisible(false)
        }}
        position='right'
        bodyStyle={{width: 'var(--80vw)'}}
      >
        <div className={globalStyles.frame}>
          <div style={{
            fontSize: 'var(--adm-font-size-8)',
            padding: '20px',
            color: 'var(--adm-color-text)',
            textAlign: "center"
          }}
               className={globalStyles.top_b}>选择用户
          </div>
          <List className={globalStyles.body}
                style={{display: userListLoading ? 'none' : 'block', overflowY: 'scroll'}}>
            {
              userList.map((item, index) => {
                return (
                  <List.Item key={index} onClick={() => {
                    submitCreateRelevantUser(item.id, item.name, item.username)
                  }}>
                    {item.name}
                  </List.Item>
                )
              })
            }
          </List>
        </div>
        <div style={{margin: '20px 50px 20px 50px', display: userListLoading ? 'block' : 'none'}}>
          <Skeleton.Title animated/>
          <Skeleton.Paragraph lineCount={5} animated/>
        </div>

      </Popup>

      <FloatingBubble
        style={{
          '--initial-position-bottom': '38px',
          '--initial-position-right': '38px',
          '--edge-distance': '38px',
          display: canUpdate ? 'block' : 'none'
        }}
        onClick={() => {
          Dialog.alert({
            content: (<div style={{margin: '0 10px'}}>
              <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>关于协作同事</div>
              <div>1. 协作同事可与客户创建人一起管理该线索</div>
              <div>2. 协作同事拥有除“删除线索”外的所有权限</div>
              <div>3. 向左滑动协作同事，可移除该协作同事</div>
            </div>),
          })
        }}
      >
        <QuestionOutlined fontSize={32 * GlobalZoom}/>
      </FloatingBubble>
    </div>)
  }

  return (
    <div>
      <div className={styles.background_pic}/>
      <div className={globalStyles.background_grey}/>

      <div className={globalStyles.vh_frame}>
        <div style={{margin: '40px 50px 20px 50px'}} className={globalStyles.top}>
          <Card>
            <div style={{fontSize: '40px', fontWeight: 'bold'}}>
              {clue.name}
            </div>
            <Divider/>
            <div style={{marginTop: '20px', color: 'var(--adm-color-text-secondary)'}}>
              <Grid columns={2}>
                <Grid.Item>
                  创建时间：{SubString(clue.created_at, 0, 10)}
                </Grid.Item>
                <Grid.Item>
                  更新时间：{SubString(clue.updated_at, 0, 10)}
                </Grid.Item>
              </Grid>
            </div>
          </Card>
          <div style={{marginTop: '20px'}}/>
        </div>

        <div className={globalStyles.body} style={{padding: '0 20px'}}>
          <CapsuleTabs defaultActiveKey={tabKey} className={globalStyles.frame}
                       style={{'--adm-color-primary': '#59BB8F', '--adm-color-box': '#FFFFFF'}}>
            <CapsuleTabs.Tab title='线索详情' key={tabKeys[0]}>
              {detailTab()}
            </CapsuleTabs.Tab>
            <CapsuleTabs.Tab title='协作同事' key={tabKeys[1]}>
              {relevantTab()}
            </CapsuleTabs.Tab>
          </CapsuleTabs>
        </div>
      </div>
    </div>
  );
};

export default ClueDetail;