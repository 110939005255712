import React, {useEffect, useState} from 'react';
import {Card, Dialog, Grid} from "antd-mobile/2x";
import {
  FileOutline,
  GiftOutline,
  GlobalOutline,
  KoubeiOutline,
  ReceiptOutline,
  SearchOutline,
  ShopbagOutline,
  SmileOutline,
  TeamOutline,
  UnorderedListOutline
} from "antd-mobile-icons";
import MyItem from "../../MyComponents/MyItem/MyItem";
import styles from "./Home.less"
import globalStyles from "../../../App.less";
import {getDateTime} from "../../../utils/Date";
import {useHistory} from "react-router-dom";
import {
  HTML_TITLE,
  OrderUserRole,
  URL_CHANCE_LIST,
  URL_CUSTOMER_DUP_CHECK,
  URL_CUSTOMER_LIST,
  URL_HOME,
  URL_LINKMAN_LIST,
  URL_MERCHANT_LIST,
  URL_ORDER_LIST_CREATE_USER,
  URL_ORDER_LIST_RECEIVE_USER
} from "../../../constant";
import {Query, Url} from "../../../api/query";
import {MerchantType} from "../../Merchant/MerchantForm/MerchantForm";
import {GlobalZoom, IsPC} from "../../../index";

const Home = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = HTML_TITLE[URL_HOME]
    CheckNeedBadgeDot()
  }, [])


  const [hasUnfinishedOrder, setHasUnfinishedOrder] = useState(false)
  const [orderUserRole, setOrderUserRole] = useState(0)

  const CheckNeedBadgeDot = () => {
    Query(Url.orderHasUnfinished, {}, (res) => {
      setOrderUserRole(res.data.role)
      if (res.data.has_unfinished) {
        setHasUnfinishedOrder(true)
      }
    })
  }

  return (
    <div style={{width: 'var(--100vw)', overflowX: 'hidden'}}>

      <div className={globalStyles.background_grey}/>
      <div style={{position: 'relative'}}>
        <div className={IsPC ? styles.background_circle_pc : styles.background_circle}/>
      </div>

      <div style={{textAlign: 'center'}}>
        <div style={{color: 'var(--adm-color-white)', marginTop: '60px', fontSize: '50px'}}>
          <span style={{fontSize: '20px', marginRight: '10px ', color: '#1677ff'}}>测试版</span>
          欢迎使用营销管理系统
          <span style={{fontSize: '20px', marginLeft: '10px '}}>测试版</span>
        </div>
        <div style={{color: 'var(--adm-color-light)', marginTop: '20px', fontSize: 'var(--adm-font-size-8)'}}>
          {getDateTime()}
        </div>
      </div>

      <div style={{margin: "50px 30px 0 30px"}}>

        <Card style={{paddingBottom: '30px', borderRadius: '36px',boxShadow:'0 1px 10px 0 rgb(0 0 0 / 10%)'}}>
          <div style={{
            textAlign: 'center',
            fontSize: '38px',
            fontWeight: 'bold',
            marginTop: '10px',
            marginBottom: '35px'
          }}>
            客户
          </div>

          <Grid columns={3} gap={8} style={{textAlign: 'center'}}>
            <Grid.Item>
              <MyItem icon={<FileOutline fontSize={76 * GlobalZoom} color='var(--adm-color-primary)'/>} title="客户"
                      onClick={() => {
                        history.push({
                          pathname: URL_CUSTOMER_LIST,
                          // state: {
                          //   load: true
                          // }
                        })
                      }}/>
            </Grid.Item>
            <Grid.Item>
              <MyItem icon={<TeamOutline fontSize={76 * GlobalZoom} color='var(--adm-color-primary)'/>}
                      title="客户联系人"
                      onClick={() => {
                        history.push(URL_LINKMAN_LIST)
                      }}/>
            </Grid.Item>
          {/*  <Grid.Item>*/}
          {/*    <MyItem icon={<SearchOutline fontSize={76 * GlobalZoom} color='var(--adm-color-primary)'/>}*/}
          {/*            title="客户查重"*/}
          {/*            onClick={() => {*/}
          {/*              history.push(URL_CUSTOMER_DUP_CHECK)*/}
          {/*            }}/>*/}
          {/*  </Grid.Item>*/}
          </Grid>

          {/* TODO 线索*/}
          {/*<div style={{margin: '50px'}}/>*/}
          {/*<Grid columns={3} gap={8} style={{textAlign: 'center'}}>*/}

          {/*  <Grid.Item>*/}
          {/*    <MyItem icon={<LinkOutline fontSize={76* GlobalZoom} color='var(--adm-color-primary)'/>} title="客户线索"*/}
          {/*            onClick={() => {*/}
          {/*              history.push(URL_CLUE_LIST)*/}
          {/*            }}/>*/}
          {/*  </Grid.Item>*/}
          {/*</Grid>*/}
        </Card>
        <div style={{margin: '40px'}}/>

        <Card style={{paddingBottom: '30px', borderRadius: '36px',boxShadow:'0 1px 10px 0 rgb(0 0 0 / 10%)'}}>
          <div style={{
            textAlign: 'center',
            fontSize: '38px',
            fontWeight: 'bold',
            marginTop: '15px',
            marginBottom: '35px'
          }}>
            往来
          </div>

          <Grid columns={3} gap={8} style={{textAlign: 'center'}}>
            <Grid.Item>
              <MyItem icon={<ReceiptOutline fontSize={76 * GlobalZoom} color='var(--adm-color-primary)'/>} title="商机"
                      onClick={() => {
                        history.push(URL_CHANCE_LIST)
                      }}/>
            </Grid.Item>
            <Grid.Item>
              <MyItem icon={<ShopbagOutline fontSize={76 * GlobalZoom} color='var(--adm-color-primary)'/>}
                      title="订单" badge={hasUnfinishedOrder ? "待处理" : null}
                      onClick={() => {
                        if (orderUserRole === OrderUserRole.Create) {
                          history.push(URL_ORDER_LIST_CREATE_USER)
                        } else if (orderUserRole === OrderUserRole.Receive) {
                          history.push(URL_ORDER_LIST_RECEIVE_USER)
                        } else {
                          Dialog.alert({
                            content: <div style={{
                              fontSize: 'var(--adm-font-size-8)',
                              textAlign: "center",
                              margin: '20px 0',
                            }}>无法使用该功能
                              <div style={{marginTop: '20px', fontSize: 'var(--adm-font-size-5)'}}>
                                您不是相关人员，请联系管理员
                              </div>
                            </div>,
                          })
                        }

                      }}/>
            </Grid.Item>
          </Grid>
        </Card>
        <div style={{margin: '40px'}}/>

        <Card style={{paddingBottom: '30px', borderRadius: '36px',boxShadow:'0 1px 10px 0 rgb(0 0 0 / 10%)'}}>
          <div style={{
            textAlign: 'center',
            fontSize: '38px',
            fontWeight: 'bold',
            marginTop: '15px',
            marginBottom: '35px'
          }}>
            出行
          </div>

          <Grid columns={3} gap={8} style={{textAlign: 'center'}}>
            <Grid.Item>
              <MyItem icon={<GlobalOutline fontSize={76 * GlobalZoom} color='var(--adm-color-primary)'/>} title="住宿"
                      onClick={() => {
                        history.push(URL_MERCHANT_LIST + "?type=" + MerchantType["住宿"])
                      }}/>
            </Grid.Item>
            <Grid.Item>
              <MyItem icon={<KoubeiOutline fontSize={76 * GlobalZoom} color='var(--adm-color-primary)'/>} title="餐饮"
                      onClick={() => {
                        history.push(URL_MERCHANT_LIST + "?type=" + MerchantType["餐饮"])
                      }}/>
            </Grid.Item>
            <Grid.Item>
              <MyItem icon={<GiftOutline fontSize={76 * GlobalZoom} color='var(--adm-color-primary)'/>} title="烟酒礼品"
                      onClick={() => {
                        history.push(URL_MERCHANT_LIST + "?type=" + MerchantType["烟酒礼品"])
                      }}/>
            </Grid.Item>
          </Grid>

          <div style={{margin: '50px'}}/>
          <Grid columns={3} gap={8} style={{textAlign: 'center'}}>
            <Grid.Item>
              <MyItem icon={<SmileOutline fontSize={76 * GlobalZoom} color='var(--adm-color-primary)'/>} title="娱乐"
                      onClick={() => {
                        history.push(URL_MERCHANT_LIST + "?type=" + MerchantType["娱乐"])
                      }}/>
            </Grid.Item>
            <Grid.Item>
              <MyItem icon={<UnorderedListOutline fontSize={76 * GlobalZoom} color='var(--adm-color-primary)'/>}
                      title="其他"
                      onClick={() => {
                        history.push(URL_MERCHANT_LIST + "?type=" + MerchantType["其他"])
                      }}/>
            </Grid.Item>
          </Grid>
        </Card>


      </div>
      <div style={{height: '100px'}}/>

    </div>
  )
    ;
};

export default Home;