import React, {useEffect, useState} from 'react';
import {FloatingBubble, IndexBar, List, SearchBar, Skeleton} from "antd-mobile/2x";
import {
  HTML_TITLE,
  PS_RELOAD_CHANCE_LIST,
  URL_CHANCE_CREATE,
  URL_CHANCE_DETAIL,
  URL_CHANCE_LIST,
} from "../../../constant";
import globalStyles from "../../../App.less";
import "./ChanceList.css"
import {Query, Url} from "../../../api/query";
import ChineseHelper from "../../../utils/Pinyin/ChineseHelper";
import {AddOutline} from "antd-mobile-icons";
import {useHistory} from "react-router-dom";
import MyEmpty from "../../MyComponents/MyEmpty/MyEmpty";
import {GlobalZoom} from "../../../index";
import PubSub from "pubsub-js";

const ChanceList = () => {
  useEffect(() => {
    document.title = HTML_TITLE[URL_CHANCE_LIST]
    chanceQuerySelf()

    const ps_reload_chance_list = PubSub.subscribe(PS_RELOAD_CHANCE_LIST, () => {
      chanceQuerySelf()
    })
    return () => {
      PubSub.unsubscribe(ps_reload_chance_list)
    }
  }, [])

  const history = useHistory();

  const [myList, setMyList] = useState([])
  const [myListLoading, setMyListLoading] = useState(false)
  const [myGroups, setMyGroups] = useState([])

  const chanceQuerySelf = () => {
    setMyListLoading(true)
    Query(Url.chanceQueryByUserId, null,
      (res) => {
        let list = res.data.list
        for (let i = 0; i < list.length; i++) {
          list[i]["enName"] = ChineseHelper.ConvertPinyin(list[i].name)
          list[i]["enCustomerName"] = ChineseHelper.ConvertPinyin(list[i].customer_name)
        }

        setMyList(list)
        setGroups(list)
        setMyListLoading(false)
      },
      (res) => {
        setMyListLoading(false)
      },
      () => {
        setMyListLoading(false)
      }
    )
  }

  const searchMyList = (value) => {
    const keyword = value.split('').filter((s) => s !== '' && s !== ' ');
    setGroups(myList.filter((item) => {
      let flag = true
      keyword.forEach((k) => {
        if (!item.name.includes(k) && !item.customer_name.includes(k)) {
          flag = false
        }
      })
      return flag
    }))
  }

  const setGroups = (list) => {
    list.sort((o1, o2) => o1.enCustomerName.localeCompare(o2.enCustomerName, 'en'))

    let lastInitial = '$'
    let groups = []
    for (let i = 0; i < list.length; i++) {
      const initial = list[i].enCustomerName.charAt(0)
      if (initial !== lastInitial) {
        lastInitial = initial
        groups.push({
          title: initial,
          items: []
        })
      }
      groups[groups.length - 1].items.push(list[i])
    }
    if (groups.length !== 0) {
      groups.push({
        title: '',
        items: []
      })
    }
    setMyGroups(groups)
  }

  const ListDiv = () => {
    let isLoading = false
    isLoading = myListLoading
    return (<div className={globalStyles.body}>
      <div style={{margin: '20px 50px 20px 50px', display: isLoading ? 'block' : 'none'}}>
        <Skeleton.Title animated/>
        <Skeleton.Paragraph lineCount={5} animated/>
      </div>
      <div style={{margin: '80px 50px 20px 50px', display: !isLoading && myGroups.length <= 0 ? 'block' : 'none'}}>
        <MyEmpty/>
      </div>
      <IndexBar style={{display: (isLoading || myGroups.length <= 0) ? 'none' : 'block'}}>
        {myGroups.map(group => {
          const {title, items} = group
          return (
            <IndexBar.Panel index={title} title={title} key={title}>
              <List>
                {
                  items.map(listItem => (
                    <List.Item key={listItem.id} title={listItem.customer_name} onClick={() => {
                      history.push({
                        pathname: URL_CHANCE_DETAIL,
                        state: {chance: listItem}
                      })
                    }}>
                      {listItem.name}
                    </List.Item>
                  ))}
              </List>
            </IndexBar.Panel>
          )
        })}
      </IndexBar>
    </div>)
  }

  return (
    <div className={globalStyles.vh_frame}>
      <div className={globalStyles.body}>
        <FloatingBubble
          style={{
            '--initial-position-bottom': '24px',
            '--initial-position-right': '24px',
            '--edge-distance': '24px',
            '--size': '128px',
            '--z-index': '1000',
          }}
          onClick={() => {
            history.push(URL_CHANCE_CREATE)
          }}
        >
          <div>
            <AddOutline fontSize={60*GlobalZoom}/>
          </div>
        </FloatingBubble>


        <div className={globalStyles.frame}>
          <div className={globalStyles.top} style={{padding:'20px 24px 0 24px'}}>
            <div style={{margin: '10px 0 30px 0'}}>
              <SearchBar placeholder='查找我的商机' showCancelButton
                         onSearch={searchMyList}
                         onClear={() => {
                           setGroups(myList)
                         }}
                         onCancel={() => {
                           setGroups(myList)
                         }}
                         style={{'--height': '80px'}}
              />
            </div>
          </div>
          {ListDiv()}
        </div>
      </div>

    </div>
  );
};


export default ChanceList;