import React, {useEffect} from 'react';
import globalStyles from "../../../App.less";
import {
  Button,
  DatePicker,
  Dialog,
  FloatingBubble,
  Form,
  Input,
  List,
  Popup,
  Skeleton,
  TextArea,
  Toast
} from "antd-mobile/2x";
import {useHistory} from "react-router-dom";
import {HTML_TITLE, URL_ORDER_CREATE,} from "../../../constant";
import {Query, Url} from "../../../api/query";
import ChineseHelper from "../../../utils/Pinyin/ChineseHelper";
import MyPicker from "../../MyComponents/Form/MyPicker";
import {AddCircleOutline} from "antd-mobile-icons";
import dayjs from "dayjs";
import {QuestionOutlined} from "@ant-design/icons";
import {GlobalZoom} from "../../../index";


const OrderForm = (props) => {

  const history = useHistory()
  const formRef = React.createRef()

  const [customer, setCustomer] = React.useState({})
  const [customerPopup, setCustomerPopup] = React.useState(false)
  const [customerList, setCustomerList] = React.useState([])
  const [customerListLoading, setCustomerListLoading] = React.useState(false)

  const [user, setUser] = React.useState({})
  const [userPopup, setUserPopup] = React.useState(false)
  const [userList, setUserList] = React.useState([])
  const [userListLoading, setUserListLoading] = React.useState(false)

  const [hasSubmit, setHasSubmit] = React.useState(false)
  const role = props.location.state.role

  useEffect(() => {
    document.title = HTML_TITLE[URL_ORDER_CREATE]
  }, [])

  const setForm = (content) => {
    let form;
    (form = formRef.current) === null || form === void 0 ? void 0 : form.setFieldsValue(content);
  }

  const setCustomerInfo = (customer) => {
    setForm({customer_name: customer.name})
    setCustomer(customer)
  }

  const setUserInfo = (user) => {
    setForm({confirm_user_name: user.name})
    setUser(user)
  }

  const submitForm = (obj) => {
    let value = JSON.parse(JSON.stringify(obj))
    if (value.order_date !== undefined) {
      value.order_date = dayjs(value.order_date).format('YYYY-MM-DD')
    }
    if (value.products !== null && value.products !== undefined) {
      for (let i = 0; i < value.products.length; i++) {
        if (value.products[i].deliver_date !== undefined) {
          value.products[i].deliver_date = dayjs(value.products[i].deliver_date).format('YYYY-MM-DD')
        }
      }
    }
    if (JSON.stringify(value.products) === "[{}]") {
      value.products = []
    }

    let data = {}
    delete value.customer_name
    data.customer_id = customer.id
    data.confirm_user_id = user.id
    data.name = value.order_name
    data.content = value

    Dialog.confirm({
      content: <div style={{
        fontSize: 'var(--adm-font-size-8)', textAlign: "center", margin: '20px 0'
      }}>确认创建订单？</div>, onConfirm: async () => {
        setHasSubmit(true)
        Query(Url.orderCreate, data, (res) => {
          Toast.show({
            icon: 'success',
            duration: 2000,
            content: (
              <div style={{textAlign: 'center'}}>提交成功！<br/>请等待审批人<strong>{user.name}</strong>审批</div>),
          })
          setTimeout(() => {
            // history.replace({
            //   pathname: URL_ORDER_DETAIL, state: {
            //     order: res.data, role: role
            //   }
            // },)
            history.go(-1)
          }, 1500)
        }, (err) => {
          setHasSubmit(false)
        }, () => {
          setHasSubmit(false)
        })
      },
    })


  }

  const chooseCustomer = () => {
    setCustomerPopup(true)

    setCustomerListLoading(true)
    Query(Url.customerQuerySelf, null, (res) => {
      let list = res.data.list
      for (let i = 0; i < list.length; i++) {
        list[i]["enName"] = ChineseHelper.ConvertPinyin(list[i]["name"])
      }
      list.sort((o1, o2) => o1.enName.localeCompare(o2.enName, 'en'))

      setCustomerList(list)
      setCustomerListLoading(false)
    }, (res) => {
      setCustomerListLoading(false)
    }, () => {
      setCustomerListLoading(false)
    })
  }

  const chooseUser = () => {
    setUserPopup(true)

    setUserListLoading(true)
    Query(Url.userQuery, null, (res) => {
      let list = res.data.list
      for (let i = 0; i < list.length; i++) {
        list[i]["enName"] = ChineseHelper.ConvertPinyin(list[i]["name"])
      }
      list.sort((o1, o2) => o1.enName.localeCompare(o2.enName, 'en'))

      setUserList(list)
      setUserListLoading(false)
    }, (res) => {
      setUserListLoading(false)
    }, () => {
      setUserListLoading(false)
    })
  }

  return (<div style={{padding: '0 20px', background: '#EDEEF0'}} className={globalStyles.vh_frame}>
    <div className={globalStyles.body}>

      <Form ref={formRef}
            layout='horizontal'
            mode='card'
            onFinish={submitForm}
            initialValues={{
              products: [{}], order_birth_date: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            }}
            onFinishFailed={() => {
              Toast.show({
                icon: 'fail', content: '请检查表单是否填写完整',
              })
            }}
            footer={<Button block type='submit' color='primary' size='large'
                            disabled={hasSubmit}
                            style={{marginBottom: '80px', '--border-radius': '30px'}}>提交</Button>}
      >
        <Form.Header>基本信息</Form.Header>
        <Form.Item label='客户名称' name='customer_name' rules={[{required: true}]} onClick={chooseCustomer}>
          <Input placeholder='请输入' readOnly/>
        </Form.Item>

        <Form.Item label='订单名称' name='order_name' rules={[{required: true}]}>
          <Input placeholder='请输入'/>
        </Form.Item>

        <Form.Item label='订货日期' name='order_date' trigger='onConfirm' onClick={(e, datePickerRef) => {
          let _a;
          (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.open();
        }}>
          <DatePicker>{value => value ? dayjs(value).format('YYYY-MM-DD') :
            <div style={{color: '#cccccc'}}>请选择</div>}
          </DatePicker>
        </Form.Item>
        <Form.Item label='结算单位' name='charge_unit'>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='关联订单' name='relative_order'>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='参照订单' name='refer_order'>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='销售预计号' name='sales_forecast_number'>
          <Input placeholder='请输入'/>
        </Form.Item>

        <Form.Header>业务信息</Form.Header>
        <Form.Item label='业务类型' name='business_type'>
          <MyPicker columns={[['高铁', '机车', '城轨', '其他']]}/>
        </Form.Item>
        <Form.Item label='客户类型' name='customer_type'>
          <MyPicker columns={[['路局招标', '站段零售', '主机厂', '集成商', '城轨公司', '中间商', '其他']]}/>
        </Form.Item>
        <Form.Item label='业务归口' name='business_belong'>
          <MyPicker columns={[['机务', '电务', '车辆', '其他']]}/>
        </Form.Item>
        <Form.Item label='系统类型' name='system_type'>
          <MyPicker columns={[['牵引', '制动', '列控', '其他']]}/>
        </Form.Item>


        <Form.Header>联系人信息</Form.Header>
        <Form.Item label='联系人' name='receiver_name'>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='联系人电话' name='receiver_phone'>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='收货地址' name='receiver_location'>
          <TextArea
            placeholder='请输入'
            rows={3}
          />
        </Form.Item>

        <Form.Header>产品信息</Form.Header>
        <Form.Array
          name="products"
          renderAdd={() => (<span><AddCircleOutline/> 添加产品</span>)}
          renderHeader={({index}, {remove}) => (<span>
                <span>产品{index + 1}</span>
                <a onClick={() => remove(index)} style={{float: 'right', zIndex: 1}}>
                  删除
                </a>
              </span>)}
        >
          {fields => fields.map(({index}) => (<>
            <Form.Item name={[index, 'product_name']} label='产品名称'>
              <Input placeholder='请输入'/>
            </Form.Item>

            <Form.Item name={[index, 'product_type']} label='规格型号'
            >
              <Input placeholder='请输入'/>
            </Form.Item>
            <Form.Item name={[index, 'product_unit']} label='单位'
            >
              <Input placeholder='请输入'/>
            </Form.Item>
            <Form.Item name={[index, 'product_number']} label='数量'
            >
              <Input placeholder='请输入'/>
            </Form.Item>
            <Form.Item name={[index, 'product_price']} label='不含税单价（元）'
            >
              <Input placeholder='请输入'/>
            </Form.Item>

            <Form.Item label='交货日期' name={[index, 'deliver_date']} trigger='onConfirm'
                       onClick={(e, datePickerRef) => {
                         let _a;
                         (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.open();
                       }}>

              <DatePicker defaultDate={new Date()}>
                {value => value ? dayjs(value).format('YYYY-MM-DD') : <div style={{color: '#cccccc'}}>请选择</div>}
              </DatePicker>
            </Form.Item>
            <Form.Item name={[index, 'note']} label='备注'>
              <TextArea placeholder='请输入' rows={3}/>
            </Form.Item>
          </>))}
        </Form.Array>


        <Form.Header>其他信息</Form.Header>
        <Form.Item label='交付特殊要求' name='deliver_special_requirement'>
          <TextArea
            placeholder='请输入'
            rows={3}
          />
        </Form.Item>
        <Form.Item label='产品特殊要求' name='product_special_requirement'>
          <TextArea
            placeholder='请输入'
            rows={3}
          />
        </Form.Item>
        <Form.Item label='不含税采购价格(适用于其他销售)' name='order_price'>
          <TextArea
            placeholder='请输入'
            rows={3}
          />
        </Form.Item>


        <Form.Header>备注</Form.Header>
        <Form.Item name='order_note'>
          <TextArea
            placeholder='请输入'
            rows={5}
          />
        </Form.Item>

        <Form.Header>确认信息</Form.Header>
        <Form.Item label='创建时间' name='order_birth_date'>
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item label='审批人' name='confirm_user_name' rules={[{required: true}]} onClick={chooseUser}>
          <Input placeholder='请输入' readOnly/>
        </Form.Item>

      </Form>

      {/*Customer Popup*/}
      <Popup
        visible={customerPopup}
        onMaskClick={() => {
          setCustomerPopup(false)
        }}
        position='right'
        bodyStyle={{width: 'var(--80vw)'}}
      >
        <div className={globalStyles.frame}>
          <div style={{
            fontSize: 'var(--adm-font-size-8)', padding: '20px', color: 'var(--adm-color-text)', textAlign: "center"
          }}
               className={globalStyles.top_b}>选择客户
          </div>
          <List className={globalStyles.body}
                style={{display: customerListLoading ? 'none' : 'block', overflowY: 'scroll'}}>
            {customerList.map((item, index) => {
              return (<List.Item key={index} onClick={() => {
                setCustomerInfo(item)
                setCustomerPopup(false)
              }}>
                {item.name}
              </List.Item>)
            })}
          </List>
        </div>
        <div style={{margin: '20px 50px 20px 50px', display: customerListLoading ? 'block' : 'none'}}>
          <Skeleton.Title animated/>
          <Skeleton.Paragraph lineCount={5} animated/>
        </div>
      </Popup>

      {/*User Popup*/}
      <Popup
        visible={userPopup}
        onMaskClick={() => {
          setUserPopup(false)
        }}
        position='right'
        bodyStyle={{width: 'var(--80vw)'}}
      >
        <div className={globalStyles.frame}>
          <div style={{
            fontSize: 'var(--adm-font-size-8)', padding: '20px', color: 'var(--adm-color-text)', textAlign: "center"
          }}
               className={globalStyles.top_b}>选择审批人
          </div>
          <List className={globalStyles.body}
                style={{display: userListLoading ? 'none' : 'block', overflowY: 'scroll'}}>
            {userList.map((item, index) => {
              return (<List.Item key={index} onClick={() => {
                setUserInfo(item)
                setUserPopup(false)
              }}>
                {item.name}
              </List.Item>)
            })}
          </List>
        </div>
        <div style={{margin: '20px 50px 20px 50px', display: customerListLoading ? 'block' : 'none'}}>
          <Skeleton.Title animated/>
          <Skeleton.Paragraph lineCount={5} animated/>
        </div>

      </Popup>


    </div>

    <FloatingBubble
      style={{
        '--initial-position-bottom': '38px',
        '--initial-position-right': '38px',
        '--edge-distance': '38px',
      }}
      onClick={() => {
        Dialog.alert({
          content: (<div style={{margin: '0 10px'}}>
            <div style={{textAlign: "center", marginBottom: '20px', fontWeight: 'bold'}}>填写订单说明</div>
            <div>1.客户名称处填写确定业务的客户全称；</div>
            <div>2.结算单位填写开票单位全称；</div>
            <div>3.收货地址填写货物发出的收货单位地址；</div>
            <div>4.联系人及电话处填写收货人姓名及联系电话；</div>
            <div>5.交付特殊要求：指产品交付时的文件要求，如：二维码、合格证、检测报告电子文档等；</div>
            <div>6.产品特殊要求：指公司发布的产品指标外的其他对产品的要求，如：标识、标志要求等；</div>
            <div>7.费用要求：适用于其他销售，包含产品采购单价及其他扣除费用要求。</div>
          </div>),
        })
      }}
    >
      <QuestionOutlined fontSize={32* GlobalZoom}/>
    </FloatingBubble>

  </div>);
};

export default OrderForm;