import React, {useEffect, useState} from 'react';
import {Picker} from 'antd-mobile/2x';
import {RightOutline} from 'antd-mobile-icons';
import {GlobalZoom} from "../../../index";


const MyPicker = ({value, onChange, columns}) => {
  const [visible, setVisible] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  useEffect(() => {
    if (value === undefined) {
      setIsSelect(false)
    } else {
      setIsSelect(true);
    }
  }, [value]);


  const triggerValue = (changedValue) => {
    onChange === null || onChange === void 0 ? void 0 : onChange(changedValue);
  };
  const onPreValueChange = (value) => {
    const v = value[0];
    if (v === null) return;
    triggerValue(v);
    setIsSelect(true);
  };
  return (<>
    <div onClick={() => setVisible(true)} style={{display: 'flex', flexDirection: 'row'}}>
      <div style={{flex: '1'}}>
        <div style={{color: '#cccccc', display: isSelect ? 'none' : 'block'}}>请选择</div>
        <div>{value}</div>
      </div>
      <div style={{flex: '0', marginRight: '20px'}}>
        <RightOutline fontSize={38 * GlobalZoom} style={{color: '#cccccc'}}/>
      </div>
    </div>
    <Picker style={{
      "--header-button-font-size": '36px',
      "--item-font-size": '40px',
      "--item-height": '80px'
    }} columns={columns} visible={visible} onClose={() => {
      setVisible(false);
    }} value={[value]} onConfirm={onPreValueChange}/>
  </>);
};

export default MyPicker;