import React, {useEffect, useState} from 'react';
import {ApprovalStatus, CustomerType, HTML_TITLE, URL_APPROVAL_DETAIL} from "../../../constant";
import styles from "./ApprovalDetail.less";
import globalStyles from "../../../App.less";
import {Button, Card, Dialog, Divider, Grid, List, Popup, SafeArea, Steps, Toast} from "antd-mobile/2x";
import {ApprovalTitle} from "../ApprovalList/ApprovalList";
import {Step} from "antd-mobile/2x/es/components/steps/step";
import "./ApprovalDetail.css"
import {CheckOutline, CloseOutline, RightOutline} from "antd-mobile-icons";
import {Query, Url} from "../../../api/query";
import {CmpStr, NempStr, NempStrColor, SubString} from "../../../utils/StringUtil";
import {MerchantTypeText} from "../../Merchant/MerchantForm/MerchantForm";

export const ApprovalDetailDisplayType = {
  ApproveByMe: 0,
  ApproveByOther: 1
}
const PreviewType = {
  Customer: 0,
  Linkman: 1,
  Merchant: 2,
  Order: 3,
}

const ApprovalDetail = (props) => {

    const [displayType, setDisplayType] = useState(ApprovalDetailDisplayType.ApproveByMe)
    const [approval, setApproval] = React.useState({})
    const [displayPreviewType, setDisplayPreviewType] = useState(PreviewType.Customer)
    const [previewCustomer, setPreviewCustomer] = useState({Content: {}})
    const [previewLinkman, setPreviewLinkman] = useState({Content: {}})
    const [previewMerchant, setPreviewMerchant] = useState({Content: {}})
    const [originCustomer, setOriginCustomer] = useState({Content: {}})
    const [originLinkman, setOriginLinkman] = useState({Content: {}})
    const [originMerchant, setOriginMerchant] = useState({Content: {}})
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewOrder, setPreviewOrder] = useState({Content: {}})
    const [previewOrderCustomerName, setPreviewOrderCustomerName] = useState("")


    useEffect(() => {
      document.title = HTML_TITLE[URL_APPROVAL_DETAIL]
      if (props.location.state !== undefined) {
        let _displayType = props.location.state.displayType
        setDisplayType(_displayType)

        let approval
        if (_displayType === ApprovalDetailDisplayType.ApproveByMe) {
          approval = props.location.state.approveByMe
        } else if (_displayType === ApprovalDetailDisplayType.ApproveByOther) {
          approval = props.location.state.approveByOther
        } else {
          approval = {}
        }
        setApproval(approval)

        if (approval.cmd_type === 'UpdateCustomerArg' || approval.cmd_type === 'DeleteCustomerArg') {
          setDisplayPreviewType(PreviewType.Customer)

          const cmd = JSON.parse(approval.cmd)

          let customer = cmd.customer === undefined ? {Content: '{}'} : cmd.customer
          customer.Content = JSON.parse(customer.Content)
          setPreviewCustomer(customer)
          let customerOrigin = cmd.customer_origin
          customerOrigin.Content = JSON.parse(customerOrigin.Content)
          setOriginCustomer(customerOrigin)

        } else if (approval.cmd_type === 'UpdateLinkmanArg' || approval.cmd_type === 'DeleteLinkmanArg') {
          setDisplayPreviewType(PreviewType.Linkman)

          const cmd = JSON.parse(approval.cmd)

          let linkman = cmd.linkman === undefined ? {Content: '{}'} : cmd.linkman
          linkman.Content = JSON.parse(linkman.Content)
          setPreviewLinkman(linkman)
          let linkmanOrigin = cmd.linkman_origin
          linkmanOrigin.Content = JSON.parse(linkmanOrigin.Content)
          setOriginLinkman(linkmanOrigin)

        } else if (approval.cmd_type === 'UpdateMerchantArg' || approval.cmd_type === 'DeleteMerchantArg') {
          setDisplayPreviewType(PreviewType.Merchant)

          const cmd = JSON.parse(approval.cmd)
          let merchant = cmd.merchant === undefined ? {Content: '{}'} : cmd.merchant
          merchant.Content = JSON.parse(merchant.Content)
          setPreviewMerchant(merchant)

          let merchantOrigin = cmd.merchant_origin
          merchantOrigin.Content = JSON.parse(merchantOrigin.Content)
          setOriginMerchant(merchantOrigin)

        } else if (approval.cmd_type === 'CreateOrderArg') {
          setDisplayPreviewType(PreviewType.Order)

          const cmd = JSON.parse(approval.cmd)
          let order = cmd.order === undefined ? {Content: '{}'} : cmd.order
          order.Content = JSON.parse(order.Content)
          setPreviewOrder(order)

          const reg = new RegExp('客户「.*?」', 'g')
          const approvalContent = approval.content
          if (reg.test(approvalContent)) {
            const matchResult = approvalContent.match(reg)[0];
            setPreviewOrderCustomerName(matchResult.substring(3, matchResult.length - 1))
          }
        }
      }
    }, [])

    const approvalDeal = (id) => {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认同意该审批？</div>,
        onConfirm: async () => {
          Query(Url.approvalDeal, {id}, () => {
            let approvalNew = {...approval}
            approvalNew.is_deal = ApprovalStatus.Deal
            approvalNew.updated_at = "刚刚"
            setApproval(approvalNew)
            Toast.show({
              icon: 'success',
              content: '已同意',
              duration: 1000,
            })
          })
        },
      })
    }

    const approvalReject = (id) => {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认拒绝该审批？</div>,
        onConfirm: async () => {
          Query(Url.approvalReject, {id}, () => {
            let approvalNew = {...approval}
            approvalNew.is_deal = ApprovalStatus.Reject
            approvalNew.updated_at = "刚刚"
            setApproval(approvalNew)
            Toast.show({
              icon: 'success',
              content: '已拒绝',
              duration: 1000,
            })
          })
        },
      })
    }

    const customerPreview = () => {
      return <div style={{display: displayPreviewType === PreviewType.Customer ? 'block' : 'none'}}>
        <List
          header="客户信息"
          mode="card"
          style={{
            '--prefix-width': '200px',
            '--font-size': '28px',
          }}>
          <List.Item prefix='客户名称'>{CmpStr(originCustomer.Name, previewCustomer.Name)}</List.Item>
          <List.Item prefix='客户等级'>{CmpStr(originCustomer.Level, previewCustomer.Level)}</List.Item>
          <List.Item prefix='客户类型'>
            {CmpStr(originCustomer.Type === CustomerType.Company ? "企业客户" : "个人客户",
              previewCustomer.Type === CustomerType.Company ? "企业客户" : "个人客户")}
          </List.Item>
          <List.Item prefix='客户编号'>
            {CmpStr(originCustomer.Content.customer_id, previewCustomer.Content.customer_id)}
          </List.Item>
          <List.Item prefix='客户归属'>
            {CmpStr(originCustomer.Content.customer_source, previewCustomer.Content.customer_source)}
          </List.Item>
          <div style={{display: originCustomer.Type === 0 || previewCustomer.Type === 0 ? 'block' : 'none'}}>
            <List.Item prefix='企业地址'>
              {CmpStr(originCustomer.Content.customer_location, previewCustomer.Content.customer_location)}
            </List.Item>
            <List.Item prefix='企业规模'>
              {CmpStr(originCustomer.Content.customer_size, previewCustomer.Content.customer_size)}
            </List.Item>
          </div>
          <List.Item prefix='主营产品'>
            {CmpStr(originCustomer.Content.customer_product, previewCustomer.Content.customer_product)}
          </List.Item>
          <List.Item prefix='备注'>
            {CmpStr(originCustomer.Content.customer_note, previewCustomer.Content.customer_note)}
          </List.Item>
        </List>
        <div style={{
          textAlign: 'right', marginRight: '24px',
          color: 'var(--adm-color-text-secondary)',
          lineHeight: '40px'
        }}>
          <div>* 原始和变更内容相同时，不重复展示</div>
          <div>* 原始和变更内容不同时，<span style={{color: '#EF4823'}}>红色</span>内容为变更后内容</div>
        </div>
      </div>
    }

    const linkmanPreview = () => {
      return <div style={{display: displayPreviewType === PreviewType.Linkman ? 'block' : 'none'}}>
        <Divider
          style={{
            color: 'var(--adm-color-text-secondary)',
            borderColor: 'var(--adm-color-text-secondary)',
            margin: '24px 0px 24px 0px',
            borderStyle: 'double'
          }}
        >
          联系人信息
        </Divider>
        <List mode='card' header="个人信息"
              style={{
                '--prefix-width': '200px',
                '--font-size': '28px',
              }}>
          <List.Item prefix='联系人姓名'>{CmpStr(previewLinkman.Name, previewLinkman.Name)}</List.Item>
          <List.Item prefix='性别'>
            {CmpStr(originLinkman.Content.linkman_sex, previewLinkman.Content.linkman_sex)}
          </List.Item>
          <List.Item prefix='出生日期'>
            {CmpStr(originLinkman.Content.linkman_birthday, previewLinkman.Content.linkman_birthday)}
          </List.Item>
          <List.Item prefix='爱好'>
            {CmpStr(originLinkman.Content.linkman_hobby, previewLinkman.Content.linkman_hobby)}
          </List.Item>
          <List.Item prefix='家庭住址'>
            {CmpStr(originLinkman.Content.linkman_home_location, previewLinkman.Content.linkman_home_location)}
          </List.Item>
        </List>

        <List mode='card' header="职务信息"
              style={{
                '--prefix-width': '200px',
                '--font-size': '28px',
              }}>
          <List.Item prefix='部门'>
            {CmpStr(originLinkman.Content.linkman_depart, previewLinkman.Content.linkman_depart)}
          </List.Item>
          <List.Item prefix='职务'>
            {CmpStr(originLinkman.Content.linkman_post, previewLinkman.Content.linkman_post)}
          </List.Item>
          <List.Item prefix='办公地址'>
            {CmpStr(originLinkman.Content.linkman_work_location, previewLinkman.Content.linkman_work_location)}
          </List.Item>
        </List>

        <List mode='card' header="备注框" style={{
          '--prefix-width': '200px',
          '--font-size': '28px',
        }}>
          <List.Item>{CmpStr(originLinkman.Content.linkman_note, previewLinkman.Content.linkman_note)}</List.Item>
        </List>

        <div style={{
          textAlign: 'right', marginRight: '24px',
          color: 'var(--adm-color-text-secondary)',
          lineHeight: '40px'
        }}>
          <div>* 原始和变更内容相同时，不重复展示</div>
          <div>* 原始和变更内容不同时，<span style={{color: '#EF4823'}}>红色</span>内容为变更后内容</div>
        </div>

        <div style={{
          display: (((originLinkman.Content.phones !== undefined) && (originLinkman.Content.phones !== null) && (originLinkman.Content.phones.length !== 0)) ||
            (previewLinkman.Content.phones !== undefined) && (previewLinkman.Content.phones !== null) && (previewLinkman.Content.phones.length !== 0)) ? 'block' : 'none',
        }}>
          <Divider
            style={{
              color: 'var(--adm-color-text-secondary)',
              borderColor: 'var(--adm-color-text-secondary)',
              margin: '100px 0px 24px 0px',
              borderStyle: 'double'
            }}>
            联系人电话号码信息
          </Divider>
          <div>
            <List mode='card' header={'原电话号码'}
                  style={{
                    '--prefix-width': '200px',
                    '--font-size': '30px',
                  }}>
              {
                !(originLinkman.Content.phones !== undefined && originLinkman.Content.phones !== null) ? null :
                  originLinkman.Content.phones.map((item, index) => {
                    return (
                      <List.Item prefix={<div>号码{index + 1}</div>} key={index + 1}>{
                        NempStr(item.phone)
                      }</List.Item>
                    )
                  })
              }
            </List>

            <List mode='card' header={'新电话号码'}
                  style={{
                    '--prefix-width': '200px',
                    '--font-size': '30px',
                  }}>
              {
                !(previewLinkman.Content.phones !== undefined && previewLinkman.Content.phones !== null) ? null :
                  previewLinkman.Content.phones.map((item, index) => {
                    return (
                      <List.Item prefix={<div>号码{index + 1}</div>} key={index + 1}>{
                        NempStrColor(item.phone)
                      }</List.Item>
                    )
                  })
              }
            </List>

            <div style={{
              textAlign: 'right',
              marginRight: '24px',
              color: 'var(--adm-color-text-secondary)',
              lineHeight: '40px'
            }}>
              <div>* 原始和变更内容都会展示，分别展示在前后</div>
              <div>* <span style={{color: '#000000'}}>黑色</span>信息为原始内容，<span
                style={{color: '#1677ff'}}>蓝色</span>信息为变更后内容
              </div>
            </div>
          </div>
        </div>

        <div style={{
          display: (((originLinkman.Content.relative !== undefined) && (originLinkman.Content.relative !== null)) ||
            (previewLinkman.Content.relative !== undefined) && (previewLinkman.Content.relative !== null)) ? 'block' : 'none',
        }}>
          <Divider
            style={{
              color: 'var(--adm-color-text-secondary)',
              borderColor: 'var(--adm-color-text-secondary)',
              margin: '100px 0px 24px 0px',
              borderStyle: 'double'
            }}
          >
            联系人相关人信息
          </Divider>
          <div>
            {
              !(originLinkman.Content.relative !== undefined && originLinkman.Content.relative !== null) ? null :
                originLinkman.Content.relative.map((item, index) => {
                  return (
                    <div key={index + 1}>
                      <List mode='card' header={'相关人' + (index + 1)}
                            style={{
                              '--prefix-width': '200px',
                              '--font-size': '30px',
                            }}>
                        <List.Item prefix='姓名'>{NempStr(item.name)}</List.Item>
                        <List.Item prefix='关系'>{NempStr(item.relation)}</List.Item>
                        <List.Item prefix='出生年份'>{NempStr(item.birthday)}</List.Item>
                        <List.Item prefix='爱好'>{NempStr(item.hobby)}</List.Item>
                        <List.Item prefix='备注'>{NempStr(item.note)}</List.Item>
                      </List>
                    </div>
                  )
                })
            }
          </div>
          <div>
            {
              previewLinkman.Content.relative !== undefined && previewLinkman.Content.relative !== null ?
                previewLinkman.Content.relative.map((item, index) => {
                  return (
                    <div key={index + 1}>
                      <List mode='card' header={<div style={{color: '#1677ff'}}>{'相关人' + (index + 1)}</div>}
                            style={{
                              '--prefix-width': '200px',
                              '--font-size': '30px',
                            }}>
                        <List.Item prefix={<div style={{color: '#1677ff'}}>姓名</div>}>
                          {NempStrColor(item.name)}
                        </List.Item>
                        <List.Item prefix={<div style={{color: '#1677ff'}}>关系</div>}>
                          {NempStrColor(item.relation)}
                        </List.Item>
                        <List.Item prefix={<div style={{color: '#1677ff'}}>出生年份</div>}>
                          {NempStrColor(item.birthday)}
                        </List.Item>
                        <List.Item prefix={<div style={{color: '#1677ff'}}>爱好</div>}>
                          {NempStrColor(item.hobby)}
                        </List.Item>
                        <List.Item prefix={<div style={{color: '#1677ff'}}>备注</div>}>
                          {NempStrColor(item.note)}
                        </List.Item>
                      </List>
                    </div>
                  )
                }) :
                null
            }
          </div>

          <div style={{
            textAlign: 'right',
            marginRight: '24px',
            color: 'var(--adm-color-text-secondary)',
            lineHeight: '40px'
          }}>
            <div>* 原始和变更内容都会展示，分别展示在前后</div>
            <div>* <span style={{color: '#000000'}}>黑色</span>信息为原始内容，<span
              style={{color: '#1677ff'}}>蓝色</span>信息为变更后内容
            </div>
          </div>
        </div>
      </div>
    }

    const merchantPreview = () => {
      return <div style={{display: displayPreviewType === PreviewType.Merchant ? 'block' : 'none'}}>

        <List
          header="商户信息"
          mode="card"
          style={{
            '--prefix-width': '200px',
            '--font-size': '28px',
          }}>
          <List.Item prefix='商户名称'>
            {CmpStr(originMerchant.Name, previewMerchant.Name)}
          </List.Item>
          <List.Item prefix='商户城市'>
            {CmpStr(originMerchant.City, previewMerchant.City)}
          </List.Item>
          <List.Item prefix='商户类型'>{CmpStr(
            MerchantTypeText[Number(originMerchant.Type) - 1]
            , MerchantTypeText[Number(previewMerchant.Type) - 1])}</List.Item>
          <List.Item prefix='联系人姓名'>
            {CmpStr(originMerchant.Content.linkman_name, previewMerchant.Content.linkman_name)}
          </List.Item>
          <List.Item prefix='联系电话'>
            {CmpStr(originMerchant.Content.linkman_phone, previewMerchant.Content.linkman_phone)}
          </List.Item>
          <List.Item prefix='地址'>
            {CmpStr(originMerchant.Content.merchant_location, previewMerchant.Content.merchant_location)}
          </List.Item>
          <List.Item prefix='备注'>
            {CmpStr(originMerchant.Content.merchant_note, previewMerchant.Content.merchant_note)}
          </List.Item>
        </List>
        <div style={{
          textAlign: 'right', marginRight: '24px',
          color: 'var(--adm-color-text-secondary)',
          lineHeight: '40px'
        }}>
          <div>* 原始和变更内容相同时，不重复展示</div>
          <div>* 原始和变更内容不同时，<span style={{color: '#EF4823'}}>红色</span>内容为变更后内容</div>
        </div>
      </div>
    }

    const orderPreview = () => {
      return <div style={{display: displayPreviewType === PreviewType.Order ? 'block' : 'none'}}>

        <List header='基本信息'
              mode={"card"}
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          <List.Item prefix='客户名称'>{NempStr(previewOrderCustomerName)}</List.Item>
          <List.Item prefix='订单名称'>{NempStr(previewOrder.Content.order_name)}</List.Item>
          <List.Item prefix='订货日期'>{NempStr(previewOrder.Content.order_date)}</List.Item>
          <List.Item prefix='结算单位'>{NempStr(previewOrder.Content.charge_unit)}</List.Item>
          <List.Item prefix='关联订单'>{NempStr(previewOrder.Content.relative_order)}</List.Item>
          <List.Item prefix='参照订单'>{NempStr(previewOrder.Content.refer_order)}</List.Item>
          <List.Item prefix='销售预计号'>{NempStr(previewOrder.Content.sales_forecast_number)}</List.Item>
        </List>

        <List header='业务信息'
              mode={"card"}
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          <List.Item prefix='业务类型'>{NempStr(previewOrder.Content.business_type)}</List.Item>
          <List.Item prefix='客户类型'>{NempStr(previewOrder.Content.customer_type)}</List.Item>
          <List.Item prefix='业务归口'>{NempStr(previewOrder.Content.business_belong)}</List.Item>
          <List.Item prefix='系统类型'>{NempStr(previewOrder.Content.system_type)}</List.Item>
        </List>

        <List header='联系人信息'
              mode={"card"}
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          <List.Item prefix='联系人'>{NempStr(previewOrder.Content.receiver_name)}</List.Item>
          <List.Item prefix='联系人电话'>{NempStr(previewOrder.Content.receiver_phone)}</List.Item>
          <List.Item prefix='收货地址'>{NempStr(previewOrder.Content.receiver_location)}</List.Item>
        </List>

        <div>
          {
            previewOrder.Content.products !== undefined && previewOrder.Content.products !== null ?
              previewOrder.Content.products.map((item, index) => {
                return (
                  <div key={index + 1}>
                    <List mode='card' header={'产品' + (index + 1)}
                          style={{
                            '--prefix-width': '200px',
                            '--font-size': '30px',
                          }}>
                      <List.Item prefix='产品名称'>{NempStr(item.product_name)}</List.Item>
                      <List.Item prefix='规格型号'>{NempStr(item.product_type)}</List.Item>
                      <List.Item prefix='单位'>{NempStr(item.product_unit)}</List.Item>
                      <List.Item prefix='数量'>{NempStr(item.product_number)}</List.Item>
                      <List.Item prefix='不含税单价（元）'>{NempStr(item.product_price)}</List.Item>
                      <List.Item prefix='交货日期'>{NempStr(item.deliver_date)}</List.Item>
                      <List.Item prefix='备注'>{NempStr(item.note)}</List.Item>
                    </List>
                  </div>
                )
              }) :
              null
          }
        </div>

        <List header='其他信息'
              mode={"card"}
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          <List.Item prefix='交付特殊要求'>{NempStr(previewOrder.Content.deliver_special_requirement)}</List.Item>
          <List.Item prefix='产品特殊要求'>{NempStr(previewOrder.Content.product_special_requirement)}</List.Item>
          <List.Item prefix='不含税采购价格(适用于其他销售)'>{NempStr(previewOrder.Content.order_price)}</List.Item>
        </List>

        <List header='备注'
              mode={"card"}
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          <List.Item>{NempStr(previewOrder.Content.order_note)}</List.Item>
        </List>

        <List header='确认信息'
              mode={"card"}
              style={{
                '--prefix-width': '200px',
                '--font-size': '30px',
              }}>
          <List.Item prefix='创建时间'>{NempStr(previewOrder.Content.order_birth_date)}</List.Item>
          <List.Item prefix='确认人'>{NempStr(previewOrder.Content.confirm_user_name)}</List.Item>
        </List>
      </div>
    }

    return (
      <div>
        <div className={styles.background_pic}/>
        <div className={globalStyles.background_grey}/>
        <div className={globalStyles.vh_frame}>
          <div className={globalStyles.body} style={{padding: '10px 12px 0 12px'}}>

            <Card title={`审批详情 (` + approval.id + `)`}
                  style={{
                    margin: '50px 50px 0 50px', lineHeight: '40px',
                    fontSize: 'var(--adm-font-size-6)',
                    boxShadow: '0 1px 10px 0 rgb(0 0 0 / 10%)'
                  }}
                  extra={<div>预览 <RightOutline/></div>}
                  onHeaderClick={() => {
                    setPreviewVisible(true)
                  }}>
              {approval.content}
            </Card>


            <Card title='审批类型'
                  style={{
                    margin: '50px 50px 0 50px', lineHeight: '40px',
                    fontSize: 'var(--adm-font-size-6)',
                    boxShadow: '0 1px 10px 0 rgb(0 0 0 / 10%)'
                  }}>
              {ApprovalTitle[approval.cmd_type]}
            </Card>

            <Card title='审批人'
                  style={{
                    margin: '50px 50px 0 50px', lineHeight: '40px',
                    fontSize: 'var(--adm-font-size-6)',
                    boxShadow: '0 1px 10px 0 rgb(0 0 0 / 10%)'
                  }}>
              {
                (approval.approver === undefined || approval.approver === null ? "-" : approval.approver.map((item, index) => {
                  return <span key={item.id}>{item.name}{index === approval.approver.length - 1 ? '' : ' 或 '}</span>
                }))
              }
            </Card>

            <Card title='审批流' style={{
              margin: '50px 50px 0 50px',
              fontSize: 'var(--adm-font-size-6)',
              boxShadow: '0 1px 10px 0 rgb(0 0 0 / 10%)'
            }}>
              <div>
                <Steps direction='vertical'>
                  <Step
                    title='发起审批'
                    status='finish'
                    description={SubString(approval.created_at, 0, 19)}
                  />
                  {approval.is_deal === ApprovalStatus.Undeal ? <Step title='审批中' status='wait'/> :
                    (approval.is_deal === ApprovalStatus.Deal ?
                      <Step title='审批通过' status='finish'
                            description={SubString(approval.updated_at, 0, 19)}/> :
                      <Step title='审批不通过' status='error'
                            description={SubString(approval.updated_at, 0, 19)}/>)
                  }
                </Steps>
              </div>
            </Card>
            <div style={{padding: '50px 0'}}/>
          </div>
          <div className={globalStyles.bottom} style={{backgroundColor: '#DBDBDC'}}>
            <div style={{
              display: (displayType === ApprovalDetailDisplayType.ApproveByMe ||
                (displayType === ApprovalDetailDisplayType.ApproveByOther && approval.is_deal !== ApprovalStatus.Undeal)) ? 'block' : 'none'
            }}>
              <Grid columns={2} gap={8} style={{margin: '50px 0 50px 0'}}>
                <Grid.Item style={{textAlign: 'center'}}>
                  <Button color={approval.is_deal === ApprovalStatus.Reject ? 'default' : 'primary'} fill='solid'
                          size='large'
                          style={{width: '70%'}} disabled={approval.is_deal !== ApprovalStatus.Undeal}
                          onClick={() => {
                            approvalDeal(approval.id)
                          }}>
                    <Grid columns={9}>
                      <Grid.Item span={1}/>
                      <Grid.Item style={{textAlign: 'center'}} span={1}>
                        <CheckOutline/>
                      </Grid.Item>
                      <Grid.Item style={{textAlign: 'center'}} span={7}>
                        {approval.is_deal === ApprovalStatus.Deal ? '已同意' : '同意'}
                      </Grid.Item>
                    </Grid>
                  </Button>
                </Grid.Item>
                <Grid.Item style={{textAlign: 'center'}}>
                  <Button color={approval.is_deal === ApprovalStatus.Deal ? 'default' : 'danger'} fill='solid'
                          size='large'
                          style={{width: '70%'}} disabled={approval.is_deal !== ApprovalStatus.Undeal}
                          onClick={() => {
                            approvalReject(approval.id)
                          }}>
                    <Grid columns={9}>
                      <Grid.Item span={1}/>
                      <Grid.Item style={{textAlign: 'center'}} span={1}>
                        <CloseOutline/>
                      </Grid.Item>
                      <Grid.Item style={{textAlign: 'center'}} span={7}>
                        {approval.is_deal === ApprovalStatus.Reject ? '已拒绝' : '拒绝'}
                      </Grid.Item>
                    </Grid>
                  </Button>
                </Grid.Item>
              </Grid>
              <SafeArea position={'bottom'}/>
            </div>
          </div>
        </div>

        <Popup visible={previewVisible}
               onMaskClick={() => {
                 setPreviewVisible(false)
               }}
               position='right'
               bodyStyle={{width: 'var(--80vw)'}}

        >
          <div style={{backgroundColor: '#EDEEF0'}} className={globalStyles.vh_frame}>
            <div className={globalStyles.body}>
              {customerPreview()}
              {linkmanPreview()}
              {merchantPreview()}
              {orderPreview()}
              <div style={{padding: '50px'}}/>
            </div>
          </div>
        </Popup>
      </div>
    )
      ;
  }
;

export default ApprovalDetail;