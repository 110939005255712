const NempStr = (content) => {
  return content === null || content === undefined || content === '' ? <div>&nbsp;</div> : content
}

const NempStrColor = (content) => {
  return content === null || content === undefined || content === '' ? <div>&nbsp;</div> :
    <div style={{color: '#1677ff'}}>{content}</div>
}

const CmpStr = (c1, c2) => {
  if (c1 === c2) {
    return c1 === null || c1 === undefined || c1 === '' ?
      (<div>&nbsp;</div>) :
      (<div>{c1}</div>)
  }
  if (c1 === null || c1 === undefined || c1 === '') {
    c1 = " "
  }
  if (c2 === null || c2 === undefined || c2 === '') {
    c2 = "(空)"
  }
  return (<>
    <div>{c1}</div>
    <div style={{color: '#EF4823'}}>{c2}</div>
  </>)
}

export const SubString = (str, begin, end) => {
  if (str !== undefined && str !== null) {
    return str.substring(begin, end)
  }
  return " "
}
export {NempStr, NempStrColor, CmpStr}
