import React, {useEffect, useState} from 'react';
import {Divider, FloatingBubble, JumboTabs, List, SearchBar, Skeleton, Tag} from "antd-mobile/2x";
import {
  HTML_TITLE,
  OrderStatus,
  OrderUserRole,
  URL_ORDER_CREATE,
  URL_ORDER_DETAIL,
  URL_ORDER_LIST_CREATE_USER,
} from "../../../constant";
import globalStyles from "../../../App.less";
import "./OrderListCreateUser.css"
import {Query, Url} from "../../../api/query";
import {
  AddOutline,
  CheckCircleOutline,
  CloseCircleOutline,
  ExclamationCircleOutline,
  PieOutline
} from "antd-mobile-icons";
import {useHistory} from "react-router-dom";
import MyEmpty from "../../MyComponents/MyEmpty/MyEmpty";
import {SubString} from "../../../utils/StringUtil";
import {GlobalZoom} from "../../../index";

// const QueryType = {
//   MyType: "MyType",
//   UnderlingType: "UnderlingType",
// }

const tabKeys = ['0', '1', '2', '3']
let tabKey = tabKeys[1]

const OrderListCreateUser = () => {
  useEffect(() => {
    document.title = HTML_TITLE[URL_ORDER_LIST_CREATE_USER]
    orderQuerySelf()
  }, [])

  const history = useHistory();

  const [originList, setOriginList] = useState([])
  const [originListLoading, setOriginListLoading] = useState(false)
  const [displayList, setDisplayList] = useState([])
  const [searchText, setSearchText] = useState("")

  const orderQuerySelf = () => {
    setOriginListLoading(true)
    Query(Url.orderQuerySelf, null,
      (res) => {
        const list = res.data.list
        setOriginList(list)
        reFilter(list)
        setOriginListLoading(false)
      },
      (res) => {
        setOriginListLoading(false)
      },
      () => {
        setOriginListLoading(false)
      }
    )
  }

  const reFilter = (sourceList, searchWord) => {
    let [...list] = sourceList === undefined ? originList : sourceList

    console.log(tabKey)

    if (tabKey === tabKeys[1]) {
      list = list.filter((item) => item.status === OrderStatus.Unfinished)
    } else if (tabKey === tabKeys[2]) {
      list = list.filter((item) => item.status === OrderStatus.Finish)
    } else if (tabKey === tabKeys[3]) {
      list = list.filter((item) => item.status === OrderStatus.Close)
    }

    const search = searchWord === undefined ? searchText : searchWord
    if (search !== "") {
      const keyword = search.split('').filter((s) => s !== '' && s !== ' ');
      list = list.filter((item) => {
        let flag = true
        keyword.forEach((k) => {
          if (!item.name.includes(k) && !item.customer_name.includes(k)) {
            flag = false
          }
        })
        return flag
      })
    }

    list.sort((o1, o2) => o2.id - o1.id)

    setDisplayList(list)
  }

  const ListDiv = () => {
    // let isLoading = false
    // isLoading = originListLoading
    return (
      <div>
        <div style={{margin: '20px 50px 20px 50px', display: originListLoading ? 'block' : 'none'}}>
          <Skeleton.Title animated/>
          <Skeleton.Paragraph lineCount={5} animated/>
        </div>
        <div style={{
          margin: '80px 50px 20px 50px',
          display: !originListLoading && displayList.length <= 0 ? 'block' : 'none',
          height: '100%'
        }}>
          <MyEmpty/>
        </div>
        <List style={{display: !originListLoading && displayList.length > 0 ? 'block' : 'none'}}>
          {
            displayList.map((item, index) => {
              return (
                <List.Item key={index + 1} title={<div>订单{item.id} &nbsp; {item.customer_name}</div>}
                           extra={
                             item.status === OrderStatus.Unfinished
                               ? <Tag color="danger" fill='outline' style={{'--border-radius': '10px'}}>待确认</Tag>
                               : (item.status === OrderStatus.Finish
                                   ? (
                                     <div>
                                     <span style={{
                                       marginRight: '10px',
                                       fontSize: '26px'
                                     }}>{item.receive_user_id === 0 ? null : item.receive_user_name}</span>
                                       <Tag color="success" fill='outline'
                                            style={{'--border-radius': '10px'}}>已确认</Tag>
                                     </div>)
                                   : (
                                     <Tag color="default" fill='outline' style={{'--border-radius': '10px'}}>已关闭</Tag>)
                               )
                           }
                           description={
                             <div>
                               创建时间：{SubString(item.created_at, 0, 10)}
                             </div>
                           }
                           onClick={() => {
                             history.push({
                                 pathname: URL_ORDER_DETAIL,
                                 state: {
                                   order: item,
                                   role: OrderUserRole.Create
                                 }
                               }
                             )
                           }}>
                  {item.name}
                </List.Item>
              )
            })
          }
          <Divider style={{margin: '0px', borderWidth: '1px'}}/>
        </List>
      </div>
    )
  }

  const tabInfo = [
    {
      key: tabKeys[0],
      title: <PieOutline/>,
      description: '所有订单'
    },
    {
      key: tabKeys[1],
      title: <ExclamationCircleOutline/>,
      description: '待确认'
    }
    ,
    {
      key: tabKeys[2],
      title: <CheckCircleOutline/>,
      description: '已确认'
    }
    ,
    {
      key: tabKeys[3],
      title: <CloseCircleOutline/>,
      description: '已关闭'
    }
  ]

  return (
    <div className={globalStyles.vh_frame}>
      <div className={globalStyles.body}>
        <FloatingBubble
          style={{
            '--initial-position-bottom': '24px',
            '--initial-position-right': '24px',
            '--edge-distance': '24px',
            '--size': '128px',
            '--z-index': '1000',
          }}
          onClick={() => {
            history.push({
              pathname: URL_ORDER_CREATE,
              state: {
                role: OrderUserRole.Create
              }
            })
          }}
        >
          <div>
            <AddOutline fontSize={60* GlobalZoom}/>
          </div>
        </FloatingBubble>


        <div className={globalStyles.frame}>
          <div className={globalStyles.top} style={{padding: '20px 24px 0 24px'}}>
            <div style={{margin: '10px 0 0 0'}}>
              <SearchBar placeholder='查找我的订单' showCancelButton
                         onSearch={(searchText) => {
                           setSearchText(searchText)
                           reFilter(undefined, searchText)
                         }}
                         onClear={() => {
                           setSearchText("")
                           reFilter(undefined, "")
                         }}
                         onCancel={() => {
                           setSearchText("")
                           reFilter(undefined, "")
                         }}
                         style={{'--height': '80px'}}
              />
            </div>
          </div>

          <div className={globalStyles.body}>
            <JumboTabs
              className="order-tab-frame"
              activeKey={tabKey}
              defaultActiveKey={tabKey} onChange={(value) => {
              tabKey = value
              reFilter()
            }}>
              {
                tabInfo.map((item, index) => {
                  return (
                    <JumboTabs.Tab title={item.title} description={item.description} key={item.key}>

                      {ListDiv()}
                    </JumboTabs.Tab>
                  )
                })
              }

            </JumboTabs>
          </div>
        </div>
      </div>

    </div>
  );
};


export default OrderListCreateUser;