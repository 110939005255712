import React, {useEffect, useState} from 'react';
import {CapsuleTabs, FloatingBubble, IndexBar, List, SearchBar, Skeleton} from "antd-mobile/2x";
import {
  HTML_TITLE,
  PS_RELOAD_CUSTOMER_LIST, PS_RELOAD_LINKMAN_LIST,
  URL_LINKMAN_CREATE,
  URL_LINKMAN_DETAIL,
  URL_LINKMAN_LIST,
} from "../../../constant";
import "./LinkmanList.css"
import globalStyles from "../../../App.less";
import {Query, Url} from "../../../api/query";
import ChineseHelper from "../../../utils/Pinyin/ChineseHelper";
import {AddOutline} from "antd-mobile-icons";
import {useHistory} from "react-router-dom";
import MyEmpty from "../../MyComponents/MyEmpty/MyEmpty";
import {GlobalZoom} from "../../../index";
import PubSub from "pubsub-js";

const QueryType = {
  MyType: "MyType",
  UnderlingType: "UnderlingType",
}

const tabKeys = ['0', '1']
let tabKey = tabKeys[0]

const LinkmanList = () => {
  useEffect(() => {
    document.title = HTML_TITLE[URL_LINKMAN_LIST]
    linkmanQuerySelf()

    const ps_reload_linkman_list = PubSub.subscribe(PS_RELOAD_LINKMAN_LIST, () => {
      linkmanQuerySelf()
      linkmanQueryUnderling()
    })
    return () => {
      PubSub.unsubscribe(ps_reload_linkman_list)
    }
  }, [])

  const history = useHistory();

  const [myList, setMyList] = useState([])
  const [myListLoading, setMyListLoading] = useState(false)
  const [myGroups, setMyGroups] = useState([])

  const [underlingList, setUnderlingList] = useState([])
  const [underlingListLoading, setUnderlingListLoading] = useState(false)
  const [underlingGroups, setUnderlingGroups] = useState([])

  const linkmanQuerySelf = () => {
    setMyListLoading(true)
    Query(Url.linkmanQuerySelf, null,
      (res) => {
        let list = res.data.list
        for (let i = 0; i < list.length; i++) {
          list[i].linkman["enName"] = ChineseHelper.ConvertPinyin(list[i].linkman.name)
          list[i].customer["enName"] = ChineseHelper.ConvertPinyin(list[i].customer.name)
        }

        setMyList(list)
        setGroups(list, QueryType.MyType)
        setMyListLoading(false)
      },
      (res) => {
        setMyListLoading(false)
      },
      () => {
        setMyListLoading(false)
      }
    )
  }
  const linkmanQueryUnderling = () => {
    setUnderlingListLoading(true)

    Query(Url.linkmanQueryUnderling, null,
      (res) => {
        let list = res.data.list
        for (let i = 0; i < list.length; i++) {
          list[i].linkman["enName"] = ChineseHelper.ConvertPinyin(list[i].linkman.name)
          list[i].customer["enName"] = ChineseHelper.ConvertPinyin(list[i].customer.name)
        }
        setUnderlingList(list)
        setGroups(list, QueryType.UnderlingType)
        setUnderlingListLoading(false)
      },
      (res) => {
        setMyListLoading(false)
      },
      () => {
        setMyListLoading(false)
      }
    )

  }
  const searchMyList = (value) => {
    const keyword = value.split('').filter((s) => s !== '' && s !== ' ');
    setGroups(myList.filter((item) => {
      let flag = true
      keyword.forEach((k) => {
        if (!item.linkman.name.includes(k) && !item.customer.name.includes(k)) {
          flag = false
        }
      })
      return flag
    }), QueryType.MyType)
  }
  const searchUnderlingList = (value) => {
    const keyword = value.split('').filter((s) => s !== '' && s !== ' ');
    setGroups(underlingList.filter((item) => {
      let flag = true
      keyword.forEach((k) => {
        if (!item.linkman.name.includes(k) && !item.customer.name.includes(k)) {
          flag = false
        }
      })
      return flag
    }), QueryType.UnderlingType)
  }
  const setGroups = (list, type) => {
    list.sort((o1, o2) =>  o1.linkman.enName.localeCompare(o2.linkman.enName, 'en'))


    let lastInitial = '$'
    let groups = []
    for (let i = 0; i < list.length; i++) {
      const initial = list[i].linkman.enName.charAt(0)
      if (initial !== lastInitial) {
        lastInitial = initial
        groups.push({
          title: initial,
          items: []
        })
      }
      groups[groups.length - 1].items.push(list[i])
    }
    if (groups.length !== 0) {
      groups.push({
        title: '',
        items: []
      })
    }
    if (type === QueryType.MyType) {
      setMyGroups(groups)
    } else if (type === QueryType.UnderlingType) {
      setUnderlingGroups(groups)
    }
  }

  const ListDiv = (type) => {
    let isLoading = false
    let groups = []
    if (type === QueryType.MyType) {
      isLoading = myListLoading
      groups = myGroups
    } else if (type === QueryType.UnderlingType) {
      isLoading = underlingListLoading
      groups = underlingGroups
    }
    return (<div className={globalStyles.body}>
      <div style={{margin: '20px 50px 20px 50px', display: isLoading ? 'block' : 'none'}}>
        <Skeleton.Title animated/>
        <Skeleton.Paragraph lineCount={5} animated/>
      </div>
      <div style={{margin: '80px 50px 20px 50px', display: !isLoading && groups.length <= 0 ? 'block' : 'none'}}>
        <MyEmpty/>
      </div>
      <IndexBar style={{display: (isLoading || groups.length <= 0) ? 'none' : 'block'}}>
        {groups.map(group => {
          const {title, items} = group
          return (
            <IndexBar.Panel index={title} title={title} key={title}>
              <List>
                {
                  items.map((listItem, index) => (
                    <List.Item key={index + 1} description={
                      <div>
                        {listItem.customer.name}
                      </div>
                    } onClick={() => {
                      history.push({
                        pathname: URL_LINKMAN_DETAIL,
                        state: {customer: listItem.customer, linkman: listItem.linkman}
                      })
                    }}>
                      {listItem.linkman.name}
                    </List.Item>
                  ))}
              </List>
            </IndexBar.Panel>
          )
        })}
      </IndexBar>
    </div>)
  }

  return (
    <div className={globalStyles.vh_frame}>
      <div className={globalStyles.body}>
        <FloatingBubble
          style={{
            '--initial-position-bottom': '24px',
            '--initial-position-right': '24px',
            '--edge-distance': '24px',
            '--size': '128px',
            '--z-index': '1000',
          }}
          onClick={() => {
            history.push(URL_LINKMAN_CREATE)
          }}
        >
          <div>
            <AddOutline fontSize={60 * GlobalZoom}/>
          </div>
        </FloatingBubble>

        <CapsuleTabs defaultActiveKey={tabKey} className="linkman-tab-frame"
                     onChange={(key) => {
                       if (key === tabKeys[0]) {
                         linkmanQuerySelf()
                       } else if (key === tabKeys[1]) {
                         linkmanQueryUnderling()
                       }
                     }}>



          <CapsuleTabs.Tab title='我的客户联系人' key={tabKeys[0]}>
            <div className={globalStyles.frame}>
              <div className={globalStyles.top} style={{padding: '20px 24px 0 24px'}}>
                <SearchBar placeholder='查找我的客户联系人（姓名、企业名）' showCancelButton
                           onSearch={searchMyList}
                           onClear={() => {
                             setGroups(myList, QueryType.MyType)
                           }}
                           onCancel={() => {
                             setGroups(myList, QueryType.MyType)
                           }}
                           style={{'--height': '80px'}}
                />
                <div style={{margin: '20px'}}/>
              </div>
              {ListDiv(QueryType.MyType)}
            </div>
          </CapsuleTabs.Tab>

          <CapsuleTabs.Tab title='其他客户联系人' key={tabKeys[1]}>
            <div className={globalStyles.frame}>
              <div className={globalStyles.top} style={{padding: '20px 24px 0 24px'}}>
                <SearchBar placeholder='查找其他客户联系人（姓名、企业名）' showCancelButton
                           onSearch={searchUnderlingList}
                           onClear={() => {
                             setGroups(underlingList, QueryType.UnderlingType)
                           }}
                           onCancel={() => {
                             setGroups(underlingList, QueryType.UnderlingType)
                           }}
                           style={{'--height': '80px'}}
                />
                <div style={{margin: '20px'}}/>
              </div>
              {ListDiv(QueryType.UnderlingType)}
            </div>
          </CapsuleTabs.Tab>
        </CapsuleTabs>
      </div>

    </div>
  );
};


export default LinkmanList;