import React from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {URL_LOGIN, URL_ROOT} from "../../../constant";
import {getSessionAuthorization} from "../../../storage/storage";
import {IS_MOCK} from "../../../api/query";


class MyRoute extends React.Component {
  state = {
    isLogin: (getSessionAuthorization() !== undefined),
  }

  render() {
    let {
      component: Component,
      path = URL_ROOT,
      exact = false,
      strict = false,
      needLogin = !IS_MOCK,
    } = this.props;
    if (needLogin) {
      return getSessionAuthorization() !== undefined ?
        (<Route path={path} exact={exact} strict={strict} render={(props) => (<Component {...props} />)}/>) :
        (<Switch>
          <Redirect to={URL_LOGIN}/>
        </Switch>)
    } else {
      return (<Route path={path} exact={exact} strict={strict} render={(props) => (<Component {...props} />)}/>);
    }
  }
}

export default withRouter(MyRoute);