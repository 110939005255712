//------Session------
export const SESSION_IS_LOGIN = "SESSION_IS_LOGIN";
export const SESSION_USER_TYPE = "SESSION_USER_TYPE";
export const SESSION_USER_NAME = "SESSION_USER_NAME";
export const SESSION_USERID = "SESSION_USERID";
export const SESSION_AVATAR = "SESSION_AVATAR";
export const SESSION_MOBILE = "SESSION_MOBILE";
export const SESSION_LEADERS = "SESSION_LEADERS";

export const SESSION_AUTHORIZATION = "SESSION_AUTHORIZATION"
export const SESSION_CUSTOMER_SEARCH_HISTORY = "SESSION_CUSTOMER_SEARCH_HISTORY";
export const SESSION_LAST_CUSTOMER_INFO = "SESSION_LAST_CUSTOMER_INFO";
export const SESSION_LAST_LINKMAN_INFO = "SESSION_LAST_LINKMAN_INFO";
export const SESSION_LAST_CUSTOMER_IN_LINKMAN_INFO = "SESSION_LAST_CUSTOMER_IN_LINKMAN_INFO";
export const SESSION_LAST_CLUE_INFO = "SESSION_LAST_CLUE_INFO";
export const SESSION_LAST_CHANCE_INFO = "SESSION_LAST_CHANCE_INFO";
export const SESSION_LAST_ORDER_INFO = "SESSION_LAST_ORDER_INFO";
export const SESSION_LAST_ORDER_USER_ROLE = "SESSION_LAST_ORDER_USER_ROLE"
export const SESSION_LAST_MERCHANT_INFO = "SESSION_LAST_MERCHANT_INFO";
export const SESSION_LAST_CUSTOMER_IN_CHANCE_INFO = "SESSION_LAST_CUSTOMER_IN_CHANCE_INFO";


//------URL------
export const URL_ROOT = "/";
export const URL_LOGIN = "/login";
export const URL_LOGIN_RESULT = "/loginResult";
export const URL_MAIN = "/main";
export const URL_HOME = URL_MAIN + "/home";
export const URL_NOTIFICATION = URL_MAIN + "/notification";
export const URL_APPROVAL_LIST = URL_MAIN + "/approval";
export const URL_ME = URL_MAIN + "/me";

export const URL_CUSTOMER_LIST = "/customer_list"
export const URL_CUSTOMER_EDIT = "/customer_edit";
export const URL_CUSTOMER_CREATE = "/customer_edit/create";
export const URL_CUSTOMER_UPDATE = "/customer_edit/update";
export const URL_CUSTOMER_DETAIL = "/customer_detail";
export const URL_CUSTOMER_DUP_CHECK = "/customer_dup_check";

export const URL_LINKMAN_LIST = "/linkman_list"
export const URL_LINKMAN_EDIT = "/linkman_edit";
export const URL_LINKMAN_CREATE = "/linkman_edit/create";
export const URL_LINKMAN_UPDATE = "/linkman_edit/update";
export const URL_LINKMAN_DETAIL = "/linkman_detail";

export const URL_APPROVAL_DETAIL = "/approval_detail";

export const URL_CLUE_LIST = "/clue_list";
export const URL_CLUE_EDIT = "/clue_edit";
export const URL_CLUE_CREATE = "/clue_edit/create";
export const URL_CLUE_UPDATE = "/clue_edit/update";
export const URL_CLUE_CONVERT_TO_LINKMAN = "/clue_edit/convert_to_linkman";
export const URL_CLUE_DETAIL = "/clue_detail";

export const URL_CHANCE_LIST = "/chance_list";
export const URL_CHANCE_EDIT = "/chance_edit";
export const URL_CHANCE_CREATE = "/chance_edit/create";
export const URL_CHANCE_UPDATE = "/chance_edit/update";
export const URL_CHANCE_DETAIL = "/chance_detail";

export const URL_ORDER_LIST_CREATE_USER = "/order_list/create_user";
export const URL_ORDER_LIST_RECEIVE_USER = "/order_list/receive_user";
export const URL_ORDER_EDIT = "/order_edit";
export const URL_ORDER_CREATE = "/order_edit/create";
export const URL_ORDER_DETAIL = "/order_detail";

export const URL_MERCHANT_LIST = "/merchant_list";
export const URL_MERCHANT_EDIT = "/merchant_edit";
export const URL_MERCHANT_CREATE = "/merchant_edit/create";
export const URL_MERCHANT_UPDATE = "/merchant_edit/update";
export const URL_MERCHANT_DETAIL = "/merchant_detail";

export const URL_PERMISSION_DENIED = "/403";
export const URL_NOT_FOUNT = "/404";
export const URL_ANY = "/*";

export const ApprovalStatus = {
  Undeal: 1,// 未处理
  Deal: 2,// 已同意
  Reject: 3// 已拒绝
}

export const NotificationStatus = {
  Unread: 1,//未读
  Read: 2,// 已读
}

export const OrderStatus = {
  Unfinished: 1,//未确认
  Finish: 2,// 已确认
  Close: 3// 已拒绝
}
export const OrderUserRole = {
  Create: 1,// 创建人
  Receive: 2, // 接收人
  Else: 0// 其他人
}
export const CustomerType = {//客户类型
  Company: 1,
  Person: 2
}

export const LoginRedirectState = {
  Approval: "approval",
  ChanceList: "chance_list",
  OrderList: "order_list",
  CustomerList: "customer_list",
}

//HTML_Title
const HTML_TITLE = []
HTML_TITLE[URL_HOME] = 'CRM - 主页'
HTML_TITLE[URL_NOTIFICATION] = "CRM - 通知"
HTML_TITLE[URL_APPROVAL_LIST] = "CRM - 审批"
HTML_TITLE[URL_ME] = "CRM - 我的"
HTML_TITLE[URL_CUSTOMER_LIST] = '客户列表'
HTML_TITLE[URL_CUSTOMER_EDIT] = '编辑客户信息'
HTML_TITLE[URL_CUSTOMER_CREATE] = '创建新客户'
HTML_TITLE[URL_CUSTOMER_UPDATE] = '修改客户信息'
HTML_TITLE[URL_CUSTOMER_DETAIL] = '客户详情'
HTML_TITLE[URL_CUSTOMER_DUP_CHECK] = '客户查重'

HTML_TITLE[URL_LINKMAN_LIST] = '客户联系人列表'
HTML_TITLE[URL_LINKMAN_EDIT] = '编辑联系人信息'
HTML_TITLE[URL_LINKMAN_CREATE] = '创建新联系人'
HTML_TITLE[URL_LINKMAN_UPDATE] = '修改联系人信息'
HTML_TITLE[URL_LINKMAN_DETAIL] = '联系人详情'

HTML_TITLE[URL_APPROVAL_DETAIL] = '审批详情'

HTML_TITLE[URL_CLUE_LIST] = '线索列表'
HTML_TITLE[URL_CLUE_EDIT] = '编辑线索信息'
HTML_TITLE[URL_CLUE_CREATE] = '创建新线索'
HTML_TITLE[URL_CLUE_UPDATE] = '修改线索信息'
HTML_TITLE[URL_CLUE_DETAIL] = '线索详情'
HTML_TITLE[URL_CLUE_CONVERT_TO_LINKMAN] = '线索转换为联系人'

HTML_TITLE[URL_CHANCE_LIST] = '商机列表'
HTML_TITLE[URL_CHANCE_EDIT] = '编辑商机信息'
HTML_TITLE[URL_CHANCE_CREATE] = '创建新商机'
HTML_TITLE[URL_CHANCE_UPDATE] = '修改商机信息'
HTML_TITLE[URL_CHANCE_DETAIL] = '商机详情'

HTML_TITLE[URL_ORDER_LIST_CREATE_USER] = '订单列表'
HTML_TITLE[URL_ORDER_LIST_RECEIVE_USER] = '订单中心'
HTML_TITLE[URL_ORDER_EDIT] = '编辑订单信息'
HTML_TITLE[URL_ORDER_CREATE] = '创建新订单'
HTML_TITLE[URL_ORDER_DETAIL] = '订单详情'

HTML_TITLE[URL_MERCHANT_LIST] = '商户列表'
HTML_TITLE[URL_MERCHANT_EDIT] = '编辑商户信息'
HTML_TITLE[URL_MERCHANT_CREATE] = '创建新商户'
HTML_TITLE[URL_MERCHANT_UPDATE] = '修改商户信息'
HTML_TITLE[URL_MERCHANT_DETAIL] = '商户详情'
export {HTML_TITLE}

//
export const PS_CLEAR_UNREAD_NOTIFICATION = "PS_CLEAR_UNREAD_NOTIFICATION";
export const PS_CLEAR_UN_DEAL_APPROVAL = "PS_CLEAR_UN_DEAL_APPROVAL";
export const PS_RELOAD_CUSTOMER_LIST = "PS_RELOAD_CUSTOMER_LIST";
export const PS_RELOAD_LINKMAN_LIST = "PS_RELOAD_LINKMAN_LIST";
export const PS_RELOAD_CLUE_LIST = "PS_RELOAD_CLUE_LIST";
export const PS_RELOAD_CHANCE_LIST = "PS_RELOAD_CHANCE_LIST";
export const PS_RELOAD_MERCHANT_LIST = "PS_RELOAD_MERCHANT_LIST";
