import React, {useEffect, useState} from 'react';
import {Badge, SafeArea, TabBar} from 'antd-mobile/2x';
import {Redirect, Route, Switch, useHistory, useLocation,} from 'react-router-dom';
import styles from './Main.less';
import {
  PS_CLEAR_UN_DEAL_APPROVAL,
  PS_CLEAR_UNREAD_NOTIFICATION,
  URL_APPROVAL_LIST,
  URL_CHANCE_LIST,
  URL_CLUE_LIST,
  URL_CUSTOMER_LIST,
  URL_HOME,
  URL_LINKMAN_LIST,
  URL_ME,
  URL_MERCHANT_LIST,
  URL_NOTIFICATION
} from "../../constant";
import Home from "./Home/Home";
import PubSub from 'pubsub-js'
import {Query, Url} from "../../api/query";
import {
  CheckShieldFill,
  CheckShieldOutline,
  MessageFill,
  MessageOutline,
  StarFill,
  StarOutline,
  TeamFill,
  TeamOutline
} from "antd-mobile-icons";
import ApprovalList from "../Approval/ApprovalList/ApprovalList";
import Notification from "../Notification/Notification";
import PersonalCenter from "../PersonalCenter/PersonalCenter";
import {GlobalZoom} from "../../index";
import {useAliveController} from "react-activation";


const Bottom = ({hasUnreadNotification, hasUnDealApproval}) => {
  const history = useHistory();
  const location = useLocation();
  const {pathname} = location;
  const setRouteActive = (value) => {
    history.replace(value);
  };
  const GlobalZoom120 = GlobalZoom * 120
  const resizeIcon = GlobalZoom120.toString() + '%'
  const resizeTitle = '24px'
  const tabs = [
    {
      key: URL_HOME,
      title: <div style={{fontSize: resizeTitle, marginTop: '15px'}}>首页</div>,
      icon: (active) => active ? <StarFill fontSize={resizeIcon}/> :
        <StarOutline fontSize={resizeIcon}/>,
    },
    {
      key: URL_NOTIFICATION,
      title: <div style={{fontSize: resizeTitle, marginTop: '15px'}}>通知</div>,
      icon: (active) => active ? <MessageFill fontSize={resizeIcon}/> :
        <MessageOutline fontSize={resizeIcon}/>,
      badge: hasUnreadNotification ? Badge.dot : null,
    },
    {
      key: URL_APPROVAL_LIST,
      title: <div style={{fontSize: resizeTitle, marginTop: '15px'}}>审批</div>,
      icon: (active) => active ? <CheckShieldFill fontSize={resizeIcon}/> :
        <CheckShieldOutline fontSize={resizeIcon}/>,
      badge: hasUnDealApproval ? Badge.dot : null,
    },
    {
      key: URL_ME,
      title: <div style={{fontSize: resizeTitle, marginTop: '15px'}}>我的</div>,
      icon: (active) => active ? <TeamFill fontSize={resizeIcon}/> :
        <TeamOutline fontSize={resizeIcon}/>,
    },
  ];
  return (<TabBar style={{marginTop: '15px', marginBottom: '25px'}} activeKey={pathname}
                  onChange={(value) => {
                    setRouteActive(value)
                  }}>
    {tabs.map(item => (
      <TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge}/>))}
  </TabBar>);
};

const Main = () => {
  const {drop} = useAliveController()
  const dropKeepAlive = () => {
    drop(URL_CUSTOMER_LIST)
    drop(URL_LINKMAN_LIST)
    drop(URL_CHANCE_LIST)
    drop(URL_CLUE_LIST)
    drop(URL_MERCHANT_LIST)
  }

  useEffect(() => {
    CheckNeedBadgeDot()
    dropKeepAlive()

    const ps_clear_unread_notification = PubSub.subscribe(PS_CLEAR_UNREAD_NOTIFICATION, () => {
      setHasUnreadNotification(false)
    })
    const ps_clear_un_deal_approval = PubSub.subscribe(PS_CLEAR_UN_DEAL_APPROVAL, () => {
      setHasUnDealApproval(false)
    })
    return () => {
      PubSub.unsubscribe(ps_clear_unread_notification)
      PubSub.unsubscribe(ps_clear_un_deal_approval)
    }
  }, [])

  const [hasUnreadNotification, setHasUnreadNotification] = useState(false)
  const [hasUnDealApproval, setHasUnDealApproval] = useState(false)
  const CheckNeedBadgeDot = () => {
    Query(Url.notificationHasUnread, {}, (res) => {
      if (res.data.has_unread) {
        setHasUnreadNotification(true)
      }
    })
    Query(Url.approvalHasUnDeal, {}, (res) => {
      if (res.data.has_un_deal) {
        setHasUnDealApproval(true)
      }
    })
  }


  const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  return (
    <div className={[isSafari ? styles.appSafari : styles.app]}>
      <SafeArea position="top"/>
      <div className={styles.body}>
        <Switch>
          <Route path={URL_HOME}>
            <Home/>
          </Route>
          <Route path={URL_NOTIFICATION}>
            <Notification/>
          </Route>
          <Route path={URL_APPROVAL_LIST}>
            <ApprovalList/>
          </Route>
          <Route path={URL_ME}>
            <PersonalCenter/>
          </Route>
          <Redirect to={URL_HOME}/>
        </Switch>
      </div>
      <div className={styles.bottom}>
        <Bottom hasUnDealApproval={hasUnDealApproval}
                hasUnreadNotification={hasUnreadNotification}/>
        <SafeArea position="bottom"/>
      </div>
    </div>
  );
};

export default Main
