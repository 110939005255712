import React, {useEffect} from 'react';
import globalStyles from "../../../App.less";
import {Button, DatePicker, Dialog, Form, Input, List, Popup, Skeleton, TextArea, Toast} from "antd-mobile/2x";
import MyPicker from "../../MyComponents/Form/MyPicker";
import {useHistory} from "react-router-dom";
import dayjs from "dayjs";
import {AddCircleOutline} from "antd-mobile-icons";
import {
  HTML_TITLE, PS_RELOAD_CLUE_LIST,
  SESSION_LAST_LINKMAN_INFO,
  URL_CLUE_CONVERT_TO_LINKMAN,
  URL_CLUE_CREATE,
  URL_CLUE_DETAIL,
  URL_CLUE_UPDATE,
  URL_LINKMAN_DETAIL,
  URL_LINKMAN_UPDATE
} from "../../../constant";
import {Query, Url} from "../../../api/query";
import ChineseHelper from "../../../utils/Pinyin/ChineseHelper";
import PubSub from "pubsub-js";


const PageType = {
  Create: 0,
  Update: 1,
  ConvertToLinkman: 2,
}


let toLinkmanDetail = undefined

const ClueForm = (props) => {
  const history = useHistory()
  const formRef = React.createRef()
  const [pageType, setPageType] = React.useState(PageType.Create)
  const [linkmanIdInUpdate, setLinkmanIdInUpdate] = React.useState(-1)
  const [hasSubmit, setHasSubmit] = React.useState(false)
  const [customer, setCustomer] = React.useState({})
  const [customerPopup, setCustomerPopup] = React.useState(false)
  const [customerList, setCustomerList] = React.useState([])
  const [customerListLoading, setCustomerListLoading] = React.useState(false)

  const toLinkmanDetailListener = (e) => {
    if (toLinkmanDetail !== undefined) {
      history.replace({
        pathname: URL_LINKMAN_DETAIL,
        state: toLinkmanDetail
      })
      toLinkmanDetail = undefined
    }
  }

  useEffect(() => {
    toLinkmanDetail = undefined
    if (props.location.pathname === URL_CLUE_CREATE) {
      setPageType(PageType.Create)
      document.title = HTML_TITLE[URL_CLUE_CREATE]

    } else if (props.location.pathname === URL_CLUE_UPDATE) {
      setPageType(PageType.Update)
      document.title = HTML_TITLE[URL_LINKMAN_UPDATE]

      const stateLinkman = props.location.state === undefined ? getLastLinkmanInfo() : props.location.state.clue
      const content = convertDate(JSON.parse(stateLinkman.content))
      setLinkmanIdInUpdate(stateLinkman.id)
      let form;
      (form = formRef.current) === null || form === void 0 ? void 0 : form.setFieldsValue(content);
    } else if (props.location.pathname === URL_CLUE_CONVERT_TO_LINKMAN) {
      setPageType(PageType.ConvertToLinkman)
      document.title = HTML_TITLE[URL_CLUE_CONVERT_TO_LINKMAN]

      const stateLinkman = props.location.state === undefined ? getLastLinkmanInfo() : props.location.state.clue
      const content = convertDate(JSON.parse(stateLinkman.content))
      setLinkmanIdInUpdate(stateLinkman.id)
      let form;
      (form = formRef.current) === null || form === void 0 ? void 0 : form.setFieldsValue(content);
    }

    window.addEventListener('popstate', toLinkmanDetailListener)
    return () => {
      window.removeEventListener('popstate', toLinkmanDetailListener)
    }

  }, [])

  const convertDate = (content) => {
    if (content.linkman_birthday !== null && content.linkman_birthday !== undefined) {
      content.linkman_birthday = content.linkman_birthday === null ? null : new Date(content.linkman_birthday)
    }
    if (content.relative !== null && content.relative !== undefined) {
      for (let i = 0; i < content.relative.length; i++) {
        content.relative[i].birthday = content.relative[i].birthday === null ? null : new Date(content.relative[i].birthday)
      }
    }
    return content
  }

  const getLastLinkmanInfo = () => {
    const lastLinkmanInfoStr = sessionStorage.getItem(SESSION_LAST_LINKMAN_INFO);
    if (lastLinkmanInfoStr == null) {
      return {}
    }
    return JSON.parse(lastLinkmanInfoStr)
  }

  const setForm = (content) => {
    let form;
    (form = formRef.current) === null || form === void 0 ? void 0 : form.setFieldsValue(content);
  }

  const setCustomerInfo = (customer) => {
    setForm({customer_name: customer.name})
    setCustomer(customer)
  }


  const submitForm = (obj) => {
    let value = JSON.parse(JSON.stringify(obj))
    if (value.linkman_birthday !== undefined) {
      value.linkman_birthday = dayjs(value.linkman_birthday).format('YYYY-MM-DD')
    }
    if (value.relative !== null && value.relative !== undefined) {
      for (let i = 0; i < value.relative.length; i++) {
        if (value.relative[i].birthday !== undefined) {
          value.relative[i].birthday = dayjs(value.relative[i].birthday).format('YYYY')
        }
      }
    }

    let data = {}
    if (pageType === PageType.Update || pageType === PageType.ConvertToLinkman) {
      data.id = linkmanIdInUpdate
    }
    delete value.customer_name
    data.name = value.linkman_name
    data.content = value

    if (pageType === PageType.Create) {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认创建线索？</div>,
        onConfirm: async () => {
          setHasSubmit(true)
          Query(Url.clueCreate, data, (res) => {
            Toast.show({
              icon: 'success',
              content: '创建成功',
              duration: 1000,
            })
            PubSub.publish(PS_RELOAD_CLUE_LIST, {})
            setTimeout(() => {
              history.replace({
                  pathname: URL_CLUE_DETAIL,
                  state: {clue: res.data}
                },
              )
            }, 1500)
          }, (err) => {
            setHasSubmit(false)
          }, () => {
            setHasSubmit(false)
          })
        },
      })

    } else if (pageType === PageType.Update) {
      Dialog.confirm({
        content: <div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>确认修改线索内容？</div>,
        onConfirm: async () => {
          setHasSubmit(true)
          Query(Url.clueUpdate, data, (res) => {
            Toast.show({
              icon: 'success',
              content: (<div style={{textAlign: 'center'}}>修改成功</div>),
              duration: 2000,
            })
            PubSub.publish(PS_RELOAD_CLUE_LIST, {})
            setTimeout(() => {
              history.go(-1)
              history.replace({
                pathname: URL_CLUE_DETAIL,
                state: {
                  clue: res.data
                }
              })
            }, 2500)
          }, (err) => {
            setHasSubmit(false)
          }, () => {
            setHasSubmit(false)
          })
        },
      })

    } else if (pageType === PageType.ConvertToLinkman) {
      data.customer_id = customer.id
      Dialog.show({
        content: (<div style={{
          fontSize: 'var(--adm-font-size-8)',
          textAlign: "center",
          margin: '20px 0'
        }}>
          是否将该线索的协作同事增设到对应客户
          <span style={{fontWeight: 'bold'}}> {customer.name} </span>
          的协作同事名单？</div>)
        ,
        closeOnAction: true,
        actions: [[
          {
            key: 'no',
            text: '不增设',
            onClick: async () => {
              submitConvertToLinkman(data, false)
            }
          },
          {
            key: 'yes',
            text: '增设',
            onClick: async () => {
              submitConvertToLinkman(data, true)
            }
          }
        ],
          {
            key: 'cancel',
            text: '取消',
          }
        ]
      })
    }
  }

  const submitConvertToLinkman = (data, convert_relevants) => {
    setHasSubmit(true)
    Query(Url.clueUpdate, data, (res) => {
      Query(Url.clueConvertToLinkman, {
        clue_id: data.id,
        customer_id: customer.id,
        convert_relevants
      }, (res2) => {

        toLinkmanDetail = {
          linkman: res2.data.linkman,
          customer: res2.data.customer
        }

        Toast.show({
          icon: 'success',
          content: (<div style={{textAlign: 'center'}}>转换成功</div>),
          duration: 2000,
        })
        setTimeout(() => {
          history.go(-2)
        }, 2000)
      })
    }, (err) => {
      setHasSubmit(false)
    }, () => {
      setHasSubmit(false)
    })


  }

  const chooseCustomer = () => {
    setCustomerPopup(true)

    setCustomerListLoading(true)
    Query(Url.customerQuerySelf, null,
      (res) => {
        let list = res.data.list
        for (let i = 0; i < list.length; i++) {
          list[i]["enName"] = ChineseHelper.ConvertPinyin(list[i]["name"])
        }
        list.sort((o1, o2) => o1.enName.localeCompare(o2.enName, 'en'))

        setCustomerList(list)
        setCustomerListLoading(false)
      },
      (res) => {
        setCustomerListLoading(false)
      },
      () => {
        setCustomerListLoading(false)
      }
    )
  }

  return (
    <div style={{padding: '0 20px', background: '#EDEEF0'}} className={globalStyles.vh_frame}>
      <div className={globalStyles.body}>

        <Form ref={formRef}
              layout='horizontal'
              mode='card'
              onFinish={submitForm}
              initialValues={{
                phones: [{}],
              }}
              onFinishFailed={() => {
                Toast.show({
                  icon: 'fail',
                  content: '请检查表单是否填写完整',
                })
              }}
              footer={<Button block type='submit' color='primary' size='large'
                              disabled={hasSubmit}
                              style={{marginBottom: '80px', '--border-radius': '30px'}}>提交</Button>}
        >
          <Form.Header>个人信息</Form.Header>

          <Form.Item label='客户名称' name='customer_name'
                     rules={[{required: pageType === PageType.ConvertToLinkman}]}
                     onClick={chooseCustomer} hidden={!(pageType === PageType.ConvertToLinkman)}>
            <Input placeholder='请输入' readOnly/>
          </Form.Item>
          <Form.Item label='联系人姓名' name='linkman_name' rules={[{required: true}]}>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='性别' name='linkman_sex'>
            <MyPicker columns={[['男', '女', '未知']]}/>
          </Form.Item>
          <Form.Item label='出生日期' name='linkman_birthday' trigger='onConfirm' onClick={(e, datePickerRef) => {
            let _a;
            (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.open();
          }}>
            <DatePicker min={new Date(1900, 0, 1)}
                        max={new Date()}
            >

            </DatePicker>
          </Form.Item>
          <Form.Item label='爱好' name='linkman_hobby'>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='家庭住址' name='linkman_home_location'>
            <TextArea placeholder='请输入' rows={2}/>
          </Form.Item>
          <Form.Item label='线索来源' name='linkman_source'>
            <MyPicker columns={[['线上咨询', '电话咨询', '展会资源', '邮件咨询', '其他方式']]}/>
          </Form.Item>

          <Form.Array
            name="phones"
            renderAdd={() => (<span><AddCircleOutline/> 添加电话号码</span>)}
            renderHeader={({index}, {remove}) => (
              <span>
                <span>电话号码{index + 1}</span>
                <a onClick={() => remove(index)} style={{float: 'right', zIndex: 1}}>
                  删除
                </a>
              </span>
            )}
          >
            {fields =>
              fields.map(({index}) => (
                <>
                  <Form.Item
                    name={[index, 'phone']}
                    label='号码'
                  >
                    <Input placeholder='请输入'/>
                  </Form.Item>
                </>
              ))
            }
          </Form.Array>

          <Form.Header>职务信息</Form.Header>
          <Form.Item label='部门' name='linkman_depart'>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='职务' name='linkman_post'>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='办公地址' name='linkman_work_location'>
            <TextArea placeholder='请输入' rows={2}/>
          </Form.Item>

          <Form.Header>备注框</Form.Header>
          <Form.Item name='linkman_note'>
            <TextArea
              placeholder='备注信息'
              rows={5}
            />
          </Form.Item>

          <Form.Header>相关人（如父母、对象、子女）信息</Form.Header>
          <Form.Item style={{display: 'none'}}/>
          <Form.Array
            name="relative"
            renderAdd={() => (<span><AddCircleOutline/> 添加相关人</span>)}
            renderHeader={({index}, {remove}) => (
              <span>
                <span>相关人{index + 1}</span>
                <a onClick={() => remove(index)} style={{float: 'right', zIndex: 1}}>
                  删除
                </a>
              </span>
            )}
          >
            {fields =>
              fields.map(({index}) => (
                <>
                  <Form.Item name={[index, 'name']} label='姓名'>
                    <Input placeholder='请输入'/>
                  </Form.Item>
                  <Form.Item name={[index, 'relation']} label='关系'
                  >
                    <Input placeholder='请输入'/>
                  </Form.Item>
                  <Form.Item label='出生年份' name={[index, 'birthday']} trigger='onConfirm'
                             onClick={(e, datePickerRef) => {
                               let _a;
                               (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.open();
                             }}>

                    <DatePicker min={new Date(1900, 0, 1)}
                                max={new Date()}
                                precision='year'
                    >
                      {value => value ? dayjs(value).format('YYYY') :
                        <div style={{color: '#cccccc'}}>请选择</div>}
                    </DatePicker>
                  </Form.Item>
                  <Form.Item name={[index, 'hobby']} label='爱好'>
                    <Input placeholder='请输入'/>
                  </Form.Item>
                  <Form.Item name={[index, 'note']} label='备注'>
                    <TextArea placeholder='请输入' rows={4}/>
                  </Form.Item>
                </>
              ))
            }
          </Form.Array>
        </Form>
      </div>

      <Popup
        visible={customerPopup}
        onMaskClick={() => {
          setCustomerPopup(false)
        }}
        position='right'
        bodyStyle={{width: 'var(--80vw)'}}
      >
        <div className={globalStyles.frame}>
          <div style={{
            fontSize: 'var(--adm-font-size-8)',
            padding: '20px',
            color: 'var(--adm-color-text)',
            textAlign: "center"
          }}
               className={globalStyles.top_b}>选择客户
          </div>
          <List className={globalStyles.body}
                style={{display: customerListLoading ? 'none' : 'block', overflowY: 'scroll'}}>
            {
              customerList.map((item, index) => {
                return (
                  <List.Item key={index} onClick={() => {
                    setCustomerInfo(item)
                    setCustomerPopup(false)
                  }}>
                    {item.name}
                  </List.Item>
                )
              })
            }
          </List>
        </div>
        <div style={{margin: '20px 50px 20px 50px', display: customerListLoading ? 'block' : 'none'}}>
          <Skeleton.Title animated/>
          <Skeleton.Paragraph lineCount={5} animated/>
        </div>

      </Popup>
    </div>
  );
};

export default ClueForm;