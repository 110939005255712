import React from 'react';
import {ErrorBlock} from "antd-mobile/2x";
import "./MyEmpty.css"
import emptySvg from "../../../asset/empty.svg"

const MyEmpty = (props) => {
  return (
    <div>
      <ErrorBlock
        image={emptySvg}
        style={{
          '--image-height': '200px',
        }}
        description={
          <span style={{fontSize: 'var(--adm-font-size-9)'}}>
            {props.description === undefined || props.description === null ? '暂无数据' : props.description}
          </span>
        }
      >
      </ErrorBlock>
    </div>
  );
};

export default MyEmpty;