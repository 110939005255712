import React, {useEffect, useState} from 'react';
import globalStyles from '../../../App.less';
import {CapsuleTabs, Card, Divider, Grid, Selector, Skeleton} from "antd-mobile/2x";
import {Query, Url} from "../../../api/query";
import {
  HTML_TITLE,
  PS_CLEAR_UN_DEAL_APPROVAL,
  ApprovalStatus,
  URL_APPROVAL_DETAIL,
  URL_APPROVAL_LIST
} from "../../../constant";
import {RightOutline, TagOutline} from "antd-mobile-icons";
import "./ApprovalList.css"
import {useHistory} from "react-router-dom";
import {ApprovalDetailDisplayType} from "../ApprovalDetail.jsx/ApprovalDetail";
import MyEmpty from "../../MyComponents/MyEmpty/MyEmpty";
import PubSub from "pubsub-js";

const tabKeys = ['0', '1']
let tabKey = tabKeys[0]
const ApprovalTitle = {
  UpdateCustomerArg: '修改客户信息',
  DeleteCustomerArg: '删除客户',
  UpdateLinkmanArg: '修改客户联系人信息',
  DeleteLinkmanArg: '删除客户联系人',
  UpdateMerchantArg: '修改商户信息',
  DeleteMerchantArg: '删除商户',
  CreateOrderArg: '创建订单',
}
export {ApprovalTitle}

const ApprovalList = () => {
  const history = useHistory();

  const [approveByMeListOrigin, setApproveByMeListOrigin] = useState([])
  const [approveByMeList, setApproveByMeList] = useState([])
  const [approveByMeLoading, setApproveByMeLoading] = useState(false)

  const [approveByOtherListOrigin, setApproveByOtherListOrigin] = useState([])
  const [approveByOtherList, setApproveByOtherList] = useState([])
  const [approveByOtherLoading, setApproveByOtherLoading] = useState(false)

  const [unDealCount, setUnDealCount] = useState(0)

  useEffect(() => {
    document.title = HTML_TITLE[URL_APPROVAL_LIST]
    queryApproveByMe()
    queryApproveByOther()
  }, [])

  const queryApproveByMe = () => {
    setApproveByMeLoading(true)
    Query(Url.approvalQueryApproveByMe, {}, (res) => {
      let list = res.data.list
      list.sort((o1, o2) => {
        const o1IsDeal = (o1.is_deal > ApprovalStatus.Undeal ? ApprovalStatus.Deal : ApprovalStatus.Undeal)
        const o2IsDeal = (o2.is_deal > ApprovalStatus.Undeal ? ApprovalStatus.Deal : ApprovalStatus.Undeal)
        return o1IsDeal === o2IsDeal ?
          (o2.id - o1.id) :
          (o1IsDeal - o2IsDeal)
      })
      let unDealCountVar = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i].is_deal === ApprovalStatus.Undeal) {
          unDealCountVar++
        } else {
          break;
        }
      }
      setUnDealCountWrapper(unDealCountVar)
      setApproveByMeListOrigin(list)
      setApproveByMeList(list)
      setApproveByMeLoading(false)
    }, () => {
      setApproveByMeLoading(false)
    }, () => {
      setApproveByMeLoading(false)
    })
  }

  const queryApproveByOther = () => {
    setApproveByOtherLoading(true)
    Query(Url.approvalQueryApproveByOther, {}, (res) => {
      let list = res.data.list
      list.sort((o1, o2) => {
        const o1IsDeal = (o1.is_deal > ApprovalStatus.Undeal ? ApprovalStatus.Deal : ApprovalStatus.Undeal)
        const o2IsDeal = (o2.is_deal > ApprovalStatus.Undeal ? ApprovalStatus.Deal : ApprovalStatus.Undeal)
        return o1IsDeal === o2IsDeal ?
          (o2.id - o1.id) :
          (o1IsDeal - o2IsDeal)
      })
      setApproveByOtherListOrigin(list)
      setApproveByOtherList(list)
      setApproveByOtherLoading(false)
    }, () => {
      setApproveByMeLoading(false)
    }, () => {
      setApproveByMeLoading(false)
    })
  }

  const setUnDealCountWrapper = (unDealCountVar) => {
    if (unDealCountVar === 0) {
      PubSub.publish(PS_CLEAR_UN_DEAL_APPROVAL, {})
    }
    setUnDealCount(unDealCountVar)
  }

  const setApproveByMeListByFilter = (arr) => {
    if (arr.length === 0) {
      setApproveByMeList(approveByMeListOrigin)
    } else {
      if (arr[0] === 0) {
        setApproveByMeList(approveByMeListOrigin.filter(item => item.is_deal === ApprovalStatus.Undeal))
      } else {
        setApproveByMeList(approveByMeListOrigin.filter(item => item.is_deal !== ApprovalStatus.Undeal))
      }
    }
  }

  const setApproveByOtherListByFilter = (arr) => {
    if (arr.length === 0) {
      setApproveByOtherList(approveByOtherListOrigin)
    } else {
      if (arr[0] === 0) {
        setApproveByOtherList(approveByOtherListOrigin.filter(item => item.is_deal === ApprovalStatus.Undeal))
      } else {
        setApproveByOtherList(approveByOtherListOrigin.filter(item => item.is_deal !== ApprovalStatus.Undeal))
      }
    }
  }

  const listDiv = (tabKey) => (
    <div className={globalStyles.frame} style={{width: 'var(--100vw)'}}>
      <div className={globalStyles.top}>
        <Grid columns={3} style={{margin: '10px 20px 0 20px'}}>
          <Grid.Item span={1}>
            <div style={{
              fontSize: 'var(--adm-font-size-9)',
              marginTop: '16px',
              color: 'var(--adm-color-text-secondary)'
            }}>筛选条件：
            </div>
          </Grid.Item>
          <Grid.Item span={2}>
            <div style={{textAlign: 'right'}}>
              <Selector defaultValue={[]}
                        onChange={tabKey === tabKeys[0] ? setApproveByMeListByFilter : setApproveByOtherListByFilter}
                        options={[{label: '待办', value: 0,},
                          {label: '已办', value: 1,},
                        ]
                        }/>
            </div>
          </Grid.Item>
        </Grid>
        <Divider style={{margin: '10px 0 0 0'}}/>
      </div>

      <div className={globalStyles.body} style={{backgroundColor: '#EDEEF0'}}>
        <div style={{padding: '10px'}}/>

        <Card style={{
          margin: '15px 30px',
          display: (
            (tabKey === tabKeys[0] ?
              (approveByMeList.length !== 0) :
              (approveByOtherList.length !== 0)) ? 'none' : 'block')
        }}>
          <div style={{
            margin: '30px 0 50px 0',
            display: (
              (tabKey === tabKeys[0] ?
                (!approveByMeLoading && approveByMeList.length === 0) :
                (!approveByOtherLoading && approveByOtherList.length === 0)) ? 'block' : 'none')
          }}>
            <MyEmpty/>
          </div>

          <div style={{
            margin: '10px 30px',
            display: (tabKey === tabKeys[0] ? (approveByMeLoading) : (approveByOtherLoading)) ? 'block' : 'none'
          }}>
            <Skeleton.Title animated/>
            <Skeleton.Paragraph lineCount={5} animated/>
          </div>

        </Card>
        <div>
          {
            tabKey === tabKeys[0] ?
              approveByMeList.map((item) => {
                return (
                  <div style={{padding: '15px 30px'}} key={item.id}>
                    <Card
                      title={
                        <div style={{
                          fontWeight: 'normal',
                          color: item.is_deal === ApprovalStatus.Undeal ? '#000000' : '#AAAAAA'
                        }}>

                          <TagOutline
                            style={{
                              marginRight: '15px',
                              color: item.is_deal === ApprovalStatus.Undeal ? '#1677ff' : '#AAAAAA'
                            }}/>
                          {ApprovalTitle[item.cmd_type]}
                          （编号: {item.id}）
                        </div>
                      }
                      extra={
                        <div>
                          {item.is_deal === ApprovalStatus.Reject ?
                            <span style={{color: '#EF4823'}}>不通过</span> : ''}<RightOutline/>
                        </div>
                      }
                      onClick={() => {
                        history.push({
                          pathname: URL_APPROVAL_DETAIL,
                          state: {
                            approveByMe: item,
                            displayType: ApprovalDetailDisplayType.ApproveByMe
                          }
                        })
                      }}
                      style={{borderRadius: '16px', lineHeight: '40px', boxShadow: '0 1px 10px 0 rgb(0 0 0 / 5%)'}}
                    >
                      <div style={{
                        color: item.is_deal === ApprovalStatus.Undeal ? '#000000' : '#AAAAAA',
                        fontSize: 'var(--adm-font-size-6)',
                        lineHeight: '40px'
                      }}>{item.content}</div>
                    </Card>
                  </div>
                )
              }) :
              approveByOtherList.map((item) => {
                return (
                  <div style={{padding: '15px 30px'}} key={item.id}>
                    <Card
                      title={
                        <div style={{
                          fontWeight: 'normal',
                          color: item.is_deal === ApprovalStatus.Undeal ? '#000000' : '#AAAAAA'
                        }}>
                          <TagOutline
                            style={{
                              marginRight: '15px',
                              color: item.is_deal === ApprovalStatus.Undeal ? '#1677ff' : '#AAAAAA'
                            }}/>
                          {ApprovalTitle[item.cmd_type]}
                          （编号: {item.id}）
                        </div>

                      }
                      extra={
                        <div>
                          {item.is_deal === ApprovalStatus.Reject ?
                            <span style={{color: '#EF4823'}}>不通过</span> : ''}<RightOutline/>
                        </div>
                      }
                      onClick={() => {
                        history.push({
                          pathname: URL_APPROVAL_DETAIL,
                          state: {
                            approveByOther: item,
                            displayType: ApprovalDetailDisplayType.ApproveByOther
                          }
                        })
                      }}
                      style={{
                        borderRadius: 'var(--adm-radius-m)',
                        lineHeight: '40px',
                        boxShadow: '0 1px 10px 0 rgb(0 0 0 / 5%)'
                      }}
                    >
                      <div style={{
                        color: item.is_deal === ApprovalStatus.Undeal ? '#000000' : '#AAAAAA',
                        fontSize: 'var(--adm-font-size-6)',
                        lineHeight: '40px'
                      }}>{item.content}</div>
                    </Card>
                  </div>
                )
              })
          }
        </div>

        <div style={{padding: '20px'}}/>
      </div>
    </div>
  )

  const tabChange = (number) => {
    tabKey = number
    // if (number === tabKeys[0]) {
    //   queryApproveByMe()
    // } else if (number === tabKeys[1]) {
    //   queryApproveByOther()
    // }
  }

  return (
    <div className={globalStyles.frame} style={{width: 'var(--100vw)'}}>
      <CapsuleTabs defaultActiveKey={tabKey} className="approval-tab-frame" onChange={tabChange}>
        <CapsuleTabs.Tab title='由我审批' key={tabKeys[0]}>
          {listDiv(tabKeys[0])}
        </CapsuleTabs.Tab>
        <CapsuleTabs.Tab title='我发起的审批' key={tabKeys[1]}>
          {listDiv(tabKeys[1])}
        </CapsuleTabs.Tab>
      </CapsuleTabs>
    </div>
  );
};

export default ApprovalList;