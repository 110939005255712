import axios from "axios";
import {Toast} from "antd-mobile/2x";
import {getSessionAuthorization} from "../storage/storage";

export const IS_MOCK = false;
// export const IS_MOCK = true;
export const BE_BASE_URL = IS_MOCK ? "http://192.168.0.10:8000" : "https://crm.hn-xiangyi.cn/api";
// export const BE_BASE_URL = IS_MOCK ? "http://192.168.31.101:8000" : "https://crm.hn-xiangyi.cn/api";

function generateUrl(url) {
  return BE_BASE_URL + url;
}

const Url = {
  hello: generateUrl("/hello"),

  userLogin: "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwa989392593543c96&redirect_uri=http%3a%2f%2fcrm.hn-xiangyi.cn%2fapi%2fuser%2flogin&response_type=code&scope=snsapi_privateinfo&agentid=1000023#wechat_redirect",
  userQuery: generateUrl("/user/query"),

  customerCreate: generateUrl("/customer/create"),
  customerQueryById: generateUrl("/customer/query_by_id"),
  customerTryQueryById: generateUrl("/customer/try_query_by_id"),
  customerQuerySelf: generateUrl("/customer/query_self"),
  customerQueryUnderling: generateUrl("/customer/query_underling"),
  customerUpdate: generateUrl("/customer/update"),
  customerDelete: generateUrl("/customer/delete"),
  customerDupCheck: generateUrl("/customer/dup_check"),

  customerRUCreate: generateUrl("/customer_ru/create"),
  customerRUQueryByCustomerId: generateUrl("/customer_ru/query_by_customer_id"),
  customerRUDelete: generateUrl("/customer_ru/delete"),

  customerVisitCreate: generateUrl("/customer_visit/create"),
  customerVisitQueryByCustomerId: generateUrl("/customer_visit/query_by_customer_id"),
  customerVisitDelete: generateUrl("/customer_visit/delete"),

  linkmanCreate: generateUrl("/linkman/create"),
  linkmanQueryByCustomerId: generateUrl("/linkman/query_by_customer_id"),
  linkmanQuerySelf: generateUrl("/linkman/query_by_self"),
  linkmanQueryUnderling: generateUrl("/linkman/query_by_underling"),
  linkmanUpdate: generateUrl("/linkman/update"),
  linkmanDelete: generateUrl("/linkman/delete"),

  notificationQuery: generateUrl("/notification/query"),
  notificationRead: generateUrl("/notification/read"),
  notificationHasUnread: generateUrl("/notification/has_unread"),

  approvalQueryApproveByMe: generateUrl("/approval/query_approve_by_me"),
  approvalQueryApproveByOther: generateUrl("/approval/query_approve_by_other"),
  approvalDeal: generateUrl("/approval/deal"),
  approvalReject: generateUrl("/approval/reject"),
  approvalHasUnDeal: generateUrl("/approval/has_un_deal"),

  clueCreate: generateUrl("/clue/create"),
  clueQueryByUserId: generateUrl("/clue/query_by_user_id"),
  clueUpdate: generateUrl("/clue/update"),
  clueDelete: generateUrl("/clue/delete"),
  clueRUCreate: generateUrl("/clue_ru/create"),
  clueRUDelete: generateUrl("/clue_ru/delete"),
  clueRUQueryByClueId: generateUrl("/clue_ru/query_by_clue_id"),
  clueConvertToLinkman: generateUrl("/clue/convert_to_linkman"),


  chanceCreate: generateUrl("/chance/create"),
  chanceQueryByUserId: generateUrl("/chance/query_by_user_id"),
  chanceUpdate: generateUrl("/chance/update"),
  chanceDelete: generateUrl("/chance/delete"),
  chanceRUCreate: generateUrl("/chance_ru/create"),
  chanceRUDelete: generateUrl("/chance_ru/delete"),
  chanceRUQueryByChanceId: generateUrl("/chance_ru/query_by_chance_id"),
  chanceMessageCreate: generateUrl("/chance_message/create"),
  chanceMessageQueryByChanceId: generateUrl("/chance_message/query_by_chance_id"),

  merchantCreate: generateUrl("/merchant/create"),
  merchantQuery: generateUrl("/merchant/query"),
  merchantUpdate: generateUrl("/merchant/update"),
  merchantDelete: generateUrl("/merchant/delete"),

  orderCreate: generateUrl("/order/create"),
  orderFinish: generateUrl("/order/finish"),
  orderClose: generateUrl("/order/close"),
  orderQuerySelf: generateUrl("/order/query_self"),
  orderHasUnfinished: generateUrl("/order/has_unfinished"),
}

const Query = (url, data, successFunc, failFunc, errFunc) => {
  axios({
    url,
    data,
    method: 'POST',
    headers: {
      'Authorization': getSessionAuthorization()
    }
  }).then(result => {
    const res = result.data
    if (res.code === 0) {
      successFunc(res)
    } else {
      Toast.show({
        icon: 'fail',
        content: (<div>
          <div style={{textAlign: "center"}}>出错了！</div>
          <div>{res.message}</div>
        </div>),
        duration: 3000,
      })
      if (failFunc !== undefined && failFunc !== null) {
        failFunc(res)
      }
    }
  }).catch(error => {
    Toast.show({
      icon: 'fail',
      content: (<div>
        <div style={{textAlign: "center"}}>系统出错了！</div>
        <div>{error.message}</div>
      </div>),
      duration: 3000,
    })
    if (errFunc !== undefined && errFunc !== null) {
      errFunc()
    }
    console.log("error message", error)
  })
}

export {Url, Query}